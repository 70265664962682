
import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as PlusSquareFill } from "bootstrap-icons/icons/plus-square-fill.svg";
import { ReactComponent as PencilSquare } from "bootstrap-icons/icons/pencil-square.svg";
import { ReactComponent as Trash } from "bootstrap-icons/icons/trash3.svg";
import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import CustomStyles from "../../components/utils/CustomStyles";
import { handleError } from "../../components/utils/AuthenticationCheck";

const RiderList = () => {
  const [riders, setRiders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const navigateTo = useNavigate();

  useEffect(() => {
    const fetchAllProperties = async () => {
      setLoading(true);
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) {
          throw new Error("No access token found.");
        }

        const response = await axios.get(ApiServices.getAllRiders, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });


        // console.log("these are all the  riders", response.data);
        const { status, message, data } = response.data;
        if (status === 200) {
          setRiders(data);
          toast.success(message, { toastId: "riders-fetch-success" });
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setLoading(false);
      }
    };

    fetchAllProperties();
  }, []);

  const handleFilter = (e) => {
    setFilterText(e.target.value);
    setResetPaginationToggle(!resetPaginationToggle);
  };



  // Define Expandable Component for Actions
  const ExpandableComponent = ({ data }) => (
    <div className="flex justify-content-start">
      <Link
        to="/admin/riders/details"
        state={{ riders: data }}
        className="mx-2 my-2 group"
      >
        <Eye className="w-5 h-5 text-darkGreen3" />
      </Link>

      <Link
        to="/admin/riders/update"
        state={{ riders: data }}
        className="mx-2 my-2 group"
      >
        <PencilSquare className="w-5 h-5 text-dash4" />
      </Link>

    </div>
  );

  const columns = [
    {
      name: "Full Name",
      sortable: true,
      width: "300px",
      cell: (row) => (
        <div>
          <span className="block font-bold">
            {row.firstname ? row.firstname.toUpperCase() : "NOT AVAILABLE"}{" "}
            {row.lastname ? row.lastname.toUpperCase() : "NOT AVAILABLE"}
          </span>
          <span
            className={`px-3 py-0.5 rounded-md  text-xs ${row.status.toLowerCase() === "active"
              ? "bg-darkGreen3 text-lGrey2"
              : "bg-dash5 text-lGrey2"
              }`}
          >
            {row.status ? row.status.toLowerCase() : "NOT AVAILABLE"}
          </span>
        </div>
      ),
    },
    {
      name: "Bike Plate No",
      selector: (row) => row.vehicle_plate_number.toUpperCase(),
      sortable: true,
      width: "200px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "300px",
    },

    {
      name: "Phone",
      selector: (row) => row.phone_number,
      sortable: true,
      width: "120px",
    },


  ];


  const filteredItems = riders.filter((item) => {
    for (let key in item) {
      if (
        typeof item[key] === "string" &&
        item[key].toLowerCase().includes(filterText.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  });

  const data = filteredItems.map((riders) => ({
    firstname: riders.firstname ? riders.firstname : "NOT AVAILABLE",
    lastname: riders.lastname ? riders.lastname : "NOT AVAILABLE",
    status: riders.status ? riders.status : "NOT AVAILABLE",
    email: riders.email ? riders.email : "NOT AVAILABLE",
    phone_number: riders.phone_number ? riders.phone_number : "NOT AVAILABLE",
    vehicle_plate_number: riders.vehicle_plate_number ? riders.vehicle_plate_number.toUpperCase() : "NOT AVAILABLE",
    id: riders.id,
    created_at: riders.created_at
  }));

  // Sort data based on creation date
  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <section
            className="w-full pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Riders
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1">List</p>
                </li>
              </ol>
            </nav>
          </section>
          <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap justify-between">
            <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
              <input
                type="search"
                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search"
                value={filterText}
                onChange={handleFilter}
              />
            </div>


          </div>
          {loading ? (
            <Spinner loading={loading} />
          ) : (

            <DataTable
              columns={columns}
              data={sortedData}
              pagination
              striped
              highlightOnHover
              customStyles={CustomStyles}
              noDataComponent={<div>No Riders found</div>}
              expandableRows
              expandableRowsComponent={ExpandableComponent}
            />
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default RiderList;


