import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { handleError } from "../../components/utils/AuthenticationCheck";

import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import CustomStyles from "../../components/utils/CustomStyles";
import formatDate from "../../components/utils/DateFormatter";

const Customer = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedCustomerType, setSelectedCustomerType] = useState("");

  const navigateTo = useNavigate();

  // ==========================- fetch all customers =================
  useEffect(() => {
    const fetchAllCustomerList = async () => {
      setLoading(true);
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) {
          throw new Error("No access token found.");
        }
        // console.log("i am token", token);
        const response = await axios.get(ApiServices.getCustomers, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        //  console.log("this is all customer", response.data);
        const { status, message, data } = response.data;
        if (status === 200) {
          setCustomers(data.slice().reverse());
          toast.success(message, { toastId: "customer-fetch-success" });
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setLoading(false);
      }
    };

    fetchAllCustomerList();
  }, []);
  // ================================== end of list of customer ==========================================

  //filter data by name
  const handleFilter = (e) => {
    setFilterText(e.target.value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  //selct user type
  const handleCustomerTypeSelect = (type) => {
    setSelectedCustomerType(type);
    setIsDropdownOpen(false);
  };


  // Define Expandable Component for Actions
  const ExpandableComponent = ({ data }) => (
    <div className="d-flex justify-content-end ">
      <Link
        to="/admin/customer/details"
        state={{ id: data.id }}
        // className=""
        onClick={(e) => {
          navigateTo("/admin/customer/details", {
            replace: true,
            state: { id:data.id },
          });
        }}
      >
        <Eye className="w-5 h-5 text-dash4 " />
      </Link>
    </div>
  );

  const columns = [
    // {
    //   name: "Details",
    //   cell: (row) => (
        
    //   ),
    //   width: "80px",
    // },

    {
      name: "Full Name",
      sortable: true,
      width: "300px",
      cell: (row) => (
        <div>
          <span className="block font-bold">
            {row.firstname ? row.firstname.toUpperCase() : "NOT AVAILABLE"}{" "}
            {row.lastname ? row.lastname.toUpperCase() : "NOT AVAILABLE"}
          </span>
          <span
            className={`px-3 py-0.5 rounded-md  text-xs ${
              row.user_type.toLowerCase() === "subdealers"
                ? "bg-dash5 text-lGrey2"
                : "bg-orange1 text-lGrey2"
            }`}
          >
            {row.user_type ? row.user_type.toLowerCase() : "NOT AVAILABLE"}
          </span>
        </div>
      ),
    },

    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "300px",
    },

    {
      name: "Phone",
      selector: (row) => row.phoneNumber,
      sortable: true,
      width: "120px",
    },

    {
      name: "Date",
      selector: (row) => formatDate(row.created_at),
      sortable: true,
    },
  ];

  //filter data
  const filteredItems = customers.filter((item) => {
    const matchesFilterText = Object.values(item).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(filterText.toLowerCase())
    );

    // Filter data based on selected customer type
    const matchesCustomerType =
      !selectedCustomerType ||
      item.user_type.toLowerCase() === selectedCustomerType.toLowerCase();

    return matchesFilterText && matchesCustomerType;
  });

  const data = filteredItems.map((customer) => ({
    created_at: formatDate(customer.created_at),
    firstname: customer.firstname
      ? customer.firstname.toUpperCase()
      : "NOT AVAILABLE",
    lastname: customer.lastname
      ? customer.lastname.toUpperCase()
      : "NOT AVAILABLE",
    email: customer.email ? customer.email.toUpperCase() : "NOT AVAILABLE",
    phoneNumber: customer.phoneNumber
      ? String(customer.phoneNumber)
      : "NOT AVAILABLE",
    user_type: customer.user_type
      ? customer.user_type.toUpperCase()
      : "NOT AVAILABLE",
    status: customer.status ? customer.status.toUpperCase() : "NOT AVAILABLE",
    id: customer.id,
  }));

  // Sort data based on creation date
  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  return (
    <div>
      <MainLayouts>
        <div className="sm:rounded-lg py-6 w-full">
          {/* <h1>Customer List</h1> */}
          <section
            className="w-full pl-[3%] pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg ">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Customer
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1 ">List</p>
                </li>
              </ol>
            </nav>
          </section>
          <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap items-center justify-between gap-4">
            {/* Search Input Field */}
            <div className="relative w-full md:w-auto flex-grow ">
              <input
                type="search"
                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search"
                value={filterText}
                onChange={handleFilter}
              />
            </div>

            {/* <div className="relative w-full md:w-auto flex-shrink-0">
              <div>
                <Link
                  to="/admin/customer/transactions/history"
                  className="bg-orange1 inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-3 text-sm font-semibold text-lGrey3 "
                >
                  Customer Transaction History
                </Link>
              </div>
            </div> */}
            {/* Select Options Button */}
            <div className="relative w-full md:w-auto flex-shrink-0">
              <div>
                <button
                  type="button"
                  className="bg-darkGreen3 inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-3 text-sm font-semibold text-dGrey3"
                  id="menu-button"
                  aria-expanded={isDropdownOpen}
                  aria-haspopup="true"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  Select Customer Type
                  <svg
                    className="-mr-1 h-5 w-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              {isDropdownOpen && (
                <div
                  className="absolute right-0 z-10 mt-2 w-full md:w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-lGrey shadow-lg ring-1 ring-black1 ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                >
                  <div
                    className="py-1 rounded-md"
                    role="none"
                    style={{ backgroundColor: "white" }}
                  >
                    <button
                      onClick={() => handleCustomerTypeSelect("subdealers")}
                      className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-white hover:text-darkGreen3"
                      role="menuitem"
                    >
                      Sub Dealers
                    </button>
                    <button
                      onClick={() => handleCustomerTypeSelect("endusers")}
                      className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-white hover:text-darkGreen3"
                      role="menuitem"
                    >
                      End users
                    </button>
                    <div className="relative w-full md:w-auto flex-shrink-0">
                      <button
                        type="button"
                        className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-white hover:text-darkGreen3"
                        onClick={() => handleCustomerTypeSelect("")} // Reset filter
                      >
                        All Customers
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            // <DataTable
            //   columns={columns}
            //   data={sortedData}
            //   pagination
            //   highlightOnHover
            //   noDataComponent={<div>No customer found</div>}
            //   customStyles={CustomStyles}
            // />
              <DataTable
                columns={columns}
                data={sortedData}
                pagination
                striped
                highlightOnHover
                customStyles={CustomStyles}
                noDataComponent={<div>No customer found</div>}
                expandableRows
                expandableRowsComponent={ExpandableComponent}
              />
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default Customer;
