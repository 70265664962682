import React, { useState, useEffect } from "react";
import Search from "../assets/search-normal.png";
import Line from "../assets/Line 1.png";
import Menu from "../assets/menu.png";
import NavBarLogo from "../assets/navbar-logo.png";
import User from "../assets/user.png";
import { ReactComponent as BoxArrowRight } from "bootstrap-icons/icons/box-arrow-right.svg";
import LogoutButton from "../components/utils/LogoutButton";
import MobileDropdownNavbar from "../../src/components/MobileDropdownNavbar";

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [userPhoto, setUserPhoto] = useState(User);

  // Get user credentials from local storage
  useEffect(() => {
    const authData = localStorage.getItem("auth");
    if (authData) {
      const { email, photo, fullname } = JSON.parse(authData);
      setUserEmail(email || "");
      setUserName(fullname || "");
      setUserPhoto(photo || User);
    }
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <>
      <div className="desktop-navbar hidden md:flex h-[30px] items-center pl-[3%] pr-[8%] mb-16">
        <div className="flex items-center mr-[5%] mt-10">
          <img
            src={NavBarLogo}
            alt="Joatech Logo"
            className="w-[55px] h-[42px] mr-[19px]"
          />
          <h3 className="gilroy-heavy text-[24px] leading-[28px] tracking-[-0.2px]">
            Joatech
          </h3>
        </div>
        <div className="navbar-right flex items-center gap-[16px] ml-auto mt-10">
          {/* <p className="text-orange1 pt-1">{userEmail}</p> */}
          <p className="text-orange1 pt-1">{userName}</p>
          <div className="relative">

            <div
              className="flex items-center cursor-pointer"
              onClick={toggleDropdown}
            >
              <div className="user w-10 h-10">

                <img
                  src={userPhoto}
                  alt="user icon"
                  className="w-10 h-10 rounded-full object-cover"
                />
              </div>
            </div>
            {isDropdownOpen && (
              <div
                className="absolute right-0 mt-2 w-64 rounded-lg shadow-md ring-black ring-opacity-5 bg-dGrey3 transition-transform transform scale-100 opacity-100"
                style={{ zIndex: 50 }}
              >
                <div className="py-4 flex flex-col items-center">
                  <img
                    src={userPhoto}
                    alt="user"
                    className="w-16 h-16 rounded-full mb-3 object-cover"
                  />
                  <p className="text-lg font-semibold text-dash5">Hi,</p>
                  <p className="text-sm text-gray-700 text-darkGreen3">
                    {userName}
                    {/* {userEmail} */}
                  </p>
                </div>
                <hr className="my-2 border-grey3" />
                <div className="py-2 px-4 flex items-center cursor-pointer hover:bg-gray-100">
                  <BoxArrowRight className="mr-3 text-sm text-dash5" />
                  <LogoutButton />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mobile-navbar md:hidden h-[60px] border-b-[1px] border-darkGrey1 flex gap-[16px] py-[13px] px-[24px]">

        <img
          src={NavBarLogo}
          alt="Joatech Logo"
          className="w-[55px] h-[42px] mr-[19px]"
        />

        <div className="flex-1 flex justify-end">

          <div
            className="user w-10 h-10 cursor-pointer"
            onClick={toggleMobileNav}
          >
            <img src={Menu} alt="menu icon" />
          </div>
        </div>
      </div>
      {isMobileNavOpen && <MobileDropdownNavbar />}
    </>
  );
};

export default Navbar;

