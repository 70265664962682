import { React, useState, useEffect } from "react";

const Footer = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    // Update the current year when the component mounts
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000); // Update every second

    // Clean up interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            {/* <div className="col-md-6">
              {currentYear}. © Owode - www.owode.com
            </div> */}
            <div className="bg-[#F1F1F1] flex flex-col lg:flex-row items-center lg:justify-between py-[16px] px-[26px] lg:px-[120px">
              <div className="text-[16px] leading-[25.6px]">
                © JOATECH GLOBAL LINK INVESTMENT LTD. ALL RIGHTS RESERVED.
                {currentYear}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
