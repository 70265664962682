import React, { useState, useEffect } from "react";
import MainLayouts from "../../../Layout/MainLayouts";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import Spinner from "../../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { handleError } from "../../../components/utils/AuthenticationCheck";
import axios from "axios";
import ApiServices from "../../../api/ApiServices";
import { toast } from "react-toastify";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";

const AddBillers = () => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const navigateTo = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form
    const validateForm = () => {
      if (!name) {
        toast.error("The name fields is required.");
        return false;
      }
      return true;
    };

    if (!validateForm()) return;

    setLoading(true);

    const formData = {
      name,
    };

    // console.log("this is my form data", formData);
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }

      const actualToken = token.split("|")[1];

      const response = await axios.post(ApiServices.addBillers, formData, {
        headers: {
          Authorization: `Bearer ${actualToken}`,
          token: token,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      const { status, message } = response.data;
      if (status === 200) {
        navigateTo("/admin/billers/list");
        toast.success(message, { toastId: "biller-created-successfully" });
        setName("");
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <section
            className="w-full  pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Billers
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1 ">Create</p>
                </li>
              </ol>
            </nav>
          </section>

          <section className="gray_background">
            <div className="container px-1 pb-2 mx-auto">
              <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-1 sm:grid-cols-1 gap-6">
                    <div className="w-full">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                        placeholder="Enter biller name"
                      />
                    </div>
                  </div>

                  <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
                    <Link
                      to="/admin/billers/list"
                      className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
                    >
                      <List className="mr-2" />
                      Back To List
                    </Link>
                    <button
                      type="submit"
                      className={`text-white bg-darkGreen3 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium ${
                        loading ? "btn-loading" : "btn-primary"
                      }`}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Add Biller"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </MainLayouts>
    </div>
  );
};

export default AddBillers;
