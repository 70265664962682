import React, { useEffect, useState } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import Spinner from "../spinner/Spinner";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as ArrowLeftSquare } from "bootstrap-icons/icons/arrow-left-square-fill.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";

const HelpCenterDetails = () => {
    const [ticket, setTicket] = useState(null);
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    const [currentMessagePage, setCurrentMessagePage] = useState(1);
    const [messagesPerPage] = useState(3);

    const location = useLocation();
    const tickets = location.state.tickets;

    useEffect(() => {
        if (tickets) {
            // console.log("this is the ticket details", tickets);
            setTicket(tickets);
            setMessages(tickets.messages || []); // Set messages from the ticket
            setLoading(false);
        } else {
            console.error("No ticket data available");
            setLoading(false);
        }
    }, [tickets]);

    const paginateMessages = (pageNumber) => {
        setCurrentMessagePage(pageNumber);
    };

    // Calculate indices for slicing the messages
    const indexOfLastMessage = currentMessagePage * messagesPerPage;
    const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
    const currentMessages = messages.slice(indexOfFirstMessage, indexOfLastMessage);

    // Determine if there are more pages
    const hasNextPageMessages = () => indexOfLastMessage < messages.length;
    const hasPrevPageMessages = () => currentMessagePage > 1;

    //check if message is today
    const isToday = (date) => {
        const today = new Date();
        return (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        );
    };

    return (
        <MainLayouts>
            <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
                {loading ? (
                    <Spinner loading={loading} />
                ) : (
                    <section className="text-gray-600 body-font">
                        <section
                            className="w-full pl-[3%] pr-[3%] pt-3 my-3"
                            style={{ backgroundColor: "#F7F7F7" }}
                        >
                            <nav className="bg-gray-200 py-0 px-4 rounded-lg ">
                                <ol className="list-none p-0 inline-flex mb-2">
                                    <li className="flex items-center">
                                        <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                                            HelpCenter
                                        </p>
                                        <ChevronRightIcon />
                                    </li>
                                    <li className="flex items-center">
                                        <p className="text-orange1 ">Details</p>
                                    </li>
                                </ol>
                            </nav>
                        </section>
                        <div className="container px-1 py-1 mx-auto">
                            <div className="flex flex-wrap -m-2">
                                <div className="p-2 w-full">
                                    <div className="h-full flex border-grey3 border items-center p-4 rounded-lg">
                                        <div className="flex-grow">
                                            <h2 className="text-dash title-font font-medium">
                                                {ticket.user_info?.name || "Not Available"}
                                            </h2>
                                            <p className="text-gray-500">
                                                <span className="text-dash">User Type: </span>
                                                {ticket.user_info?.user_type || "Not Available"}
                                            </p>
                                            <p className="text-gray-500">
                                                <span className="text-dash">Email: </span>
                                                {ticket.user_info?.email || "Not Available"}
                                            </p>
                                            <p className="text-gray-500">
                                                <span className="text-dash">Description: </span>
                                                {ticket.description || "Not Available"}
                                            </p>
                                            <p className="text-black1">
                                                <span className="text-dash">Date: </span>
                                                {ticket.created_at
                                                    ? new Date(ticket.created_at).toLocaleDateString('en-US', {
                                                        weekday: 'long',
                                                        year: 'numeric',
                                                        month: 'short',
                                                        day: 'numeric',
                                                    })
                                                    : "Not Available"}
                                            </p>
                                        </div>
                                        <Link
                                            to="/admin/help/list"
                                            className="w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                        >
                                            <span className="flex justify-center sm:justify-start mx-2">
                                                <ArrowLeftSquare className="mr-2" />
                                                Back
                                            </span>
                                        </Link>
                                    </div>
                                </div>

                                <div className="px-2 w-full">
                                    <div className="h-full flex items-center border-dash border p-2 rounded-lg border-l-4 ">
                                        <div className="w-full pb-6">
                                            <h3 className="text-lg font-bold">Messages:</h3>
                                            {currentMessages.length > 0 ? (
                                                currentMessages.map((msg) => {
                                                    const messageDate = new Date(msg.created_at);
                                                    return (
                                                        <div key={msg.message_id} className="border-b border-gray-200 py-2 flex justify-between items-center">
                                                            <div className="flex items-center">


                                                                <p className="text-black1">
                                                                    {msg.message}
                                                                </p>
                                                            </div>
                                                            <span className="text-dGrey3 text-sm bg-orange1 px-2 py-1  rounded-md">
                                                                <small className="text-black1 capitalize ">{msg.user_type}
                                                                    {isToday(messageDate) && (
                                                                        <span className="bg-darkGreen3 px-2 py-1 rounded-md text-dGrey3 text-xs mx-2">Today</span>

                                                                    )}

                                                                </small><br></br>
                                           
                                                                {messageDate
                                                                    ? messageDate.toLocaleDateString('en-US', {
                                                                        weekday: 'long',
                                                                        year: 'numeric',
                                                                        month: 'short',
                                                                        day: 'numeric',
                                                                    })
                                                                    : "Not Available"}
                                                            </span>

                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <p>No messages available.</p>
                                            )}


                                            <div className="flex justify-end items-center  mt-4">
                                                <nav aria-label="Page navigation">
                                                    <ul className="pagination flex mx-auto">
                                                        <li className={`page-item ${!hasPrevPageMessages() ? "disabled" : ""}`}>
                                                            <button
                                                                className="text-dGrey3 bg-black1 hover:bg-darkGreen2 focus:outline-none font-medium rounded-full text-sm px-5 py-2"
                                                                onClick={() => paginateMessages(currentMessagePage - 1)}
                                                                disabled={!hasPrevPageMessages()}
                                                            >
                                                                Prev
                                                            </button>
                                                        </li>
                                                        <li className={`page-item ${!hasNextPageMessages() ? "disabled" : ""}`}>
                                                            <button
                                                                className="text-dGrey3 bg-black1 hover:bg-darkGreen2 focus:outline-none font-medium rounded-full text-sm px-5 py-2"
                                                                onClick={() => paginateMessages(currentMessagePage + 1)}
                                                                disabled={!hasNextPageMessages()}
                                                            >
                                                                Next
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </div>
        </MainLayouts>
    );
};

export default HelpCenterDetails;
