import React, { useState, useContext } from "react";
import NavBarLogo from "../../assets/navbar-logo.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import { AuthContext } from "../../AuthProvider/AuthProvider";
import { ReactComponent as Person } from "bootstrap-icons/icons/person-fill.svg";
import { ReactComponent as LockFill } from "bootstrap-icons/icons/lock-fill.svg";
import { ReactComponent as UnLockFill } from "bootstrap-icons/icons/unlock-fill.svg";
import { handleError } from "../../components/utils/AuthenticationCheck";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigateTo = useNavigate();
  // Make sure is avaialable for  setPermissions
  const { setAuth, setPermissions } = useContext(AuthContext);

  const makePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      toast.error("All fields are required.");
      return;
    }

    if (!validateEmail(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        ApiServices.adminLogin,
        { email, password },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const { status, message, data } = response.data;

      if (status === 200) {
        const is2fa = data?.is2fa;

        if (is2fa) {
          localStorage.setItem("AdminEmail", email);
          navigateTo("/login/token");
          toast.success(message);
        } else {
          const { token, admin: { email, admin_id, fullname } } = data;
          localStorage.setItem("auth", JSON.stringify({ email, token, user: admin_id, fullname }));
          setAuth({ email, token, admin_id, fullname });


          const permissionResponse = await axios.get(ApiServices.getAdminPermissionsByid, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            params: { admin_id },
            withCredentials: true,
          });
          // Log the entire permission response
          // console.log("Permission response:", permissionResponse);

          // Accessing the permissions from the data array
          const permissions = permissionResponse.data.data;
          // console.log("Fetched permissions:", permissions);

          // Setting permissions in context
          setPermissions(permissions);

          // Clean form after submission
          setEmail("");
          localStorage.removeItem("AdminEmail");
          navigateTo("/admin/dashboard");
          toast.success(message, { toastId: "admin-log-in-successfully" });
        }
      } else {
        toast.error(message, { toastId: "admin-log-failed" });
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="text-gray-600 body-font mt-4">
      <div className="container mx-auto flex flex-col px-5 py-24 justify-center items-center">
        <img
          className="mb-10 object-cover object-center rounded"
          alt="hero"
          src={NavBarLogo}
        />
        <div className="w-full md:w-2/3 flex flex-col mb-16 items-center">
          <h1 className="text-darkGreen3">JOATECH ADMIN PORTAL</h1>
          <div className="xl:w-2/4 md:w-2/2 p-1">
            <div
              className="border border-grey3 p-6 rounded-lg shadow-lg"
              style={{ backgroundColor: "white" }}
            >
              <form className="mx-auto" onSubmit={handleSubmit}>
                <div className="mb-5">
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Email
                  </label>
                  <div className="relative">
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="pr-12 pl-4 shadow-sm bg-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                      placeholder="Email"
                      required
                    />
                    <Person className="absolute inset-y-0 right-0 flex items-center pr-3 text-blue-500 h-8 w-8" />
                  </div>
                </div>
                <div className="mb-5">
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Password
                  </label>
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="pr-12 pl-4 shadow-sm bg-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                      placeholder="Password"
                      required
                    />
                    <div
                      className="absolute inset-y-0 right-0 flex items-center pr-3 text-blue-500 cursor-pointer"
                      onClick={makePasswordVisibility}
                      aria-label="Toggle password visibility"
                    >
                      {showPassword ? <UnLockFill /> : <LockFill />}
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="text-dGrey3 bg-darkGreen3 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center w-full my-1"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Log In"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
