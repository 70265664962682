import React, { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as ArrowLeftSquare } from "bootstrap-icons/icons/arrow-left-square-fill.svg";
import axios from "axios";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { handleError } from "../../components/utils/AuthenticationCheck";

const AddProductStockVariantSupply = () => {
  const [name, setName] = useState("");
  const [add_quantity, setAddQuantity] = useState("");
  const [stock_id, setStockId] = useState("");
  const [import_csv, setImport_Csv] = useState(null);
  const [is_serialized, setIsSerialized] = useState(false);
  const [stock_variant_id, setStockVariantId] = useState("");
  const [loading, setLoading] = useState(false);

  const navigateTo = useNavigate();
  const location = useLocation();
  const productStock = location.state?.productStock;

  useEffect(() => {
    if (productStock) {
      console.log("this is the product here for use ", productStock);
      setName(productStock.name || "");
      setStockId(productStock.stock_id || "");
      setStockVariantId(productStock.variant_id || "");
    }
  }, [productStock]);


  useEffect(() => {
    const fetchProductStockDetails = async () => {
      setLoading(true);
      try {
        // Retrieve token from local storage
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) {
          throw new Error("No access token found.");
        }

        // Make the API call
        const response = await axios.get(ApiServices.getAllProductsStock, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          params: { stock_id },
          withCredentials: true,
        });

        if (response.status === 200) {
          const { data, message } = response.data;

          console.log('Product Details:', data);

          // Find the product where stock_id matches
          const product = data.find((product) => product.stock_id === stock_id);
          console.log('Product with the serialize Details:', product);
          // Set the serialized state
          if (product) {
            setIsSerialized(product.is_serialized);
          }
        } else {
          // Handle non-200 status
          handleError({ response: { status: response.status, data: { message: response.statusText } } }, navigateTo);
        }
      } catch (error) {
        // Catch any errors during the API call and handle them
        handleError(error, navigateTo);
      } finally {
        // Final steps regardless of success or failure
        setLoading(false);
      }
    };

    // Trigger the API call when component mounts or dependencies change
    fetchProductStockDetails();

  }, [stock_id, navigateTo]); // Dependency array includes stock_id and navigateTo


  // Validation function
  const validateForm = () => {
    if (!stock_variant_id || !stock_id || !add_quantity) {
      toast.error("All fields are required.");
      return false;
    }
    return true;
  };

  // Handle submit button
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setLoading(true);
    const formData = {
      add_quantity,
      stock_id,
      stock_variant_id
    };
    // console.log(formData);
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) throw new Error("No access token found.");

      const response = await axios.post(
        ApiServices.addProductStockSupply,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const { status, message } = response.data;
      if (status === 200) {
        toast.success(message, {
          toastId: "product-stock-supply-added-success",
        });
        navigateTo("/admin/product/stock/details", {
          state: { stock_id: formData.stock_id },
        });
        setAddQuantity("");
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  const handleBackToList = () => {
    navigateTo("/admin/product/stock/details", {
      state: { stock_id },
    });
  };

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          {/* form here */}
          <section className=" body-font">
            <div className="container px-5 pb-2 mx-auto ">
              <section
                className="w-full  pr-[3%] pt-3 my-3"
                style={{ backgroundColor: "#F7F7F7" }}
              >
                <nav className="bg-gray-200 py-0 px-4 rounded-lg">
                  <ol className="list-none p-0 inline-flex mb-2">
                    <li className="flex items-center">
                      <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                        Product Stock Variant
                      </p>
                      <ChevronRightIcon />
                    </li>
                    <li className="flex items-center">
                      <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                        Supply
                      </p>
                      <ChevronRightIcon />
                    </li>
                    <li className="flex items-center">
                      <p className="text-orange1 ">Create</p>
                    </li>
                  </ol>
                </nav>
              </section>
              <div className="lg:w-full md:w-full bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <input type="hidden" name="stock_id" value={stock_id} />
                    <input
                      type="hidden"
                      name="stock_variant_id"
                      value={stock_variant_id}
                    />
                    <div className="w-full">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Stock Name
                      </label>
                      {/* <input
                        type="text"
                        name="name"
                        id="name"
                        defaultValue={name}
                        readOnly
                        className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      /> */}
                      <div className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                        {name}
                      </div>
                    </div>

                    <div className="w-full">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Quantity
                      </label>
                      <input
                        type="text"
                        name="add_quantity"
                        id="add_quantity"
                        value={add_quantity}
                        onChange={(e) => setAddQuantity(e.target.value)}
                        className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Enter quantity"
                      />
                    </div>
                  </div>
                  {/* Conditionally render the file input */}
                  {is_serialized && (
                    <>
                      <label className="block mt-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">
                        Serial Number <small className="text-orange1">Upload a serial number in CSV format</small>
                      </label>
                      <input
                        className="w-full p-4 my-3 bg-gray-50 border border-dGrey1 text-black1 text-sm rounded-lg"
                        id="file_input"
                        type="file"
                        accept=".csv" // Restrict file type to CSV only
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            const validTypes = ['text/csv', 'application/csv', 'text/plain'];
                            console.log('File type:', file.type); // Debugging line
                            if (!validTypes.includes(file.type)) {
                              toast.error("Please upload a valid CSV file.");
                              e.target.value = ""; // Clear the input
                            } else {
                              setImport_Csv(file); // Set the file if it's valid
                            }
                          }
                        }}
                      />
                    </>
                  )}
                  <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
                    <Link
                      to="/admin/product/stock/details"
                      // state={{ stock_id: productStock?.stock_id }}
                      state={{ stock_id }}
                      className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
                    >
                      <List className="mr-2" />
                      Back To List
                    </Link>
                    <button
                      type="submit"
                      className={`text-white bg-darkGreen3 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium ${loading ? "btn-loading" : "btn-primary"
                        }`}
                      disabled={loading}
                    >
                      {loading
                        ? "Loading..."
                        : "Add Quantity To product Stock Variant"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </MainLayouts>
    </div>
  );
};

export default AddProductStockVariantSupply;
