
import MainLayouts from "../Layout/MainLayouts";
import NavBarLogo from "../assets/navbar-logo.png";

const PageNotFound = () => {
  return (
    <div>
      <MainLayouts>
        <section className="text-gray-600 body-font">
          <div className="container mx-auto flex flex-col px-5 py-24 justify-center items-center">
            <img
              className="mb-10 object-cover object-center rounded"
              alt="hero"
              src={NavBarLogo}
              />
              <h1>Page not found</h1>
            <div className="w-full md:w-2/3 flex flex-col mb-16 items-center ">
             <p>404 - PAGE NOT FOUND </p>
            </div>
          </div>
        </section>
      </MainLayouts>
    </div>
  );
};

export default PageNotFound;
