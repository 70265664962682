import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as ArrowLeftSquare } from "bootstrap-icons/icons/arrow-left-square-fill.svg";
import Spinner from "../spinner/Spinner";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import { ReactComponent as ArrowLeftCircleLeftFill } from "bootstrap-icons/icons/arrow-left-circle-fill.svg";
import { ReactComponent as ArrowRightCircleLeftFill } from "bootstrap-icons/icons/arrow-right-circle-fill.svg";
import CurrencyFormatter from "../../components/utils/CurrencyFormatter";
import userEvent from "@testing-library/user-event";
import { handleError } from "../../components/utils/AuthenticationCheck";

const StoreDetails = () => {
  const [storeDetails, setstoreDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 1;

  const navigateTo = useNavigate();
  const location = useLocation();
  //get store id row data here
  // const store = location.state?.store;
  const id = location?.state.id;
  // console.log("i am the store id", id);

  useEffect(() => {
    const fetchStoreDetails = async (store_id) => {
      setLoading(true);
      const formData = {
        store_id: id,
      };
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) {
          throw new Error("No access token found.");
        }

        const response = await axios.get(ApiServices.getAllStoreById, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          params: { store_id: id },
          withCredentials: true,
        });

        const { status, message, data } = response.data;
        // console.log("storeDetails response", data);
        if (status === 200) {
          setstoreDetails(data);

          toast.success(message, { toastId: "store-details-fetch-success" });
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setLoading(false);
      }
    };

    
    fetchStoreDetails();
  }, [id]);


  const indexOfLastItem = currentPage * itemsPerPage;
  const currentItems = storeDetails.images
    ? storeDetails.images.slice(indexOfLastItem - itemsPerPage, indexOfLastItem)
    : [];

  const hasNextPage = indexOfLastItem < (storeDetails.images?.length || 0);
  const hasPrevPage = currentPage > 1;

  const handleNextPage = () => {
    if (hasNextPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (hasPrevPage) {
      setCurrentPage(currentPage - 1);
    }
  };



  return (
    <div>
      <MainLayouts>
        <div className="relative sm:rounded-lg py-6 w-full">
          {/* <h1 className="text-dash md:text-lg">storeDetails Details</h1> */}
          <section
            className="w-full pl-[3%] pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg ">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Vendor Store
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1"> Details</p>
                </li>
              </ol>
            </nav>
          </section>
          <section className="text-gray-600 body-font">
            <div className="container">
              <div className="flex justify-end mb-3">
                <Link
                  to="/admin/store/list"
                  className="w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  <span className="flex justify-center sm:justify-start mx-2">
                    <ArrowLeftSquare className="mr-2" />
                    Back
                  </span>
                </Link>
              </div>
              {loading ? (
                <Spinner loading={loading} />
              ) : (
                <div className="flex flex-wrap -m-2">
                  {/* <div className="p-2 w-full lg:w-1/5">
                    <div className="flex items-center border-dash p-1 border rounded-lg border-l-4">
                      <div className="relative sm:rounded-lg">
                        {currentItems.length > 0 ? (
                          <img
                            alt="product image"
                            className="w-full lg:h-auto h-64 rounded"
                            src={currentItems[0].image_url}
                          />
                        ) : (
                          <img
                            alt="placeholder"
                            className="w-full lg:h-auto h-64 rounded"
                            src="https://via.placeholder.com/200"
                          />
                        )}
                      </div>
                    </div>

                    <div className="flex w-full mt-3">
                      <button
                        className="justify-end text-dGrey3 bg-black1 border-0 py-1 px-1 rounded mr-1"
                        onClick={handlePrevPage}
                        disabled={!hasPrevPage}
                      >
                        <span className="flex items-center">
                          <ArrowLeftCircleLeftFill className="w-4 h-4 mr-1" />
                          Prev
                        </span>
                      </button>
                      <button
                        className="text-dGrey3 bg-dash border-0 py-1 px-1 rounded"
                        onClick={handleNextPage}
                        disabled={!hasNextPage}
                      >
                        <span className="flex items-center">
                          <ArrowRightCircleLeftFill className="w-4 h-4 mr-1" />
                          Next
                        </span>
                      </button>
                    </div>
                  </div> */}

                  <div className="px-1 w-full lg:w-5/5 mt-2">
                    <div className="flex items-center border-dash border p-1 rounded-lg border-l-4">
                      <section className="body-font w-full">
                        <div className="container px-2">
                          <div className="w-full flex flex-wrap">
                            <div className="w-full px-4">
                              <h2 className="text-sm title-font mt-2">
                                {storeDetails.name
                                  ? storeDetails.name.toUpperCase()
                                  : "Not Available"}
                              </h2>
                              <h1 className="text-dash text-3xl title-font font-medium mb-1">
                                {storeDetails.telephone
                                  ? storeDetails.telephone.toUpperCase()
                                  : "Not Available"}
                              </h1>
                              <span className="bg-dash text-dGrey3 text-xs font-medium me-2 px-3 py-1 rounded-full dark:bg-blue-900 dark:text-blue-300">
                                {storeDetails.status
                                  ? storeDetails.status.toUpperCase()
                                  : "Not Available"}
                              </span>
                              <p className="leading-relaxed mb-4 mt-3">
                                {storeDetails.description
                                  ? storeDetails.description
                                  : "Not Available"}
                              </p>
                              <div className="flex border-t border-grey3 py-2">
                                <span className="text-gray-500">
                                  Owner Type
                                </span>
                                <span className="ml-auto text-dash">
                                  {storeDetails.owner_type
                                    ? storeDetails.owner_type.toUpperCase()
                                    : "Not Available"}
                                </span>
                              </div>
                              <div className="flex border-t  border-grey3 py-2">
                                <span className="text-gray-500">Email</span>
                                <span className="ml-auto text-dash">
                                  {storeDetails.owner?.email
                                    ? storeDetails.owner.email.toUpperCase()
                                    : "Not Available"}
                                </span>
                                {/* <span className="ml-auto text-dash">
                                {email.email
                                  ? email.email.toUpperCase()
                                  : "Not Available"}
                              </span> */}
                              </div>
                              <div className="flex border-t  border-grey3 py-2">
                                <span className="text-gray-500">
                                  Notification Status
                                </span>

                                <span
                                  className={`ml-auto rounded-md  px-2 ${
                                    storeDetails.notification_status === 1
                                      ? "bg-dash text-lGrey3"
                                      : "bg-dash3 text-lGrey3  p-1 "
                                  }`}
                                >
                                  {storeDetails.notification_status === 1
                                    ? "Active"
                                    : storeDetails.notification_status === 0
                                    ? "Nonactive"
                                    : "Not Available"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </MainLayouts>
    </div>
  );
};

export default StoreDetails;
