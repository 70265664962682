import React, { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";
import { ReactComponent as CloudUploadFill } from "bootstrap-icons/icons/cloud-upload-fill.svg";
import { ReactComponent as ArrowLeftSquare } from "bootstrap-icons/icons/arrow-left-square-fill.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { handleError } from "../../components/utils/AuthenticationCheck";

const AddImagesToProductStockVariant = () => {
  const [images, setImages] = useState([]);
  const [stock_id, setStockId] = useState("");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const [stock_variant_id, setStockVariantId] = useState("");

  const navigateTo = useNavigate();
  const location = useLocation();

  const productStock = location.state?.productStock;

  useEffect(() => {
    if (productStock) {
      console.log("product stock is here", productStock);
      setName(productStock.name || "");
      setValue(productStock.value || "");
      setStockId(productStock.stock_id || "");
      setStockVariantId(productStock.variant_id || "");
    }
  }, [productStock]);

  const validateForm = () => {
    if (!images.length || !stock_id || !stock_variant_id) {
      toast.error("The image field is required.");
      return false;
    }
    return true;
  };

  //convert images to base 64
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileChange = async (e) => {
    const filesArray = Array.from(e.target.files);
    const base64Images = [];

    for (const file of filesArray) {
      if (!file.type.startsWith("image/")) {
        toast.info("Only image files are allowed.");
        continue; // Skip non-image files
      }
      if (file.size > 5 * 1024 * 1024) {
        // limit image size to 5MB
        toast.info("File size exceeds 5MB limit.");
        continue; // Skip files that are too large
      }
      try {
        const base64 = await convertToBase64(file);
        base64Images.push(base64);
      } catch (error) {
        toast.error("Failed to convert image.");
      }
    }
    setImages(base64Images);
  };

  // Preview of selected images
  const renderImagePreviews = () => {
    return images.map((image, index) => (
      <img
        key={index}
        src={image}
        alt={`Preview ${index}`}
        className="w-20 h-20 object-cover"
      />
    ));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);

    const formData = {
      stock_id: stock_id,
      images: images.map((image) => {
        // Split to remove 'data:image/png;base64,' part
        return image.split(",")[1];
      }),
      stock_variant_id,
    };
    // console.log("Form data:", formData);

    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) throw new Error("No access token found.");

      const response = await axios.post(
        ApiServices.addProductStockVariantImages,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const { status, message } = response.data;
      if (status === 200) {
        toast.success(message, {
          toastId: "product-stock-variant-image-added-successfully",
        });
      
        navigateTo("/admin/product/stock/details", {
          state: { stock_id: formData.stock_id },
        });

        setImages("");
        setName("");
        setValue("");
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainLayouts>
      <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
        <section
          className="w-full pr-[3%] pt-3 my-3"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          <nav className="bg-gray-200 py-0 px-4 rounded-lg">
            <ol className="list-none p-0 inline-flex mb-2">
              <li className="flex items-center">
                <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                  Add Product Stock Variant
                </p>
                <ChevronRightIcon />
              </li>
              <li className="flex items-center">
                <p className="text-orange1">Images</p>
              </li>
            </ol>
          </nav>
        </section>

        <section className="gray_background">
          <div className="container px-1 pb-2 mx-auto">
            <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
              <form onSubmit={handleSubmit}>
                <input type="hidden" name="stock_id" value={stock_id} />
                <input
                  type="hidden"
                  name="stock_variant_id"
                  value={stock_variant_id}
                />
                <div className="flex flex-col md:flex-row gap-6 mt-4">
                  <div className="flex-1">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Name
                    </label>
                    {/* <input
                      type="text"
                      id="name"
                      value={name}
                      readOnly
                      className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    /> */}
                    <div className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                      {name}
                    </div>
                  </div>
                  <div className="flex-1">
                    <label
                      htmlFor="value"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Value
                    </label>
                    {/* <input
                      type="text"
                      id="value"
                      value={value}
                      readOnly
                      className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    /> */}
                    <div className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                      {value}
                    </div>
                  </div>
                </div>

                <div className="mt-6">
                  {loading && (
                    <div className="mb-4 text-sm text-orange1">
                      Loading, please wait...
                    </div>
                  )}
                  {/* Inside your return statement */}
                  <div className="flex flex-col my-4">
                    {images.length > 0 && (
                      <div className="flex space-x-2">
                        {renderImagePreviews()}
                      </div>
                    )}
                  </div>
                  <div className="flex items-center justify-center w-full">
                    <label
                      htmlFor="images"
                      className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-orange1 rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                    >
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <CloudUploadFill className="mr-2 w-8 h-8 text-orange1" />

                        <p className="mb-2 text-sm text-darkGreen3">
                          <span className="font-semibold">Click to upload</span>{" "}
                          or drag and drop
                        </p>
                        {/* <p className="text-xs text-orange1 ">
                          SVG, PNG, JPG or GIF (MAX. 800x400px)
                        </p> */}
                      </div>
                      <input
                        id="images"
                        type="file"
                        accept="image/*"
                        multiple // Allow multiple file selection
                        onChange={handleFileChange}
                        className="hidden"
                      />
                    </label>
                  </div>
                </div>

                <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
                  <Link
                    to="/admin/product/stock/details"
                    state={{ stock_id }}
                    className=" mx-2 w-full sm:w-auto text-dGrey3 bg-black1 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    <span className="flex justify-center sm:justify-start mx-2">
                      <ArrowLeftSquare className="mr-2" />
                      Back
                    </span>
                  </Link>
                  <button
                    type="submit"
                    className={`text-white bg-darkGreen3 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium ${
                      loading ? "btn-loading" : "btn-primary"
                    }`}
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Add To Product Stock Variant"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </MainLayouts>
  );
};

export default AddImagesToProductStockVariant;
