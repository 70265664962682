
import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import Spinner from "../spinner/Spinner";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import { handleError } from "../../components/utils/AuthenticationCheck";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";

const UpdateOrders = () => {
    const [orderId, setOrderId] = useState("");
    const [status, setStatus] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const navigateTo = useNavigate();

    // Get the order details from the location state
    const orders = location.state?.orders;

    useEffect(() => {
        if (orders) {
            console.log("na me be order", orders);
            setOrderId(orders.id || "");
            setStatus(orders.status || "");
            setCustomerName(orders.user_name || "");
            // Set loading to false after data is set
            setLoading(false); 
        }
    }, [orders]);

    // Validation function
    const validateForm = () => {
        if (!status) {
            toast.error("Please select a status.");
            return false;
        }
        return true;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        setLoading(true);
        const formData = { order_id: orderId, status: status };

        try {
            const authData = JSON.parse(localStorage.getItem("auth"));
            const token = authData?.token;
            if (!token) {
                throw new Error("No access token found.");
            }

            const response = await axios.put(
                ApiServices.updateOrders,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );

            const { status: responseStatus, message } = response.data;
            if (responseStatus === 200) {
                toast.success(message, { toastId: "order-updated-successfully" });
                navigateTo("/admin/order/list");
            } else {
                handleError({ response: { status: responseStatus, data: { message } } }, navigateTo);
            }
        } catch (error) {
            handleError(error, navigateTo);
        } finally {
            setLoading(false);
        }
    };

    return (
        <MainLayouts>
            <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
                <section className="w-full pr-[3%] pt-3 my-3" style={{ backgroundColor: "#F7F7F7" }}>
                    <nav className="bg-gray-200 py-0 px-4 rounded-lg">
                        <ol className="list-none p-0 inline-flex mb-2">
                            <li className="flex items-center">
                                <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                                    Orders
                                </p>
                                <ChevronRightIcon />
                            </li>
                            <li className="flex items-center">
                                <p className="text-orange1">Update</p>
                            </li>
                        </ol>
                    </nav>
                </section>

                {loading ? (
                    <Spinner loading={loading} />
                ) : (
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" name="order_id" value={orderId} />
                        <div className="d-flex">
                            <div className="w-full mb-3">
                                <label
                                    htmlFor="user_name"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Customer Name
                                </label>
                                <input
                                    type="text"
                                    id="user_name"
                                    value={customerName}
                                    className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                    readOnly
                                />
                            </div>

                            <div className="w-full">
                                <label
                                    htmlFor="status"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Status
                                </label>
                                    {status && (
                                        <p className="mb-2 text-sm text-gray-700">
                                            Current Status: <strong className="text-darkGreen3">{status}</strong>
                                        </p>
                                    )}
                                <select
                                    id="status"
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                    className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                >
                                    <option value="" disabled>
                                        Select status
                                    </option>
                                    <option value="processing">Processing</option>
                                    <option value="cancel">Cancel</option>
                                    <option value="shipped">Shipped</option>
                                </select>
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
                            <Link
                                to="/admin/order/list"
                                className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
                            >
                                <List className="mr-2" />
                                Back To List
                            </Link>
                            <button
                                type="submit"
                                className={`text-white bg-darkGreen3 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium ${loading ? "btn-loading" : "btn-primary"}`}
                                disabled={loading}
                            >
                                {loading ? "Loading..." : "Update Order"}
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </MainLayouts>
    );
};

export default UpdateOrders;


