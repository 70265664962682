import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import ApiServices from "../api/ApiServices";
import { toast } from "react-toastify";
import Spinner from "../pages/spinner/Spinner";


export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    const storedAuth = localStorage.getItem("auth");
    return storedAuth ? JSON.parse(storedAuth) : null;
  });

  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPermissions = async () => {
      setLoading(true);
      try {
        const storedAuth = JSON.parse(localStorage.getItem("auth"));
        const token = storedAuth?.token;
        const admin_id = storedAuth?.user;
        if (!token || !admin_id) {
          throw new Error("No access token or admin_id found.");
        }

        const response = await axios.get(ApiServices.getAdminPermissionsByid, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          params: { admin_id },
          withCredentials: true,
        });

        const { status, message, data } = response.data;

        if (status === 200) {
          setPermissions(data);
          // toast.success(message, { toastId: "permission-fetch-success" });
        } else {
          toast.error(message, { toastId: "permission-fetch-error" });
        }
      } catch (error) {
        console.error("Error fetching permissions:", error);
        toast.error("Failed to fetch permissions");
      } finally {
        setLoading(false);
      }
    };

    if (auth) {
      fetchPermissions();
    } else {
      setLoading(false);
    }
  }, [auth]);

  const logout = () => {
    setAuth(null);
    setPermissions([]);
    localStorage.removeItem("auth");
    localStorage.removeItem("user_id");
  };

  // useEffect(() => {
  //   console.log('Current User Permissions:', permissions);
  // }, [permissions]);

  return (
    <AuthContext.Provider value={{ auth, setAuth, permissions, setPermissions, logout }}>
      {loading ? (
        <div className="flex justify-center items-center h-screen w-screen">
          <Spinner loading={loading} />
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};


export default AuthProvider;






