import React, { useRef, useContext } from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../../api/ApiServices";
import { toast } from "react-toastify";
import { AuthContext } from "../../../AuthProvider/AuthProvider";
import MainLayouts from "../../../Layout/MainLayouts";
import { ReactComponent as LockFill } from "bootstrap-icons/icons/lock-fill.svg";
import { ReactComponent as UnLockFill } from "bootstrap-icons/icons/unlock-fill.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";

const ChangePassword = () => {
  const [old_password, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setPasswordConfirmation] = useState("");
  const navigateTo = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordTwo, setShowOldPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const makeOldPasswordVisibility = () => {
    setShowOldPassword(!showPasswordTwo);
  };

  const makePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const makeConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validateForm = () => {
      if (!old_password || !password || !confirm_password) {
        toast.error("All fields are required.");
        return false;
      }
      return true;
    };

    if (!validateForm()) return;

    const authData = JSON.parse(localStorage.getItem("auth"));
    const adminToken = authData?.token;

    setLoading(true);
    const formData = {
      old_password,
      password,
      confirm_password,
    };
    console.log("this is my form data for password update", formData);
    console.log("this is the form data token", adminToken);
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }

      const response = await axios.post(ApiServices.updatePassword, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      const { status, message } = response.data;
      if (status === 200) {
        setOldPassword("");
        setPassword("");
        setPasswordConfirmation("");
        navigateTo("/admin/password/change");
        toast.success(message);
      } else {
        handleError(status, message);
      }
    } catch (error) {
      console.error("Error updating  password:", error.message);
    } finally {
      setLoading(false);
    }
  };

  //handle error response

  const handleError = (status, message) => {
    if (status === 300) {
      toast.error(message);
    } else if (status === 302) {
      localStorage.removeItem("auth");
      toast.error(message);
      navigateTo("/");
    } else if (status === 400) {
      toast.error(message);
      navigateTo("/admin/password/change");
    } else if (status === 401) {
      localStorage.removeItem("auth");
      toast.error(message);
      navigateTo("/");
    } else if (status === 422) {
      toast.error(message);
    }
  };

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <section
            className="w-full  pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Password
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 ">Update</p>
                </li>
              </ol>
            </nav>
          </section>

          <section className="gray_background">
            <div className="container px-1 pb-2 mx-auto">
              <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
                <form className=" mx-auto " onSubmit={handleSubmit}>
                  <div className="mb-5">
                    <label
                      htmlFor="password"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Old Password
                    </label>
                    <div className="relative">
                      <input
                        type={showPasswordTwo ? "text" : "password"}
                        id="password"
                        value={old_password}
                        onChange={(e) => setOldPassword(e.target.value)}
                        className="pr-12 pl-4 shadow-sm bg-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                        placeholder="Enter your old password"
                      />

                      <div
                        className="absolute inset-y-0 right-0 flex items-center pr-3 text-blue-500 cursor-pointer"
                        onClick={makeOldPasswordVisibility}
                      >
                        {showPasswordTwo ? <UnLockFill /> : <LockFill />}
                      </div>
                    </div>
                  </div>
                  <div className="mb-5">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      New Password
                    </label>
                    <div className="relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="pr-12 pl-4 shadow-sm bg-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                        placeholder="Enter your new password"
                      />
                      <div
                        className="absolute inset-y-0 right-0 flex items-center pr-3 text-blue-500 cursor-pointer"
                        onClick={makePasswordVisibility}
                      >
                        {showPassword ? <UnLockFill /> : <LockFill />}
                      </div>
                    </div>
                  </div>
                  <div className="mb-5">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Confirm Password
                    </label>
                    <div className="relative">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        id="confirm_password"
                        value={confirm_password}
                        onChange={(e) =>
                          setPasswordConfirmation(e.target.value)
                        }
                        className="pr-12 pl-4 shadow-sm bg-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                        placeholder="Enter confirm password"
                        required
                      />
                      <div
                        className="absolute inset-y-0 right-0 flex items-center pr-3 text-blue-500 cursor-pointer"
                        onClick={makeConfirmPasswordVisibility}
                      >
                        {showConfirmPassword ? <UnLockFill /> : <LockFill />}
                      </div>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className={`text-white bg-darkGreen3 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium w-full my-1 ${
                      loading ? "btn-loading" : "btn-primary"
                    }`}
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Update Password"}
                  </button>
                </form>
              </div>
            </div>
          </section>
        </div>
      </MainLayouts>
    </div>
  );
};

export default ChangePassword;
