import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as ArrowLeftSquare } from "bootstrap-icons/icons/arrow-left-square-fill.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import CurrencyFormatter from "../../components/utils/CurrencyFormatter";

const PaymentDetails = () => {
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigateTo = useNavigate();
  const location = useLocation();
  const id = location.state.id;
  console.log(id);

  //format date
  const formatDate = (dateString) => {
    // Check if dateString is null or undefined
    if (!dateString) {
      return "Not Available";
    }
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`http://localhost:3000/payment/${id}`);
        console.log(response);
        setPaymentDetails(response.data);
      } catch (error) {
        console.error("Error fetching customer details:", error.message);
        // toast.error("Error fetching customer details");
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentDetails();
  }, [id]);

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <h1 className="text-dash md:text-lg">Payment Details</h1>

          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <section className="text-gray-600 body-font">
              <div className="container">
                <div className="flex justify-end mb-3">
                  <Link
                    to="/admin/payment"
                    className="w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    <span className="flex justify-center sm:justify-start mx-2">
                      <ArrowLeftSquare className="mr-2" />
                      Back
                    </span>
                  </Link>
                </div>
                <div className="flex flex-wrap ">
                  <div className="px-2 w-full ">
                    <div class=" border-dash border  rounded-lg px-2 my-2 border-l-4">
                      <div className="flex items-center py-2">
                        <img
                          alt="team"
                          className="w-16 h-16  object-cover object-center flex-shrink-0 rounded-full mr-4"
                          src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                            paymentDetails.name
                              ? paymentDetails.name
                              : "Not Available"
                          )}`}
                        />
                        <div className="w-full py-6">
                          <h1 className="text-sm title-font pb-2">
                            {paymentDetails.name
                              ? paymentDetails.name.toUpperCase()
                              : "Not Available"}
                          </h1>
                          <p className="text-dash title-font font-medium mb-1 hidden md:block ">
                            {paymentDetails.email
                              ? paymentDetails.email
                              : "Not Available"}
                          </p>
                        </div>
                      </div>

                      <div className="w-full  pb-6">
                        <div className="flex border-t border-gray-200 py-2 ">
                          <span className="text-gray-500">Email Address</span>
                          <span className="ml-auto text-dash">
                            {paymentDetails.email
                              ? paymentDetails.email
                              : "Not Available"}
                          </span>
                        </div>
                        <div className="flex border-t border-gray-200 py-2 ">
                          <span className="text-gray-500">Product</span>
                          <span className="ml-auto text-dash">
                            {paymentDetails.product
                              ? paymentDetails.product.toUpperCase()
                              : "Not Available"}
                          </span>
                        </div>
                        <div className="flex border-t border-gray-200 py-2">
                          <span className="text-gray-500">
                            Transaction Reference{" "}
                          </span>
                          <span className="ml-auto text-dash">
                            {paymentDetails.transactionId
                              ? paymentDetails.transactionId
                              : "Not Available"}
                          </span>
                        </div>
                        <div className="flex border-t border-gray-200 py-2">
                          <span className="text-gray-500">Payment Method</span>
                          <span className="ml-auto text-dash">
                            {paymentDetails.paymentMethod
                              ? paymentDetails.paymentMethod.toUpperCase()
                              : "Not Available"}
                          </span>
                        </div>

                        <div className="flex border-t border-gray-200 py-2">
                          <span className="text-gray-500">
                            Payment Confirmation
                          </span>
                          <span className="ml-auto text-dash">
                            {paymentDetails.paymentConfirmed
                              ? paymentDetails.paymentConfirmed.toUpperCase()
                              : "Not Available"}
                          </span>
                        </div>
                        <div className="flex border-t border-gray-200 py-2">
                          <span className="text-gray-500">Payment date</span>
                          <span className="ml-auto text-dash">
                            {paymentDetails.paymentDate
                              ? paymentDetails.paymentDate
                              : "Not Available"}
                          </span>
                        </div>

                        <div className="flex ">
                          <span className="title-font font-medium text-2xl">
                            {paymentDetails.amount
                              ? CurrencyFormatter(paymentDetails.amount)
                              : "Not Available"}
                          </span>
                        </div>
                        <div className="flex justify-end">
                          <span
                            className="title-font font-medium "
                            style={{
                              backgroundColor: "#1D9E34",
                              padding: "7px",
                              color: "white",
                            }}
                          >
                            Product Discount : &nbsp;
                            {paymentDetails.discount
                              ? paymentDetails.discount
                              : "Not Available"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default PaymentDetails;
