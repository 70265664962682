import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as Trash } from "bootstrap-icons/icons/trash3.svg";
import { ReactComponent as PlusSquare } from "bootstrap-icons/icons/plus-square.svg";
import { ReactComponent as PencilSquare } from "bootstrap-icons/icons/pencil-square.svg";
import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import { ReactComponent as ArrowLeftSquare } from "bootstrap-icons/icons/arrow-left-square-fill.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import CustomStyles from "../../components/utils/CustomStyles";
import { handleError } from "../../components/utils/AuthenticationCheck";

const SubCategoryList = () => {
  const [subCategory, setSubCategory] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const location = useLocation();
  const navigateTo = useNavigate();

  // Fetch all subcategories
  const fetchSubCategoryList = async (id) => {
    setLoading(true);
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }

      // console.log("Fetching subcategories with category_id:", id);

      const response = await axios.get(ApiServices.getSubCategoryById, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { category_id: id },
        withCredentials: true,
      });
      const { status, message, data } = response.data;

      if (status === 200 && Array.isArray(data)) {
        setSubCategory(data);
        toast.success(message, { toastId: "sub-category-fetch-success" });
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  // Initialize categoryId and fetch subcategories
  useEffect(() => {
    const categoryData = location.state?.categoryId;

    if (categoryData) {
      // console.log("Category data is here:", categoryData);
      const id = Number(categoryData.category_id || categoryData);
      // console.log("This is the return category id:", id);

      setCategoryId(id);
      setCategoryName(categoryData.name || "");
      setLoading(true);
      fetchSubCategoryList(id);
    }
  }, [location.state]);

  // Initialize categoryId and fetch subcategories
  useEffect(() => {
    const subCategoryId = location.state?.id;

    if (subCategoryId) {
      // console.log("Category data is here:", categoryData);
      const id = Number(subCategoryId.category_id || subCategoryId);
      // console.log("This is the return category id:", id);

      setCategoryId(id);
      setCategoryName(subCategoryId.name || "");
      setLoading(true);
      fetchSubCategoryList(id);
    }
  }, [location.state]);

  // Fetch subcategories whenever categoryId changes
  useEffect(() => {
    if (categoryId) {
      fetchSubCategoryList(categoryId);
    }
  }, [categoryId]);

  // Delete subCategory
  const handleDeleteCategory = async (subCategory_id) => {
    const formData = {
      sub_category_id: subCategory_id,
    };
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }

      const response = await axios.delete(ApiServices.removeSubCategory, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: formData,
        withCredentials: true,
      });

      const updatedSubCategory = subCategory.filter(
        (subCategory) => subCategory.sub_category_id !== subCategory_id
      );

      setSubCategory(updatedSubCategory);

      const { status, message } = response.data;
      if (status === 200) {
        toast.success(message);
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      console.error("Error deleting subCategory:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteConfirmation = (subCategory_id) => {
    setConfirmationLoading(true);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      setConfirmationLoading(false);
      if (result.isConfirmed) {
        handleDeleteCategory(subCategory_id);
      }
    });
  };

  // Filter data by name
  const handleFilter = (e) => {
    setFilterText(e.target.value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const columns = [
    {
      name: "Actions",
      width: "300px",
      cell: (row) => (
        <div className="flex justify-start">
          <Link
            to="/admin/products/category/list"
            state={{ id: row.sub_category_id }}
            className="mx-4 my-2 group"
          >
            <Eye className="w-5 h-5 text-dash mr-3" />
            <div className="absolute ml-4 top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
              product category
            </div>
          </Link>

          <Link
            to="/admin/subcategory/update"
            state={{ subCategory: row }}
            className="mx-2 my-2 group"
          >
            <PencilSquare className="w-5 h-5 text-dash4 mr-3" />
            <div className="absolute ml-4 top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
              Edit Subcategory
            </div>
          </Link>

          <Link
            className="mx-2 my-2 group"
            onClick={() => handleDeleteConfirmation(row.sub_category_id)}
            style={{ marginLeft: "5px" }}
          // disabled={confirmationLoading}
          >
            <Trash className="w-5 h-5 text-dash3 mr-3" />
            <div className="absolute ml-4 top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
              Subcategory
            </div>
          </Link>
        </div>
      ),
    },

    {
      name: "Name",
      selector: (row) => row.sub_category_name.toUpperCase(),
      sortable: true,
      width: "200px",
    },
    {
      name: "Status",
      selector: (row) =>
        row.status ? row.status.toUpperCase() : "NOT AVAILABLE",
      sortable: true,
      cell: (row) => (
        <span className={row.status === "active" ? "text-dash" : "text-dash3"}>
          {row.status ? row.status.toUpperCase() : "NOT AVAILABLE"}
        </span>
      ),
      width: "150px",
    },
    {
      name: "Category",
      selector: (row) => row.category_name,
      sortable: true,
      width: "200px",
    },
  ];

  const filteredItems = subCategory.filter((item) => {
    for (let key in item) {
      if (
        typeof item[key] === "string" &&
        item[key].toLowerCase().includes(filterText.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  });

  const data = filteredItems.map((subCategory) => ({
    sub_category_name: subCategory.sub_category_name
      ? subCategory.sub_category_name
      : "NOT AVAILABLE",
    status: subCategory.status ? subCategory.status : "NOT AVAILABLE",
    category_name: subCategory.category_name
      ? subCategory.category_name.toUpperCase()
      : "NOT AVAILABLE",
    sub_category_id: subCategory.sub_category_id,
    category_id: subCategory.category_id,
  }));

  // Sort data based on creation date
  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  const handleCreateSubcategory = () => {
    navigateTo("/admin/subcategory/create", {
      state: { categoryId, categoryName },
    });
  };

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          {/* <h1>Sub Category List</h1> */}
          <section
            className="w-full  pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Category
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Sub Category
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1">List</p>
                </li>
              </ol>
            </nav>
          </section>
          <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap justify-between">
            <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
              <input
                type="search"
                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search"
                value={filterText}
                onChange={handleFilter}
              />
            </div>
            {/* <Link
              //  to="/admin/subcategory/create"
              onClick={handleClick}
              to={{
                pathname: "/admin/subcategory/create",
                state: { categoryId: categoryId },
              }}
              className="m-1 w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <span className="flex justify-center sm:justify-start mx-2">
                <PlusSquare className="mr-2" />
                Create subcategory
              </span>
            </Link> */}
            <button
              onClick={handleCreateSubcategory}
              className="m-1 w-full sm:w-auto text-dGrey3 bg-orange1 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Create Subcategory
            </button>
            <Link
              to="/admin/category/list"
              state={{ id: categoryId }}
              className="m-1 w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <span className="flex justify-center sm:justify-start mx-2">
                <ArrowLeftSquare className="mr-2" />
                Back To Category
              </span>
            </Link>
          </div>
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <DataTable
              columns={columns}
              data={sortedData}
              pagination
              striped
              highlightOnHover
              noDataComponent={<div>No subCategory found</div>}
              customStyles={CustomStyles}
            />
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default SubCategoryList;
