import { Link } from "react-router-dom";
import MainLayouts from "../Layout/MainLayouts";
import NavBarLogo from "../assets/navbar-logo.png";

const PageNotFound = () => {
  return (
    <div>
      <MainLayouts>
        {/* <section className="text-gray-600 body-font mt-20"> */}
        <section className="text-gray-600 body-font ">
          <div className="container mx-auto flex flex-col px-5 py-24 justify-center items-center">
            <img
              className="mb-10 object-cover object-center rounded"
              alt="hero"
              src={NavBarLogo}
            />
            <h1 className="text-dash">
              An error occured on page request try again later
            </h1>
            <div className="w-full md:w-2/3 flex flex-col mb-16 items-center ">
              <p>500 -OOPS AND ERROR OCCUR </p>
              <Link
                to="/"
                type="submit"
                className="mt-10 text-dGrey3 bg-darkGreen1 hover:bg-blue-800 focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center w-2/3 my-1"
              >
                Sign In
              </Link>
            </div>
          </div>
        </section>
      </MainLayouts>
    </div>
  );
};

export default PageNotFound;
