import React, { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { handleError } from "../../components/utils/AuthenticationCheck";

const AddStore = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [telephone, setTelephone] = useState("");

  const [loading, setLoading] = useState(false);

  const navigateTo = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validateForm = () => {
      if (!name || !telephone || !description) {
        toast.error("All fields are required.");
        return false;
      }
      if (telephone.length !== 11) {
        toast.error("Phone number must be 11 digits long.");
        return false;
      }
      return true;
    };

    if (!validateForm()) return;

    setLoading(true);
    const formData = {
      name,
      telephone,
      description,
    };
    // console.log("this is my form data", formData);
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }

      const response = await axios.post(ApiServices.createStore, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      const { status, message } = response.data;
      // if (status === 200) {
      //   navigateTo("/admin/store/list");
      //   toast.success(message);
      // }
      //this sttaus should be 200 need to inform ketu also the phone number should be 11 digits
      if (status === 201) {
        navigateTo("/admin/store/list");
        toast.success(message);
        setName("");
        setDescription("");
        setTelephone("");
     } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };



  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <section
            className="w-full  pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Vendor Store
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1">Create</p>
                </li>
              </ol>
            </nav>
          </section>

          <section className="gray_background">
            <div className="container px-1 pb-2 mx-auto">
              <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col md:flex-row gap-6 mt-4">
                    <div className="flex-1">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Enter store name"
                      />
                    </div>
                    <div className="flex-1">
                      <label
                        htmlFor="telephone"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Phone Number
                      </label>
                      <input
                        type="text"
                        name="telephone"
                        id="telephone"
                        value={telephone}
                        onChange={(e) => setTelephone(e.target.value)}
                        className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Enter phone number"
                      />
                    </div>
                  </div>

                  <div className="mt-6">
                    <label
                      htmlFor="description"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Brief Description
                    </label>
                    <textarea
                      type="text"
                      name="description"
                      id="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Enter brief description here"
                      rows={6}
                    />
                  </div>

                  <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-6">
                    <Link
                      to="/admin/store/list"
                      className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
                    >
                      <List className="mr-2" />
                      Back To List
                    </Link>
                    <button
                      type="submit"
                      className={`text-white bg-darkGreen3 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium ${
                        loading ? "btn-loading" : "btn-primary"
                      }`}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Add Vendor Store"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </MainLayouts>
    </div>
  );
};

export default AddStore;
