
import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as Trash } from "bootstrap-icons/icons/trash3.svg";
import { ReactComponent as PlusSquareFill } from "bootstrap-icons/icons/plus-square-fill.svg";
import { ReactComponent as PencilSquare } from "bootstrap-icons/icons/pencil-square.svg";
import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import CustomStyles from "../../components/utils/CustomStyles";
import CurrencyFormatter from "../../components/utils/CurrencyFormatter";
import { handleError } from "../../components/utils/AuthenticationCheck";

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);

  const navigateTo = useNavigate();

  useEffect(() => {
    const fetchAllproductList = async () => {
      setLoading(true);
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) {
          throw new Error("No access token found.");
        }
        const response = await axios.get(ApiServices.getProducts, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        const { status, message, data } = response.data;
        if (status === 200) {
          setProducts(data.slice().reverse());
          toast.success(message, { toastId: "product-fetch-success" });
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setLoading(false);
      }
    };

    fetchAllproductList();
  }, []);

  // Handle Delete Confirmation
  const handleDeleteConfirmation = (productId) => {
    setConfirmationLoading(true);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      setConfirmationLoading(false);
      if (result.isConfirmed) {
        handleDeleteProduct(productId);
      }
    });
  };

  // Handle Product Deletion
  const handleDeleteProduct = async (productId) => {
    const formData = {
      product_id: productId,
    };
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }
      const response = await axios.delete(ApiServices.removeProducts, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: formData,
        withCredentials: true,
      });

      const updatedProduct = products.filter(
        (product) => product.product_id !== productId
      );
      setProducts(updatedProduct);

      const { status, message } = response.data;
      if (status === 200) {
        toast.success(message);
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };


  const handleFilter = (e) => {
    setFilterText(e.target.value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  // Define Expandable Component for Actions
  const ExpandableComponent = ({ data }) => (
    <div className="flex justify-start p-4 relative">
      <div className="mx-2 my-2 group relative">
        <Link
          to="/admin/product/details"
          state={{ product_id: data.product_id }}
        >
          <Eye className="w-5 h-5 text-darkGreen3 " />
        </Link>
        <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-1 hidden group-hover:block  text-darkGreen3 text-xs px-2 py-1 rounded">
          Details
        </div>
      </div>

      <div className="mx-2 my-2 group relative">
        <Link
          to="/admin/product/edit"
          state={{ productByRow: data }}
        >
          <PencilSquare className="w-5 h-5 text-dash4 " />
        </Link>
        <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-1 hidden group-hover:block text-dash4 text-xs px-2 py-1 rounded">
          Edit
        </div>
      </div>

      <div className="mx-2 my-2 group relative">
        <Link
          to="/admin/addproduct/images"
          state={{ productByRow: data }}
        >
          <PlusSquareFill className="w-5 h-5 text-orange1 " />
        </Link>
        <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-1 hidden group-hover:block text-orange1 text-xs px-2 py-1 rounded">
          Add Images
        </div>
      </div>

      <div className="mx-2 my-2 group relative">
        <button
          onClick={() => handleDeleteConfirmation(data.product_id)}
          disabled={confirmationLoading}
        >
          <Trash className="w-5 h-5 text-dash3 " />
        </button>
        <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-1 hidden group-hover:block text-dash3 text-xs px-2 py-1 rounded">
          Delete
        </div>
      </div>
    </div>
  );


  const columns = [
    {
      name: "Product Name",
      selector: (row) => (row.name ? row.name.toUpperCase() : ""),
      sortable: true,
      width: "300px",
    },
    {
      name: "Sold",
      selector: (row) => row.sold_quantity,
      sortable: true,
      width: "100px",
    },
    {
      name: "Status",
      selector: (row) =>
        row.status ? row.status.toUpperCase() : "NOT AVAILABLE",
      sortable: true,
      cell: (row) => (
        <span className={row.status === "active" ? "text-dash" : "text-dash3"}>
          {row.status ? row.status.toUpperCase() : "NOT AVAILABLE"}
        </span>
      ),
      width: "100px",
    },
    {
      name: "Category",
      sortable: true,
      width: "250px",
      cell: (row) => (
        <div>
          <span className="block font-bold">
            {row.category_name
              ? row.category_name.toUpperCase()
              : "NOT AVAILABLE"}
          </span>
          <span className="px-3 py-0.5 rounded-md bg-orange1 text-lGrey2 text-xs">
            {row.sub_category_name
              ? row.sub_category_name.toLowerCase()
              : "NOT AVAILABLE"}
          </span>
        </div>
      ),
    },
    {
      name: "Brand",
      selector: (row) => row.product_category_name,
      sortable: true,
      width: "200px",
    },
  ];

  const filteredItems = products.filter((item) =>
    Object.keys(item).some((key) =>
      item[key]?.toString().toLowerCase().includes(filterText.toLowerCase())
    )
  );

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <section className="w-full pr-[3%] pt-3 my-3" style={{ backgroundColor: "#F7F7F7" }}>
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Vendors Product
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1">List</p>
                </li>
              </ol>
            </nav>
          </section>
          <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap justify-between">
            <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
              <input
                type="search"
                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search"
                value={filterText}
                onChange={handleFilter}
              />
            </div>
            {/* <Link
              to="/admin/addproduct"
              className="w-full sm:w-auto text-dGrey3 bg-orange1 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <span className="flex justify-center sm:justify-start mx-2">
                <PlusSquareFill className="mr-2" />
                Create Product
              </span>
            </Link> */}
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              highlightOnHover
              striped
              customStyles={CustomStyles}
              noDataComponent={<div>No Product found</div>}
              expandableRows
              expandableRowsComponent={ExpandableComponent}
            />
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default ProductList;

