const CurrencyFormatter = (amount) => {
  return new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
    currencyDisplay: "symbol",
    minimumFractionDigits: 2,
  }).format(amount);
};

export default CurrencyFormatter;
