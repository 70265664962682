import React, { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import axios from "axios";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { handleError } from "../../components/utils/AuthenticationCheck";
import Spinner from "../spinner/Spinner";

const AddSubCategory = () => {
  const [name, setName] = useState("");
  const [categoryId, setCategory_id] = useState("");
  const [category_name, setCategory_name] = useState("");
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const [categories, setCategories] = useState([]);

  const navigateTo = useNavigate();

  useEffect(() => {
    const stateCategoryId = location.state?.categoryId;

    // console.log("State from location:", location.state);
    if (stateCategoryId) {
      console.log("Category ID from state:", stateCategoryId);
      //set the category id here
      setCategory_id(stateCategoryId);
    }
  }, [location.state]);

  // Validation function
  const validateForm = () => {
    if (!name || !categoryId) {
      toast.error("All fields are required.");
      return false;
    }
    return true;
  };

  //get all categories
  useEffect(() => {
    const fetchCategoryList = async () => {
      setLoading(true);
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) {
          throw new Error("No access token found.");
        }
        // console.log("i am token",token);
        const response = await axios.get(ApiServices.getCategory, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        const { status, message, data } = response.data;
        // setCategories(data);
        if (status === 200) {
          setCategories(data);

          // Set category name based on category_id from state
          if (categoryId) {
            const CategoryNameFromState = data.find(
              (cat) => cat.category_id === categoryId
            );
            if (CategoryNameFromState) {
              setCategory_name(CategoryNameFromState.name);
              // console.log("this is cat name", CategoryNameFromState.name);
            }
          }
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setLoading(false);
      }
    };

    fetchCategoryList();
  }, [categoryId, navigateTo]);

  // handle submit button
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    const formData = {
      name,
      category_id :categoryId,
    };
    // console.log("this is my form data", formData);
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }

      const response = await axios.post(
        ApiServices.addSubCategory,
        formData,

        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const { status, message, data } = response.data;
      if (status === 200) {
        // navigateTo("/admin/categories");
        //wil chnage this later
        navigateTo("/admin/subcategory/create");
        setName("");
        setCategory_id("");
        toast.success(message, { toastId: "subcategory-created-successfuly" });
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  const handleGoBackToSubcategory = () => {
    setLoading(false); // Stop loading
    console.log("Navigating back with category ID:", categoryId);
    console.log("Location state passed to sub category:", location.state);

    navigateTo("/admin/subcategory/list", {
      state: { categoryId },
    });
  };

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <section
            className="w-full  pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    SubCategory
                  </p>
                  <ChevronRightIcon />
                </li>

                <li className="flex items-center">
                  <p className="text-orange1 ">Create</p>
                </li>
              </ol>
            </nav>
          </section>

          {/* form here */}
          <section className="body-font">
            <div className="container px-5 pb-2 mx-auto">
              <div className="lg:w-full md:w-full bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
                {loading ? (
                  <Spinner loading={loading} />
                ) : (
                  <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-6">
                      <input
                        type="hidden"
                        name="category_id"
                        value={categoryId}
                      />

                      <div className="w-full">
                        <label
                          htmlFor="category"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Category Name
                        </label>
                        <input
                          type="text"
                          id="category_name"
                          defaultValue={category_name || ""}
                          readOnly
                          // onChange={handleCategoryChange}
                          className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        />
                      </div>
                      <div className="grid grid-cols-1 gap-6">
                        <div className="w-full">
                          <label
                            htmlFor="name"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            placeholder="Enter  name"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
                      {/* <Link
                        // to="/admin/category/list"
                        state={{ categoryId: categoryId }}
                        to="/admin/subcategory/list"
                        className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
                      >
                        <List className="mr-2" />
                        Back To List
                      </Link> */}
                      <button
                        onClick={handleGoBackToSubcategory}
                        className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
                      >
                        <List className="mr-2" />
                        Back to Subcategory
                      </button>

                      <button
                        type="submit"
                        className={`text-white bg-darkGreen3 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium ${
                          loading ? "btn-loading" : "btn-primary"
                        }`}
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Add To  SubCategory"}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </section>
        </div>
      </MainLayouts>
    </div>
  );
};

export default AddSubCategory;
