import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as ArrowLeftSquare } from "bootstrap-icons/icons/arrow-left-square-fill.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";

const ReturnItemDetails = () => {
  const [returnItemDetails, setReturnItemDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigateTo = useNavigate();
  const location = useLocation();
  const id = location.state.id;
  console.log(id);

  //format date
  const formatDate = (dateString) => {
    // Check if dateString is null or undefined
    if (!dateString) {
      return "Not Available";
    }
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month}-${day}`;
  };

  // useEffect(() => {
  //   const fetchCustomerDetails = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await axios.post(
  //         ApiServices.getCustomers,
  //         { id },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             //  logintoken: accessToken,
  //           },
  //           withCredentials: true,
  //         }
  //       );

  //       console.log("customers details response:", response);
  //       // setCustomerDetails(response);
  //       const customerData = response.data.customer;
  //       console.log("Customer data:", customerData);
  //       // Filter the admin object based on the id
  //       const customer = customerData.find(
  //         (customer) => customer.id === parseInt(id)
  //       );
  //       //   console.log("this is customer data with id", customer);
  //       //    console.log("id", id);
  //       // Check if agent is not undefined before setting state
  //       if (customer) {
  //         //  setName(customer.name);
  //         //  setPhone(customer.phone);
  //         //  setState(customer.state);
  //         //  setEmail(customer.email);
  //         setCustomerDetails(customer);
  //       } else {
  //         console.error("customer id not found:", id);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching customer:", error.message);
  //       toast.error("Error fetching customer");
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchCustomerDetails();
  // }, [id]);
  //  }, [id, accessToken]);

  useEffect(() => {
    const fetchProductDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `http://localhost:3000/products/${id}`
        );
        console.log(response);
        setReturnItemDetails(response.data);
      } catch (error) {
        console.error("Error fetching customer details:", error.message);
        // toast.error("Error fetching customer details");
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [id]);

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <h1 className="text-dash md:text-lg">Returned Order Details</h1>

          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <section className="text-gray-600 body-font">
              <div className="container">
                <div className="flex justify-end mb-3">
                  <Link
                    to="/admin/products"
                    className="w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    <span className="flex justify-center sm:justify-start mx-2">
                      <ArrowLeftSquare className="mr-2" />
                      Back
                    </span>
                  </Link>
                </div>
                <div className="flex flex-wrap -m-2">
                  {/* <div className="p-2 w-full"> */}
                  <div className="p-2 w-full lg:w-2/5">
                    <div className="h-full flex   border-grey3 border rounded-lg">
                      {/* <img
                        alt="team"
                        className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0  mr-4 hidden sm:block"
                        src={
                          returnItemDetails.image
                            ? returnItemDetails.image
                            : "Not Available"
                        }
                      />
                      <div className="flex-grow hidden sm:block">
                        <h2 className="text-dash title-font font-medium">
                          {returnItemDetails.code
                            ? returnItemDetails.code
                            : "Not Available"}
                        </h2>
                        <p className="text-gray-500">
                          {returnItemDetails.status
                            ? returnItemDetails.status.toUpperCase()
                            : "Not Available"}
                        </p>
                      </div> */}
                      <img
                        alt="ecommerce"
                        className="w-full lg:h-auto h-64  m-2 rounded"
                        src={
                          returnItemDetails.image
                            ? returnItemDetails.image
                            : "Not Available"
                        }
                      />
                    </div>
                  </div>
                  {/* <div className="px-2  w-full"> */}
                  <div className="px-2 w-full lg:w-3/5">
                    <div className=" flex items-center border-dash border p-1 rounded-lg border-l-4">
                      <section className="body-font overflow-hidden">
                        <div className="container px-5 py-3">
                          <div className="lg:w-5/5  flex flex-wrap ">
                            <div className=" w-full lg:pr-1 lg:py-6 mb-6 lg:mb-0">
                              <h2 className="text-sm title-font">
                                {returnItemDetails.name
                                  ? returnItemDetails.name.toUpperCase()
                                  : "Not Available"}
                              </h2>
                              <h1 className="text-dash text-3xl title-font font-medium mb-1">
                                {returnItemDetails.category
                                  ? returnItemDetails.category.toUpperCase()
                                  : "Not Available"}
                              </h1>
                              <span class="bg-dash text-dGrey3 text-xs font-medium me-2 px-3 py-1 rounded-full dark:bg-blue-900 dark:text-blue-300">
                                {returnItemDetails.status
                                  ? returnItemDetails.status.toUpperCase()
                                  : "Not Available"}
                              </span>
                              <p className="leading-relaxed mb-4">
                                {returnItemDetails.description
                                  ? returnItemDetails.description
                                  : "Not Available"}
                              </p>
                              <div className="flex border-t border-gray-200 py-2">
                                <span className="text-gray-500">Code </span>
                                <span className="ml-auto text-dash">
                                  {returnItemDetails.code
                                    ? returnItemDetails.code.toUpperCase()
                                    : "Not Available"}
                                </span>
                              </div>
                              <div className="flex border-t border-gray-200 py-2">
                                <span className="text-gray-500">Brand</span>
                                <span className="ml-auto text-dash">
                                  {returnItemDetails.brand
                                    ? returnItemDetails.brand.toUpperCase()
                                    : "Not Available"}
                                </span>
                              </div>

                              <div className="flex border-t border-gray-200 py-2">
                                <span className="text-gray-500">Color</span>
                                <span className="ml-auto text-dash">
                                  {returnItemDetails.color
                                    ? returnItemDetails.color.toUpperCase()
                                    : "Not Available"}
                                </span>
                              </div>
                              <div className="flex border-t border-gray-200 py-2">
                                <span className="text-gray-500">Size</span>
                                <span className="ml-auto text-dash">
                                  {returnItemDetails.size
                                    ? returnItemDetails.size.toUpperCase()
                                    : "Not Available"}
                                </span>
                              </div>
                              <div className="flex border-t border-b mb-6 border-gray-200 py-2">
                                <span className="text-gray-900">Quantity</span>
                                <span className="ml-auto  text-dash">
                                  {returnItemDetails.quantity
                                    ? returnItemDetails.quantity
                                    : "Not Available"}
                                </span>
                              </div>

                              <div className="flex ">
                                <p className="text-dash title-font font-medium text-2xl">
                                  &#8358;
                                </p>
                                <span className="title-font font-medium text-2xl">
                                  {returnItemDetails.price
                                    ? returnItemDetails.price
                                    : "Not Available"}
                                </span>
                                {/* <div class="flex ml-auto text-white border-0 py-2 px-2 hidden sm:block"> */}
                                <div class="ml-auto text-white border-0 py-2 px-2 hidden sm:block">
                                  <span className="text-dash">
                                    Created By :&nbsp;
                                  </span>
                                  {returnItemDetails.createdBy
                                    ? returnItemDetails.createdBy.toUpperCase()
                                    : "Not Available"}
                                </div>
                              </div>
                              <div className="flex ">
                                <span
                                  className="title-font font-medium "
                                  style={{
                                    backgroundColor: "#1D9E34",
                                    padding: "7px",
                                    color: "white",
                                  }}
                                >
                                  Product Discount : &nbsp;
                                  {returnItemDetails.discount
                                    ? returnItemDetails.discount
                                    : "Not Available"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default ReturnItemDetails;
