import React, { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as ArrowLeftSquare } from "bootstrap-icons/icons/arrow-left-square-fill.svg";
import axios from "axios";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { handleError } from "../../components/utils/AuthenticationCheck";

const AddCategory = () => {
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [category_id, setCategory_id] = useState("");
  const [loading, setLoading] = useState(false);

  const navigateTo = useNavigate();

  // Validation function
  const validateForm = () => {
    if (!name || !status) {
      toast.error("All fields are required.");
      return false;
    }
    return true;
  };

  // handle submit button
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    const formData = {
      name,
      status,
    };
    // console.log("this is my form data", formData);
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }

      const response = await axios.post(
        ApiServices.addCategory,
        formData,

        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const { status, message, data } = response.data;
      if (status === 200) {
        toast.success(message, { toastId: "category-created-success" });
        navigateTo("/admin/category/list");
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
      setLoading(false);
    }
  };

  

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          {/* form here */}
          <section className=" body-font">
            <div className="container px-5 pb-2 mx-auto ">
              <section
                className="w-full  pr-[3%] pt-3 my-3"
                style={{ backgroundColor: "#F7F7F7" }}
              >
                <nav className="bg-gray-200 py-0 px-4 rounded-lg">
                  <ol className="list-none p-0 inline-flex mb-2">
                    <li className="flex items-center">
                      <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                        Category
                      </p>
                      <ChevronRightIcon />
                    </li>

                    <li className="flex items-center">
                      <p className="text-orange1 ">Create</p>
                    </li>
                  </ol>
                </nav>
              </section>
              <div className="lg:w-full md:w-full bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <div className="w-full">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Enter category name"
                      />
                    </div>

                    <div className="w-full">
                      <label
                        htmlFor="status"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Status
                      </label>
                      <select
                        id="status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      >
                        <option value="" disabled>
                          Select status
                        </option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
                    <Link
                      to="/admin/category/list"
                      className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
                    >
                      <List className="mr-2" />
                      Back To List
                    </Link>
                    <button
                      type="submit"
                      className={`text-white bg-darkGreen3 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium ${
                        loading ? "btn-loading" : "btn-primary"
                      }`}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Add To Category"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </MainLayouts>
    </div>
  );
};

export default AddCategory;
