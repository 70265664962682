import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as ArrowLeftSquare } from "bootstrap-icons/icons/arrow-left-square-fill.svg";
import Spinner from "../spinner/Spinner";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import DefaultAvatar from "../../components/utils/DefaultAvatar";
import { handleError } from "../../components/utils/AuthenticationCheck";

const AdminDetails = () => {
  const [adminDetails, setAdminDetails] = useState({});
  const [loading, setLoading] = useState(true);
  // const [currentPage, setCurrentPage] = useState(1);
  // const itemsPerPage = 1;

  const navigateTo = useNavigate();
  const location = useLocation();
  const admin = location.state?.admin;
  // console.log("admin id", admin);

  useEffect(() => {
    const fetchAdminDetails = async () => {
      setLoading(true);
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) {
          throw new Error("No access token found.");
        }

        const response = await axios.get(ApiServices.getAdminById, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          params: { admin_id: admin.admin_id },
          withCredentials: true,
        });

        const { status, message, data } = response.data;

        if (status === 200) {
          // console.log("this is is the admin data from state", data);
          setAdminDetails(data || {});
          toast.success(message, { toastId: "admin-details-fetch-success" });
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setLoading(false);
      }
    };

    fetchAdminDetails();
  }, [admin, navigateTo]);

 

  // // Image Pagination Logic
  // const indexOfLastItem = currentPage * itemsPerPage;
  // const currentItems = adminDetails.images
  //   ? adminDetails.images.slice(indexOfLastItem - itemsPerPage, indexOfLastItem)
  //   : [];

  // const hasNextPage = indexOfLastItem < (adminDetails.images?.length || 0);
  // const hasPrevPage = currentPage > 1;

  // const handleNextPage = () => {
  //   if (hasNextPage) {
  //     setCurrentPage(currentPage + 1);
  //   }
  // };

  // const handlePrevPage = () => {
  //   if (hasPrevPage) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // };

  return (
    <MainLayouts>
      <div className="relative sm:rounded-lg py-6 w-full">
        <section
          className="w-full pr-[3%] pt-3 my-3"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          <nav className="bg-gray-200 py-0 px-4 rounded-lg">
            <ol className="list-none p-0 inline-flex mb-2">
              <li className="flex items-center">
                <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                  Admin
                </p>
                <ChevronRightIcon />
              </li>
              <li className="flex items-center">
                <p className="text-orange1">Details</p>
              </li>
            </ol>
          </nav>
        </section>

        {loading ? (
          <Spinner loading={loading} />
        ) : (
          <section className="text-gray-600 body-font">
            <div className="container">
              <div className="flex justify-end mb-3">
                <Link
                  to="/admin/view/list"
                  className="w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  <span className="flex justify-center sm:justify-start mx-2">
                    <ArrowLeftSquare className="mr-2" />
                    Back
                  </span>
                </Link>
              </div>

              <div className="flex flex-wrap -m-2">
                <div className="p-2 w-full lg:w-1/5">
                  <div className="flex items-center border-dash p-1 border rounded-lg border-l-4">
                    <div className="relative sm:rounded-lg">
                      {adminDetails.profile_photo ? (
                        <img
                          src={adminDetails.profile_photo}
                          alt={adminDetails.fullname}
                          className="w-10 h-10 rounded-full object-cover"
                        />
                      ) : (
                        <DefaultAvatar className="text-dash text-center w-5" />
                      )}
                    </div>
                  </div>
                </div>

                <div className="px-1 w-full lg:w-4/5 mt-2 mb-4">
                  <div className="flex items-center border-dash border p-1 rounded-lg border-l-4">
                    <section className="body-font w-full">
                      <div className="container px-2">
                        <div className="w-full flex flex-wrap">
                          <div className="w-full px-4">
                            <h2 className="text-sm title-font mt-2">
                              {adminDetails.fullname
                                ? adminDetails.fullname.toUpperCase()
                                : "Not Available"}
                            </h2>
                            <h1 className="hidden md:block text-dash text-3xl title-font font-medium mb-1">
                              {adminDetails.email
                                ? adminDetails.email.toUpperCase()
                                : "Not Available"}
                            </h1>

                            <span className="bg-dash text-dGrey3 text-xs font-medium me-2 px-3 py-1 rounded-full dark:bg-blue-900 dark:text-blue-300">
                              {adminDetails.status
                                ? adminDetails.status.toUpperCase()
                                : "Not Available"}
                            </span>
                            <p className="leading-relaxed mb-4 mt-3">
                              {adminDetails.phone_number
                                ? adminDetails.phone_number
                                : "Not Available"}
                            </p>
                            <div className="sm:hidden flex border-t border-gray-200 py-2">
                              <span className="text-gray-500">Email</span>
                              <span className="ml-auto text-dash">
                                {adminDetails.email
                                  ? adminDetails.email
                                  : "Not Available"}
                              </span>
                            </div>

                            <div className="flex border-t border-gray-200 py-2">
                              <span className="text-gray-500">Role</span>
                              <span className="ml-auto text-dash">
                                {adminDetails.role
                                  ? adminDetails.role.toUpperCase()
                                  : "Not Available"}
                              </span>
                            </div>
                            <div className="flex border-t border-gray-200 py-2">
                              <span className="text-gray-500 hidden md:block">
                                Two Factor Authentication
                              </span>
                              <span className="text-gray-500 sm:hidden">
                                2FA
                              </span>
                              <span
                                className={`text-dGrey3 ml-auto text-xs font-medium me-2 px-3 py-1 rounded-full ${
                                  adminDetails.two_factor_enabled === 1
                                    ? "bg-dash"
                                    : adminDetails.two_factor_enabled === 0
                                    ? "bg-dash3"
                                    : "bg-gray-300"
                                } dark:bg-blue-900 dark:text-blue-300`}
                              >
                                {adminDetails.two_factor_enabled === 1
                                  ? "2fa Active"
                                  : adminDetails.two_factor_enabled === 0
                                  ? "2fa Not Active"
                                  : "Not Available"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </MainLayouts>
  );
};

export default AdminDetails;
