import React, { useState, useEffect } from "react";
import MainLayouts from "../../../Layout/MainLayouts";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import Spinner from "../../spinner/Spinner";
import { handleError } from "../../../components/utils/AuthenticationCheck";
import axios from "axios";
import ApiServices from "../../../api/ApiServices";
import DataTable from "react-data-table-component";
import CustomStyles from "../../../components/utils/CustomStyles";
import { toast } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";

const Switcher = () => {
    const [products, setProducts] = useState([]);
    const [productsListSelection, setProductsSelection] = useState([]);
    const [productsListSelectionId, setProductsSelectionId] = useState("");
    const [switches, setSwitches] = useState([]);
    const [product_name, setProductName] = useState("");
    const [selectedBiller, setSelectedBiller] = useState({});
    const [loading, setLoading] = useState(true);
    const [filterText, setFilterText] = useState("");
    const [switchLoading, setSwitchLoading] = useState({});
    const [switchError, setSwitchError] = useState(null);
    const [switchSuccess, setSwitchSuccess] = useState(null);
    const [isProductLoading, setIsProductLoading] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState("");//product id
    const [selectedProductName, setSelectedProductName] = useState("");//name
    const [selectedProductCategoryId, setSelectedProductCategoryId] = useState("");//category id
    const [selectedBillerId, setSelectedBillerId] = useState("");//biller id
    const navigateTo = useNavigate();


    // Fetch products and switches
    const fetchData = async () => {
        setLoading(true);
        try {
            const authData = JSON.parse(localStorage.getItem("auth"));
            const token = authData?.token?.split("|")[1];
            if (!token) throw new Error("No access token found.");

            const response = await axios.post(ApiServices.getSwitchers, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });
            // console.log("API Response:", response.data.data);
            const { status, switchers, switches, products } = response.data.data;
            if (response.status === 200) {
                setProducts(switchers);//getting product name here
                setSwitches(switches);//get switches name here
                setProductsSelection(products);//getting form product list here
                setProductsSelectionId(products.id); //getting the selected product id here for form submition later 
                setSelectedBillerId(products.biller_id)
                setSelectedProductCategoryId(products.category_id)
                setLoading(false);
            } else {
                handleError({ response: { status, message: data.message } }, navigateTo);
            }
        } catch (error) {
            handleError(error, navigateTo);
        } finally {
            setLoading(false);
        }
    };

    // Function to format permission text
    const formatText = (text) => {
        let formattedText = text.replace(/_/g, " ");
        formattedText = formattedText
            .toLowerCase()
            .replace(/\b\w/g, (char) => char.toUpperCase());
        return formattedText;
    };


    const handleProductChange = (e) => {
        const selectedOption = e.target.options[e.target.selectedIndex]; // Get selected option element
        const selectedProductValue = e.target.value; // Get product id
        const selectedProductNameValue = selectedOption.text; // Get product name
        const selectedCategoryId = selectedOption.dataset.categoryId; // Get category_id from data attribute
        const selectedBillerIdValue = selectedOption.dataset.billerId; // Get biller_id from data attribute

        setSelectedProduct(selectedProductValue);
        setSelectedProductName(selectedProductNameValue);
        setSelectedProductCategoryId(selectedCategoryId);
        setSelectedBillerId(selectedBillerIdValue);

        // console.log("Selected product ID: ", selectedProductValue);
        // console.log("Selected product name:", selectedProductNameValue);
        // console.log("Selected Category ID:", selectedCategoryId);
        // console.log("Selected Biller ID:", selectedBillerIdValue);

    };



    // Fetch products and switches on component mount
    useEffect(() => {
        fetchData();
    }, []);

    // Handle biller change in dropdown
    const handleBillerChange = (productId, newBillerId) => {
        setSelectedBiller((prev) => ({
            ...prev,
            [productId]: newBillerId,
        }));

    };

    // Highlighted change: Filter available switches based on the product's category_id
    const getSwitchesForCategory = (category_id) => {
        // console.log(`Category ID: ${category_id}`); // Log the category id
        const filteredSwitches = switches.filter((switchItem) => switchItem.category_id === category_id);
        // console.log(`Filtered Switches:`, filteredSwitches); // Log filtered switches
        return filteredSwitches;
    };

    // Handle switching the biller
    const handleSwitch = async (productId) => {
        const selectedBillerId = selectedBiller[productId];
        if (!selectedBillerId) {
            setSwitchError("Please select a biller.");
            return;
        }

        setSwitchLoading((prev) => ({ ...prev, [productId]: true }));
        setSwitchError(null);
        setSwitchSuccess(null);

        const formData = {
            id: productId,
            biller_id: selectedBillerId,
        };
        // console.log("This is the switch credentials", formData);
        try {
            const authData = JSON.parse(localStorage.getItem("auth"));
            const token = authData?.token?.split("|")[1];
            if (!token) throw new Error("No access token found.");

            const response = await axios.post(ApiServices.switch, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });
            const { status, message, data } = response.data;
            if (status === 200) {
                // setSwitchSuccess("Switch successful!");
                toast.success(message, { toastId: "switchs-successfully-switched" });
                await fetchData(); // Re-fetch data after switching
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            setSwitchError(error.message || "An error occurred while switching.");
        } finally {
            setSwitchLoading((prev) => ({ ...prev, [productId]: false }));
        }
    };

    //Validation function
    const validateForm = () => {
        if (!selectedProduct) {
            toast.error("Please select both product before submitting.");
            return;
        }

        return true;
    };
    // Handle submit to create new switcher
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        setLoading(true);

        // console.log("Before form data creation:");
        // console.log("Selected Product ID: ", selectedProduct);
        // console.log("Selected Product Name: ", selectedProductName);
        // console.log("Selected Product Category ID: ", selectedProductCategoryId);
        // console.log("Selected Biller ID: ", selectedBillerId);

        const formData = {
            product_id: selectedProduct,
            product_name: selectedProductName,
            product_category: selectedProductCategoryId,
            biller_id: selectedBillerId
        };

        // console.log("this is my form data", formData);
        try {
            const authData = JSON.parse(localStorage.getItem("auth"));
            const token = authData?.token;
            if (!token) {
                throw new Error("No access token found.");
            }

            const response = await axios.post(
                ApiServices.addSwitchers,
                formData,

                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );

            const { status, message, data } = response.data;
            if (status === 200) {
                toast.success(message, { toastId: "switchs-created-success" });
                setSelectedProductName("")
                await fetchData(); // Re-fetch data after successful submission
            } else {
                handleError({ response: { status, data: { message } } }, navigateTo);
            }
        } catch (error) {
            handleError(error, navigateTo);
            setLoading(false);
        }
    };

    // Filter products by name
    const handleFilter = (e) => {
        setFilterText(e.target.value);
    };

    // Columns definition
    const columns = [
        {
            name: "Product Name",
            selector: (row) => row.name ? formatText(row.name).toUpperCase() : "NOT AVAILABLE",
            sortable: false,
            width: "300px",
        },
        {
            name: "Current Biller",
            selector: (row) => row.biller ? formatText(row.biller).toUpperCase() : "NOT AVAILABLE",
            sortable: true,
            width: "300px",
        },
        {
            name: "Switch To Available Biller",
            cell: (row) => {
                //  Retrieve available switches based on category
                const availableSwitches = getSwitchesForCategory(row.category_id);
                return (
                    <div className="flex flex-col">
                        <select
                            className="mx-2 my-1 px-4 py-2 text-black border rounded-md bg-dGrey3"
                            value={selectedBiller[row.id] || row.biller_id}
                            onChange={(e) => handleBillerChange(row.id, e.target.value)}
                        >
                            {availableSwitches.length > 0 ? (
                                availableSwitches.map((switchItem) => (
                                    <option key={switchItem.billerId} value={switchItem.billerId}>
                                        {/* <option key={`${switchItem.billerId}-${switchItem.id}`} value={switchItem.billerId}></option> */}
                                        {switchItem.biller}
                                    </option>
                                ))
                            ) : (
                                <option value="">No available billers</option>
                            )}
                        </select>
                        <button
                            type="button"
                            className="text-dGrey3 bg-darkGreen3 border-0 py-2 px-4 my-1 focus:outline-none hover:bg-blue-600 rounded font-medium"
                            onClick={() => handleSwitch(row.id)}
                            disabled={switchLoading[row.id]}
                        >
                            {switchLoading[row.id] ? "Switching..." : "Switch"}
                        </button>
                    </div>
                );
            },
            width: "400px",
        }
    ];

    // Filtered and sorted data
    const filteredItems = products.filter((item) =>
        Object.values(item).some((value) =>
            typeof value === "string" && value.toLowerCase().includes(filterText.toLowerCase())
        )
    );

    const data = filteredItems.map((product) => ({
        name: product.name || "NOT AVAILABLE",
        biller: product.biller || "NOT AVAILABLE",
        biller_id: product.biller_id,
        id: product.id,
        category_id: product.category_id,
    }));

    const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));


    return (
        <MainLayouts>
            <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
                <section className="w-full pr-[3%] pt-3 my-3" style={{ backgroundColor: "#F7F7F7" }}>
                    <nav className="bg-gray-200 py-0 px-4 rounded-lg">
                        <ol className="list-none p-0 inline-flex mb-2">
                            <li className="flex items-center">
                                <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">Switchers</p>
                                <ChevronRightIcon />
                            </li>
                            <li className="flex items-center">
                                <p className="text-orange1">All</p>
                            </li>
                        </ol>
                    </nav>
                </section>
                <section className="gray_background mb-2">
                    <div className="container px-1 pb-2 mx-auto">
                        <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
                            <form
                                onSubmit={handleSubmit}
                                className="grid grid-cols-1 sm:grid-cols-3 gap-6 items-end"
                            >
                                <div className="w-full mb-1">
                                    <label
                                        htmlFor="product"
                                        className="block mb-1 text-sm font-medium"
                                    >
                                        Select a  Product
                                    </label>
                                    <select
                                        id="product_name"
                                        name="product_name"
                                        value={selectedProduct || ""}
                                        onChange={handleProductChange}
                                        className="border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                    >
                                        {productsListSelection.map((prod) => {
                                            // console.log("Product:", prod); 
                                            return (
                                                <option
                                                    key={prod.id}
                                                    value={prod.id}
                                                    data-category-id={prod.category_id}
                                                    data-biller-id={prod.biller_id}
                                                >
                                                    {prod.name}
                                                </option>
                                            );
                                        })}


                                    </select>


                                </div>

                                <div className="w-full mb-1">
                                    <button
                                        type="submit"
                                        className="w-full bg-darkGreen3 text-dGrey1 rounded-lg py-2"
                                    >
                                        Add To Switch
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
                <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap justify-between">
                    <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
                        <input
                            type="search"
                            className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                            placeholder="Search"
                            value={filterText}
                            onChange={handleFilter}
                        />
                    </div>

                </div>


                {loading ? (
                    <Spinner loading={loading} />
                ) : (
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        striped
                        responsive
                        highlightOnHover
                        noDataComponent={<div>No products found</div>}
                        customStyles={CustomStyles}
                    />
                )}
            </div>
        </MainLayouts>
    );
};

export default Switcher;
