import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as Trash } from "bootstrap-icons/icons/trash3.svg";
import { ReactComponent as PlusSquare } from "bootstrap-icons/icons/plus-square.svg";
import { ReactComponent as PencilSquare } from "bootstrap-icons/icons/pencil-square.svg";
import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import CustomStyles from "../../components/utils/CustomStyles";
import CurrencyFormatter from "../../components/utils/CurrencyFormatter";

const ListOfFaq = () => {
  const [faq, setFaq] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);

  const navigateTo = useNavigate();

  //format date
  // const formatDate = (dateString) => {
  //   // Check if dateString is null or undefined
  //   if (!dateString) {
  //     return "Not Available";
  //   }
  //   const date = new Date(dateString);
  //   const year = date.getFullYear();
  //   const month = date.getMonth() + 1;
  //   const day = date.getDate();
  //   return `${year}-${month}-${day}`;
  // };

  useEffect(() => {
    const fetchfaqList = async () => {
      setLoading(true);
      try {
        // const authData = JSON.parse(localStorage.getItem("auth"));
        // const accessToken = authData.accessToken;
        const response = await axios.get(
          ApiServices.faqs,
          {},
          {
            headers: {
              // logintoken: accessToken,
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        console.log(response);
        setFaq(response.data);
        if (response.data.status === "300") {
          toast.error(response.data.message);
          return;
        }
        if (response.data.status === "302") {
          localStorage.removeItem("auth");
          toast.error(response.data.message);
          window.location.href = "/";
        }
        if (response.data.status === "400") {
          toast.error(response.data.message);
          navigateTo("/product/list");
        }
      } catch (error) {
        console.error("Error fetching products list:", error.message);
        // toast.error("Error fetching peoduct list.");
      } finally {
        setLoading(false);
      }
    };

    fetchfaqList();
  }, []);

  // ====================================delete product =================================================

  // Define toastMixin
  const toastMixin = Swal.mixin({
    toast: true,
    icon: "success",
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  // Define function to handle delete confirmation
  const handleDeleteConfirmation = (productId) => {
    setConfirmationLoading(true);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      setConfirmationLoading(false);
      if (result.isConfirmed) {
        handleDeleteProduct(productId);
      }
    });
  };

  // Define function to delete customer
  const handleDeleteProduct = async (faqId) => {
    try {
      // Send delete request to your API
      // await axios.delete(`${ApiServices.deleteCustomer}/${customerId}`);
      await axios.delete(`http://localhost:3000/faq/${faqId}`);

      // Update the customer list after deletion
      const updatedFaq = faq.filter((product) => product.id !== faqId);
      setFaq(updatedFaq);
      setLoading(false);
      toastMixin.fire({
        title: "Faq has been deleted",
      });
    } catch (error) {
      console.error("Error deleting product:", error.message);
      Swal.fire("Error!", "Failed to delete product.", "error");
      setLoading(false);
    }
  };
  // ==================================== end of delete product =================================================

  //filter data by name
  const handleFilter = (e) => {
    setFilterText(e.target.value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const columns = [
    // {
    //   name: "Date",
    //   selector: (row) => formatDate(row.created_at),
    //   sortable: true,
    // },

    {
      name: "Question",
      selector: (row) => (row.faqQuestion ? row.faqQuestion.toUpperCase() : ""),
      sortable: true,
      width: "200px",
    },
    {
      name: "Answer",
      selector: (row) => (row.faqAnswer ? row.faqAnswer.toUpperCase() : ""),
      sortable: true,
      width: "200px",
    },

    {
      name: "More",
      cell: (row) => (
        <div className="d-flex justify-content-end ">
          <Link
            to="/admin/product/details"
            state={{ id: row.id }}
            className="btn btn-info btn-sm"
            onClick={(e) => {
              navigateTo("/product/details", {
                replace: true,
                state: { id: row.id },
              });
            }}
          >
            <Eye className="w-5 h-5 text-dash4 " />
          </Link>
        </div>
      ),
      width: "80px",
    },
    {
      name: "Edit",
      cell: (row) => (
        <div className="d-flex justify-content-end buttons">
          <Link
            to="/admin/product/edit"
            state={{ id: row.id }}
            onClick={(e) => {
              navigateTo("/product/edit", {
                replace: true,
                state: { id: row.id },
              });
            }}
          >
            <PencilSquare className="w-5 h-5 text-dash4 " />
          </Link>
        </div>
      ),
      width: "80px",
    },

    {
      name: "Delete",
      cell: (row) => (
        <div className="d-flex justify-content-end buttons">
          <Link
            // to="/product/delete"
            // state={{ id: row.id }}
            className="btn btn-info btn-sm"
            // onClick={(e) => {
            //   navigateTo("/product/delete", {
            //     replace: true,
            //     state: { id: row.id },
            //   });
            // }}
            onClick={(e) => handleDeleteConfirmation(row.id)}
            disabled={confirmationLoading}
          >
            <Trash className="w-5 h-5 text-dash3 " />
          </Link>
        </div>
      ),
      width: "80px",
    },
  ];

  const filteredItems = faq.filter((item) => {
    for (let key in item) {
      if (
        typeof item[key] === "string" &&
        item[key].toLowerCase().includes(filterText.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  });

  const data = filteredItems.map((faq) => ({
    // created_at: formatDate(products.created_at),

    // createdBy: products.createdBy ? products.createdBy.toUpperCase() : "",

    faqQuestion: faq.faqQuestion ? faq.faqQuestion.toUpperCase() : "",
    faqAnswer: faq.faqAnswer ? faq.faqAnswer.toUpperCase() : "",
    id: faq.id,
  }));

  // Sort data based on creation date
  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <h1>FAQ List</h1>
          <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap justify-between">
            <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
              <input
                type="search"
                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search"
                value={filterText}
                onChange={handleFilter}
              />
            </div>
            <Link
              to="/admin/faq/create"
              className="w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <span className="flex justify-center sm:justify-start mx-2">
                <PlusSquare className="mr-2" />
                Create Faq
              </span>
            </Link>
          </div>
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <DataTable
              columns={columns}
              data={sortedData}
              pagination
                striped
              highlightOnHover
              noDataComponent={<div>No faq list found</div>}
              customStyles={CustomStyles}
            />
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default ListOfFaq;
