import React from "react";
import PuffLoader from "react-spinners/PuffLoader";

const Spinner = ({ loading }) => {
  const override = {
    display: "block",
    margin: "100px auto",
  };
  return (
    <PuffLoader
      color=" #00655f"
      loading={loading}
      cssOverride={override}
      size={50}
    />
  );
};

export default Spinner;
