
import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as PlusSquareFill } from "bootstrap-icons/icons/plus-square-fill.svg";
import { ReactComponent as PencilSquare } from "bootstrap-icons/icons/pencil-square.svg";
// import { ReactComponent as Trash } from "bootstrap-icons/icons/trash3.svg";
// import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
// import Swal from "sweetalert2";
import CustomStyles from "../../components/utils/CustomStyles";
import { handleError } from "../../components/utils/AuthenticationCheck";

const ProductPropertyList = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const navigateTo = useNavigate();

  useEffect(() => {
    const fetchAllProperties = async () => {
      setLoading(true);
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) {
          throw new Error("No access token found.");
        }

        const response = await axios.get(ApiServices.getProductProperty, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });


        const { status, message, data } = response.data;
        // console.log("these are all the product properties", response.data);
        if (status === 200) {
          setProperties(data);
          toast.success(message);
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setLoading(false);
      }
    };

    fetchAllProperties();
  }, []);

  const handleFilter = (e) => {
    setFilterText(e.target.value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  // const handleDeleteProduct = async (property_id) => {
  //   const authData = JSON.parse(localStorage.getItem("auth"));
  //   const token = authData?.token;
  //   if (!token) {
  //     throw new Error("No access token found.");
  //   }
  //   try {
  //     const response = await axios.delete(
  //       `${ApiServices.removeProperty}/${property_id}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //         withCredentials: true,
  //       }
  //     );

  //     const updatedProperties = properties.filter(
  //       (property) => property.property_id !== property_id
  //     );
  //     setProperties(updatedProperties);

  //     const { status, message } = response.data;
  //     if (status === 200) {
  //       toast.success(message);
  //     } else {
  //       handleError({ response: { status, data: { message } } }, navigateTo);
  //     }
  //   } catch (error) {
  //     handleError(error, navigateTo);
  //   }
  // };

  // const handleDeleteConfirmation = (property_id) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#d33",
  //     cancelButtonColor: "#3085d6",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       handleDeleteProduct(property_id);
  //     }
  //   });
  // };

  // const goToDetailsPage = (row) => {
  //   console.log("na ame be this ", properties);
  //   navigateTo("/admin/product/property/details", { state: { properties: row } });
  // };

  // Define Expandable Component for Actions
  const ExpandableComponent = ({ data }) => (
    <div className="flex justify-content-start">
      {/* <Link
            to="/admin/product/property/details"
            state={{ properties: data}}
            className="mx-2 my-2 group"
          >
            <Eye className="w-5 h-5 text-darkGreen3" />
          </Link> */}
      {/* <button
            // to="/admin/product/property/details"
            // state={{ properties: data }}
            onClick={() => goToDetailsPage(row)}
            className="mx-2 my-2 group"
          >
            <Eye className="w-5 h-5 text-darkGreen3" />
          </button> */}
      <Link
        to="/admin/product/property/update"
        state={{ properties: data }}
        className="mx-2 my-2 group"
      >
        <PencilSquare className="w-5 h-5 text-dash4" />
      </Link>
      {/* <Link
            className="mx-2 my-2 group"
            onClick={() => handleDeleteConfirmation(data.property_id)}
          >
            <Trash className="w-5 h-5 text-dash3" />
          </Link> */}
    </div>
  );

  const columns = [
    // {
    //   name: "Actions",
    //   cell: (row) => (
    //     <div className="flex justify-content-start">
    //       {/* <Link
    //         to="/admin/product/property/details"
    //         state={{ properties: row }}
    //         className="mx-2 my-2 group"
    //       >
    //         <Eye className="w-5 h-5 text-darkGreen3" />
    //       </Link> */}
    //       {/* <button
    //         // to="/admin/product/property/details"
    //         // state={{ properties: row }}
    //         onClick={() => goToDetailsPage(row)}
    //         className="mx-2 my-2 group"
    //       >
    //         <Eye className="w-5 h-5 text-darkGreen3" />
    //       </button> */}
    //       <Link
    //         to="/admin/product/property/update"
    //         state={{ properties: row }}
    //         className="mx-2 my-2 group"
    //       >
    //         <PencilSquare className="w-5 h-5 text-dash4" />
    //       </Link>
    //       {/* <Link
    //         className="mx-2 my-2 group"
    //         onClick={() => handleDeleteConfirmation(row.property_id)}
    //       >
    //         <Trash className="w-5 h-5 text-dash3" />
    //       </Link> */}
    //     </div>
    //   ),
    //   width: "80px",
    // },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "200px",
    },

    {
      name: "Unit",
      selector: (row) => row.unit,
      sortable: true,
      width: "100px",
    },
    {
      name: "Options",
      selector: (row) => row.options.join(", "),
      sortable: false,
      width: "200px",
    },
    {
      name: "Description",
      selector: (row) => row.description || "NOT AVAILABLE",
      sortable: true,
      width: "350px",
    },
  ];


  const filteredItems = properties.filter((item) => {
    for (let key in item) {
      if (
        typeof item[key] === "string" &&
        item[key].toLowerCase().includes(filterText.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  });

  const data = filteredItems.map((properties) => ({
    name: properties.name ? properties.name : "NOT AVAILABLE",
    description: properties.description ? properties.description : "NOT AVAILABLE",
    unit: properties.unit ? properties.unit : "NOT AVAILABLE",
    options: properties.options ? properties.options : "NOT AVAILABLE",
    property_id: properties.property_id,

  }));

  // Sort data based on creation date
  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <section
            className="w-full pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Properties
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1">List</p>
                </li>
              </ol>
            </nav>
          </section>
          <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap justify-between">
            <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
              <input
                type="search"
                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search"
                value={filterText}
                onChange={handleFilter}
              />
            </div>

            <Link
              to="/admin/product/property/create"
              className="w-full sm:w-auto text-dGrey3 bg-orange1 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <span className="flex justify-center sm:justify-start mx-2">
                <PlusSquareFill className="mr-2" />
                Create Product Property
              </span>
            </Link>
          </div>
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            // <DataTable
            //   columns={columns}
            //   data={filteredItems}
            //   pagination
            //   striped
            //   responsive
            //   highlightOnHover
            //   noDataComponent={<div>No properties found</div>}
            //   customStyles={CustomStyles}
            //   style={{ width: "100%" }}
            // />


            <DataTable
              columns={columns}
              data={sortedData}
              pagination
              // fixedHeader
              // fixedHeaderScrollHeight="450px"
              highlightOnHover
              striped
              // subHeader
              // subHeaderAlign="right"
              customStyles={CustomStyles}
              noDataComponent={<div>No Product found</div>}
              expandableRows
              expandableRowsComponent={ExpandableComponent}
            />
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default ProductPropertyList;


