// import React, { useState, useEffect } from "react";
// import MainLayouts from "../../Layout/MainLayouts";
// import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";
// import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
// import axios from "axios";
// import ApiServices from "../../api/ApiServices";
// import { toast } from "react-toastify";
// import { Link, useNavigate, useLocation } from "react-router-dom";
// import { handleError } from "../../components/utils/AuthenticationCheck";
// import formatDate from "../../components/utils/DateFormatter";

// const EditFlashSales = () => {
//   const [flash_sale_id, setFlashSaleId] = useState("");
//   const [discount_percentage, setDiscountPercentage] = useState("");
//   const [start_date, setStartDate] = useState("");
//   // New state for start time
//   const [start_time, setStartTime] = useState("");
//   const [end_date, setEndDate] = useState("");
//   // New state for end time
//   const [end_time, setEndTime] = useState("");
//   const [loading, setLoading] = useState(false);

//   const [name, setName] = useState("");
//   const [sku, setSku] = useState("");

//   const navigateTo = useNavigate();
//   const location = useLocation();

//   const productStock = location.state?.productStock;
//   const flashSales = productStock?.flash_sale;

//   console.log("Received flash sales:", flashSales); // Log the received data

//     useEffect(() => {
//       if (productStock) {
//         setName(productStock.name || "");
//         setSku(productStock.sku || "");
//       }
//     }, [productStock]);

//   useEffect(() => {
//     if (flashSales) {
//       console.log("this is the flash sales data", flashSales);
//       setDiscountPercentage(flashSales.discount_percentage || "");
//       setFlashSaleId(flashSales.flash_sale_id || "");

//       // Set the start and end dates
//       const startDate = new Date(flashSales.start_date);
//       const endDate = new Date(flashSales.end_date);

//       // Format the date to 'YYYY-MM-DD'
//       setStartDate(startDate.toISOString().split("T")[0] || "");
//       setEndDate(endDate.toISOString().split("T")[0] || "");

//       // Set the time to 'HH:mm'
//       setStartTime(
//         startDate.toTimeString().split(" ")[0].substring(0, 5) || ""
//       ); // Get HH:mm
//       setEndTime(endDate.toTimeString().split(" ")[0].substring(0, 5) || ""); // Get HH:mm
//     }
//   }, [flashSales]);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Validate the form
//     const validateForm = () => {
//       if (
//         !flash_sale_id ||
//         !discount_percentage ||
//         !start_date ||
//         !end_date ||
//         !start_time ||
//         !end_time
//       ) {
//         toast.error("All fields are required.");
//         return false;
//       }
//       return true;
//     };

//     if (!validateForm()) return;

//     setLoading(true);

//     // Ensure that start_time and end_time are properly set and formatted
//     // Formatted as 'YYYY-MM-DD HH:mm'
//     const startDateTime = `${start_date} ${start_time}`;
//     const endDateTime = `${end_date} ${end_time}`;

//     //formatDate function
//     const formattedStartDate = formatDate(startDateTime);
//     const formattedEndDate = formatDate(endDateTime);

//     const formData = {
//       flash_sale_id,
//       discount_percentage,
//       start_date: formattedStartDate,
//       end_date: formattedEndDate,
//     };

//     //  console.log("this is my form data", formData);
//     try {
//       const authData = JSON.parse(localStorage.getItem("auth"));
//       const token = authData?.token;
//       if (!token) {
//         throw new Error("No access token found.");
//       }

//       const response = await axios.put(ApiServices.updateFlashsales, formData, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//         },
//         withCredentials: true,
//       });

//       const { status, message } = response.data;
//       if (status === 200) {
//         navigateTo("/admin/flash/sales/create");
//         toast.success(message);
//         setDiscountPercentage("");
//         setStartDate("");
//         setEndDate("");
//         setStartTime("");
//         setEndTime("");
//       } else {
//         handleError({ response: { status, data: { message } } }, navigateTo);
//       }
//     } catch (error) {
//       handleError(error, navigateTo);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div>
//       <MainLayouts>
//         <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
//           <section
//             className="w-full  pr-[3%] pt-3 my-3"
//             style={{ backgroundColor: "#F7F7F7" }}
//           >
//             <nav className="bg-gray-200 py-0 px-4 rounded-lg">
//               <ol className="list-none p-0 inline-flex mb-2">
//                 <li className="flex items-center">
//                   <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
//                     Flash Sales
//                   </p>
//                   <ChevronRightIcon />
//                 </li>
//                 <li className="flex items-center">
//                   <p className="text-orange1 ">Update</p>
//                 </li>
//               </ol>
//             </nav>
//           </section>

//           <section className="gray_background">
//             <div className="container px-1 pb-2 mx-auto">
//               <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
//                 <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
//                   <div className="w-full">
//                     Product Name:<h5 className="text-darkGreen3"> {name}</h5>
//                   </div>
//                   {/* <!-- ... --> */}
//                   <div className="w-full">
//                     Product sku : <h5 className="text-darkGreen3"> {sku}</h5>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </section>

//           <section className="gray_background">
//             <div className="container px-1 pb-2 mx-auto">
//               <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
//                 <form onSubmit={handleSubmit}>
//                   <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
//                     {/* <input type="hidden" name="stock_id" value={stock_id} /> */}
//                     <input
//                       type="hidden"
//                       name="flash_sale_id"
//                       value={flash_sale_id}
//                     />
//                     <div className="w-full">
//                       <label
//                         htmlFor="name"
//                         className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
//                       >
//                         Percentage Discount
//                       </label>
//                       <input
//                         type="number"
//                         name="discount_percentage"
//                         id="discount_percentage"
//                         value={discount_percentage}
//                         onChange={(e) => setDiscountPercentage(e.target.value)}
//                         className="border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5"
//                         placeholder="Enter percentage"
//                       />
//                     </div>
//                     <div className="w-full">
//                       <label
//                         htmlFor="name"
//                         className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
//                       >
//                         Start Date
//                       </label>
//                       <input
//                         type="date"
//                         name="start_date"
//                         id="start_date"
//                         value={start_date}
//                         onChange={(e) => setStartDate(e.target.value)}
//                         className="border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5  "
//                         placeholder="Pick a start date"
//                       />
//                     </div>
//                     <div className="w-full">
//                       <label
//                         htmlFor="name"
//                         className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
//                       >
//                         Start Time
//                       </label>
//                       <input
//                         type="time"
//                         name="start_time"
//                         id="start_time"
//                         value={start_time}
//                         onChange={(e) => setStartTime(e.target.value)}
//                         className="border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5 "
//                       />
//                     </div>
//                   </div>
//                   <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-2">
//                     <div className="w-full">
//                       <label
//                         htmlFor="name"
//                         className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
//                       >
//                         End Date
//                       </label>
//                       <input
//                         type="date"
//                         name="end_date"
//                         id="end_date"
//                         value={end_date}
//                         onChange={(e) => setEndDate(e.target.value)}
//                         className="border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5  "
//                         placeholder="Pick a start date"
//                       />
//                     </div>
//                     <div className="w-full">
//                       <label
//                         htmlFor="name"
//                         className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
//                       >
//                         End Time
//                       </label>
//                       <input
//                         type="time"
//                         name="end_time"
//                         id="end_time"
//                         value={end_time}
//                         onChange={(e) => setEndTime(e.target.value)}
//                         className="border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5 "
//                       />
//                     </div>
//                   </div>
//                   <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
//                     <Link
//                       to="/admin/products/stock/list"
//                       className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
//                     >
//                       <List className="mr-2" />
//                       Back To List
//                     </Link>
//                     <button
//                       type="submit"
//                       className={`text-white bg-darkGreen3 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium ${
//                         loading ? "btn-loading" : "btn-primary"
//                       }`}
//                       disabled={loading}
//                     >
//                       {loading ? "Loading..." : "Add Flash Sales"}
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </section>
//         </div>
//       </MainLayouts>
//     </div>
//   );
// };

// export default EditFlashSales;

import React, { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { handleError } from "../../components/utils/AuthenticationCheck";
import formatDate from "../../components/utils/DateFormatter";

const EditFlashSales = () => {
  const [flash_sale_id, setFlashSaleId] = useState("");
  const [discount_percentage, setDiscountPercentage] = useState("");
  const [start_date, setStartDate] = useState("");
  const [start_time, setStartTime] = useState("");
  const [end_date, setEndDate] = useState("");
  const [end_time, setEndTime] = useState("");
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [sku, setSku] = useState("");

  const navigateTo = useNavigate();
  const location = useLocation();

  const productStock = location.state?.productStock;
  const flashSales = productStock?.flash_sale;

  // console.log("Received flash sales:", flashSales);

  useEffect(() => {
    if (productStock) {
      setName(productStock.name || "");
      setSku(productStock.sku || "");
    }
  }, [productStock]);

  // useEffect(() => {
  //   if (flashSales) {
  //     setDiscountPercentage(flashSales.discount_percentage || "");
  //     setStartDate(flashSales.start_date || "");
  //     setEndDate(flashSales.end_date || "");
  //     setFlashSaleId(flashSales.flash_sale_id || "");
  //   }
  // }, [flashSales]);
  useEffect(() => {
    if (flashSales) {
      setDiscountPercentage(flashSales.discount_percentage || "");

      // Assume flashSales.start_date and flashSales.end_date are in "YYYY-MM-DD HH:MM:SS" format
      const [startDate, startTime] = flashSales.start_date
        ? flashSales.start_date.split(" ")
        : ["", ""];
      const [endDate, endTime] = flashSales.end_date
        ? flashSales.end_date.split(" ")
        : ["", ""];

      setStartDate(startDate || "");
      setStartTime(startTime || "");
      setEndDate(endDate || "");
      setEndTime(endTime || "");

      setFlashSaleId(flashSales.flash_sale_id || "");
    }
  }, [flashSales]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validateForm = () => {
      if (
        !flash_sale_id ||
        !discount_percentage ||
        !start_date ||
        !end_date ||
        !start_time ||
        !end_time
      ) {
        toast.error("All fields are required.");
        return false;
      }
      return true;
    };

    if (!validateForm()) return;

    setLoading(true);

    const startDateTime = `${start_date} ${start_time}`;
    const endDateTime = `${end_date} ${end_time}`;

    const formattedStartDate = formatDate(startDateTime);
    const formattedEndDate = formatDate(endDateTime);

    const formData = {
      flash_sale_id,
      discount_percentage,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
    };

    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }

      const response = await axios.put(ApiServices.updateFlashsales, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      const { status, message } = response.data;
      if (status === 200) {
        navigateTo("/admin/flash/sales/create");
        toast.success(message);
        setDiscountPercentage("");
        setStartDate("");
        setEndDate("");
        setStartTime("");
        setEndTime("");
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <section
            className="w-full  pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Flash Sales
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1 ">Update</p>
                </li>
              </ol>
            </nav>
          </section>

          <section className="gray_background">
            <div className="container px-1 pb-2 mx-auto">
              <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                  <div className="w-full">
                    Product Name:<h5 className="text-darkGreen3"> {name}</h5>
                  </div>
                  <div className="w-full">
                    Product SKU:<h5 className="text-darkGreen3"> {sku}</h5>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {flashSales ? (
            <section className="gray_background">
              <div className="container px-1 pb-2 mx-auto">
                <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
                  <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
                      <input
                        type="hidden"
                        name="flash_sale_id"
                        value={flash_sale_id}
                      />
                      <div className="w-full">
                        <label
                          htmlFor="discount_percentage"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Percentage Discount
                        </label>
                        <input
                          type="number"
                          name="discount_percentage"
                          id="discount_percentage"
                          value={discount_percentage}
                          onChange={(e) =>
                            setDiscountPercentage(e.target.value)
                          }
                          className="border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                          placeholder="Enter percentage"
                        />
                      </div>
                      <div className="w-full">
                        <label
                          htmlFor="start_date"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Start Date
                        </label>
                        <input
                          type="date"
                          name="start_date"
                          id="start_date"
                          value={start_date}
                          onChange={(e) => setStartDate(e.target.value)}
                          className="border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                        />
                      </div>
                      <div className="w-full">
                        <label
                          htmlFor="start_time"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Start Time
                        </label>
                        <input
                          type="time"
                          name="start_time"
                          id="start_time"
                          value={start_time}
                          onChange={(e) => setStartTime(e.target.value)}
                          className="border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-2">
                      <div className="w-full">
                        <label
                          htmlFor="end_date"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          End Date
                        </label>
                        <input
                          type="date"
                          name="end_date"
                          id="end_date"
                          value={end_date}
                          onChange={(e) => setEndDate(e.target.value)}
                          className="border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                        />
                      </div>
                      <div className="w-full">
                        <label
                          htmlFor="end_time"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          End Time
                        </label>
                        <input
                          type="time"
                          name="end_time"
                          id="end_time"
                          value={end_time}
                          onChange={(e) => setEndTime(e.target.value)}
                          className="border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
                      <Link
                        to="/admin/products/stock/list"
                        className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
                      >
                        <List className="mr-2" />
                        Back To List
                      </Link>
                      <button
                        type="submit"
                        className={`text-white bg-darkGreen3 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium ${
                          loading ? "btn-loading" : "btn-primary"
                        }`}
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Update Flash Sales"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          ) : (
            <section className="gray_background">
              <div className="container px-1 pb-2 mx-auto">
                <h5 className="text-red-500 text-center">
                  No Flash Sales Data Available
                </h5>
              </div>
              <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
                <Link
                  to="/admin/products/stock/list"
                  className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
                >
                  <List className="mr-2" />
                  Back To List
                </Link>
              </div>
            </section>
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default EditFlashSales;
