// // import React from "react";

// // const DateFormatter = ({ dateString }) => {
// //   const formatDate = (dateString) => {
// //     if (!dateString) {
// //       return "Not Available";
// //     }
// //     const date = new Date(dateString);
// //     const year = date.getFullYear();
// //     const month = date.getMonth() + 1;
// //     const day = date.getDate();
// //     return `${year}-${month}-${day}`;
// //   };

// //   return <>{formatDate(dateString)}</>;
// // };

// // export default DateFormatter;

// const formatDate = (dateString) => {
//   if (!dateString) {
//     return "Not Available";
//   }

//   const date = new Date(dateString);
//   if (isNaN(date.getTime())) {
//     return "No Downlines Yet";
//   }

//   // const date = new Date(dateString);
//   const year = date.getFullYear();
//   const month = String(date.getMonth() + 1).padStart(2, "0");
//   const day = String(date.getDate()).padStart(2, "0");
//   const hours = String(date.getHours() - 1).padStart(2, "0");
//   const minutes = String(date.getMinutes()).padStart(2, "0");
//   const seconds = String(date.getSeconds()).padStart(2, "0");
//   return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
// };

// export default formatDate;


// const formatDate = (dateString) => {
//   if (!dateString) {
//     return "Not Available";
//   }

//   const date = new Date(dateString);
//   if (isNaN(date.getTime())) {
//     return "No Downlines Yet";
//   }

//   // const date = new Date(dateString);
//   const year = date.getFullYear();
//   const month = String(date.getMonth() + 1).padStart(2, "0");
//   const day = String(date.getDate()).padStart(2, "0");
//   const hours = String(date.getHours() - 1).padStart(2, "0");
//   const minutes = String(date.getMinutes()).padStart(2, "0");
//   const seconds = String(date.getSeconds()).padStart(2, "0");
//   return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
// };

// export default formatDate;


const formatDate = (dateString) => {
  if (!dateString) {
    return "Not Available";
  }

  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return "No Downlines Yet";
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  // Return in 'd-m-Y H:i:s' format
  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
};

export default formatDate;
