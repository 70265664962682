
import React, { useState, useEffect } from "react";
import axios from "axios";
import ApiServices from "../../../api/ApiServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { handleError } from "../../../components/utils/AuthenticationCheck";

const CreateProductForm = ({ formData, handleChange, handleNext }) => {
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState({ categories: false, subCategories: false, products: false });
    const [errors, setErrors] = useState({});

    const navigateTo = useNavigate();

    useEffect(() => {
        const fetchCategories = async () => {
            setLoading((prev) => ({ ...prev, categories: true }));
            try {
                const authData = JSON.parse(localStorage.getItem("auth"));
                const token = authData?.token;
                const response = await axios.get(ApiServices.getCategory, {
                    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
                });
                const { status, data } = response.data;
                if (status === 200 && Array.isArray(data)) {
                    setCategories(data);
                } else {
                    handleError({ response: { status, data: { message: "Failed to fetch categories." } } }, navigateTo);
                }
            } catch (error) {
                handleError(error, navigateTo);
            } finally {
                setLoading((prev) => ({ ...prev, categories: false }));
            }
        };

        fetchCategories();
    }, [navigateTo]);

    useEffect(() => {
        const fetchSubCategories = async (categoryId) => {
            if (!categoryId) return;
            setLoading((prev) => ({ ...prev, subCategories: true }));
            try {
                const authData = JSON.parse(localStorage.getItem("auth"));
                const token = authData?.token;
                const response = await axios.get(ApiServices.getSubCategoryById, {
                    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
                    params: { category_id: categoryId },
                });
                const { status, data } = response.data;
                if (status === 200 && Array.isArray(data)) {
                    setSubCategories(data);
                } else {
                    handleError({ response: { status, data: { message: "Failed to fetch subcategories." } } }, navigateTo);
                }
            } catch (error) {
                handleError(error, navigateTo);
            } finally {
                setLoading((prev) => ({ ...prev, subCategories: false }));
            }
        };

        if (formData.category_id) {
            fetchSubCategories(formData.category_id);
        }
    }, [formData.category_id, navigateTo]);

    useEffect(() => {
        const fetchProducts = async (subCategoryId) => {
            if (!subCategoryId) return;
            setLoading((prev) => ({ ...prev, products: true }));
            try {
                const authData = JSON.parse(localStorage.getItem("auth"));
                const token = authData?.token;
                const response = await axios.get(ApiServices.getProductsBySubCategoryId, {
                    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
                    params: { sub_category_id: subCategoryId },
                });
                const { status, data } = response.data;
                if (status === 200) {
                    setProducts(data);
                } else {
                    handleError({ response: { status, data: { message: "Failed to fetch products." } } }, navigateTo);
                }
            } catch (error) {
                handleError(error, navigateTo);
            } finally {
                setLoading((prev) => ({ ...prev, products: false }));
            }
        };

        if (formData.sub_category_id) {
            fetchProducts(formData.sub_category_id);
        }
    }, [formData.sub_category_id, navigateTo]);

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = "Name is required.";
        if (!formData.description) newErrors.description = "Description is required.";
        if (!formData.category_id) newErrors.category_id = "Category is required.";
        if (!formData.sub_category_id) newErrors.sub_category_id = "Sub Category is required.";
        if (!formData.product_category_id) newErrors.product_category_id = "Product Category is required.";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        handleNext();
    };

    return (
        <form onSubmit={handleSubmit}>
            <h6 className="text-orange1">Create Product Form</h6>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div className="w-full">
                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        value={formData.name}
                        onChange={handleChange}
                        className={`bg-gray-50 border ${errors.name ? 'border-red-500' : 'border-dGrey1'} text-gray-900 text-sm rounded-lg block w-full p-2.5`}
                        placeholder="Enter product name"
                    />
                    {errors.name && <p className="text-red1 text-sm">{errors.name}</p>}
                </div>
                <div className="w-full">
                    <label htmlFor="category_id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Category</label>
                    <select
                        id="category_id"
                        name="category_id"
                        value={formData.category_id}
                        onChange={handleChange}
                        className={`bg-gray-50 border ${errors.category_id ? 'border-red-500' : 'border-dGrey1'} text-gray-900 text-sm rounded-lg block w-full p-2.5`}
                    >
                        <option value="" disabled>Select Category</option>
                        {categories.map((category) => (
                            <option key={category.category_id} value={category.category_id}>{category.name}</option>
                        ))}
                    </select>
                    {errors.category_id && <p className="text-red1 text-sm">{errors.category_id}</p>}
                </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-2">
                <div className="w-full">
                    <label htmlFor="sub_category_id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Sub Category</label>
                    <select
                        id="sub_category_id"
                        name="sub_category_id"
                        value={formData.sub_category_id}
                        onChange={handleChange}
                        disabled={!formData.category_id}
                        className={`bg-gray-50 border ${errors.sub_category_id ? 'border-red-500' : 'border-dGrey1'} text-gray-900 text-sm rounded-lg block w-full p-2.5`}
                    >
                        <option value="" disabled>{loading.subCategories ? "Loading..." : "Select Sub Category"}</option>
                        {subCategories.map((sub) => (
                            <option key={sub.sub_category_id} value={sub.sub_category_id}>{sub.sub_category_name}</option>
                        ))}
                    </select>
                    {errors.sub_category_id && <p className="text-red1 text-sm">{errors.sub_category_id}</p>}
                </div>
                <div className="w-full">
                    <label htmlFor="product_category_id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Product Category</label>
                    <select
                        id="product_category_id"
                        name="product_category_id"
                        value={formData.product_category_id}
                        onChange={handleChange}
                        className={`bg-gray-50 border ${errors.product_category_id ? 'border-red-500' : 'border-dGrey1'} text-gray-900 text-sm rounded-lg block w-full p-2.5`}
                    >
                        <option value="" disabled>{loading.products ? "Loading..." : "Select Product Category"}</option>
                        {products.map((prod) => (
                            <option key={prod.product_category_id} value={prod.product_category_id}>{prod.name}</option>
                        ))}
                    </select>
                    {errors.product_category_id && <p className="text-red1 text-sm">{errors.product_category_id}</p>}
                </div>
            </div>

            <div className="grid grid-cols-12 sm:grid-cols-12 gap-6 mt-4">
                <div className="col-span-12">
                    <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Brief Description</label>
                    <textarea
                        name="description"
                        id="description"
                        value={formData.description}
                        onChange={handleChange}
                        rows={6}
                        className={`bg-gray-50 border ${errors.description ? 'border-red-500' : 'border-dGrey1'} text-gray-900 text-sm rounded-lg block w-full p-2.5`}
                        placeholder="Enter brief description here"
                    />
                    {errors.description && <p className="text-red1 text-sm">{errors.description}</p>}
                </div>
            </div>

            <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
                <button type="submit" className="t my-2 text-dGrey3 bg-darkGreen3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium">
                    Next
                </button>
            </div>
        </form>
    );
};

export default CreateProductForm;









