import React, { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { useNavigate, useLocation,Link } from "react-router-dom";
import { toast } from "react-toastify";
import ApiServices from "../../api/ApiServices";
import axios from "axios";
import { handleError } from "../../components/utils/AuthenticationCheck";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";


const EditProductProperty = () => {
  const navigateTo = useNavigate();
  const location = useLocation();
  const { properties } = location.state || {};

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [unit, setUnit] = useState("");
  const [property_id, setProperty_id] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (properties) {
      setName(properties.name);
      setDescription(properties.description || ""); // description is optional
      setUnit(properties.unit);
      setProperty_id(properties.property_id);
      setOptions(properties.options || []); // options is an array
    }
  }, [properties]);

  const handleUpdate = async (e) => {
    e.preventDefault();

    // Validate the form
    if (!name || !unit || !options.length) {
      toast.error("Name, Unit, and at least one option are required.");
      return;
    }

    setLoading(true);

    const formData = {
      property_id: property_id, 
      name,
      description,
      options: options.map(option => option.trim()), 
      unit,
    };

    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }

      const response = await axios.put(
        ApiServices.updateProductProperty,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const { status, message } = response.data;
      if (status === 200) {
        navigateTo("/admin/product/property/list");
        toast.success(message);
        setDescription("");
        setName("");
        setUnit("");
        setOptions("");
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <section
            className="w-full  pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Product Property
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1">Update</p>
                </li>
              </ol>
            </nav>
          </section>

          <section className="gray_background">
            <div className="container px-1 pb-2 mx-auto">
              <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
                <form onSubmit={handleUpdate}>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <div className="w-full">
                      <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                        placeholder="Enter name"
                      />
                    </div>
                    <div className="w-full">
                      <label htmlFor="unit" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Unit
                      </label>
                      <input
                        type="text"
                        name="unit"
                        id="unit"
                        value={unit}
                        onChange={(e) => setUnit(e.target.value)}
                        className="border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                        placeholder="Enter unit"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-2">
                    <div className="w-full">
                      <label htmlFor="options" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Options
                        <br />
                        <small className="text-red1">Note: This is comma-separated (e.g., option1, option2)</small>
                      </label>
                      <input
                        type="text"
                        name="options"
                        id="options"
                        value={options.join(", ")} // Display options as a comma-separated string
                        onChange={(e) => setOptions(e.target.value.split(",").map(option => option.trim()))} // Update options as an array
                        className="border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                        placeholder="Enter options"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-2">
                    <div className="w-full">
                      <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Description
                      </label>
                      <textarea
                        name="description"
                        id="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        rows={4}
                        className="border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                      />
                    </div>
                  </div>

                  <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
                    <Link
                      to="/admin/product/property/list"
                      className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
                    >
                      <List className="mr-2" />
                      Back To List
                    </Link>
                    <button
                      type="submit"
                      className={`text-white bg-darkGreen3 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium ${loading ? "btn-loading" : "btn-primary"
                        }`}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Update Product Property "}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </MainLayouts>
    </div>
  );
};

export default EditProductProperty;
