import React, { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import SweetAlertToast from "../../components/SweetAlertToast";
import Swal from "sweetalert2";

const Faq = () => {
  const [faqQuestion, setFaqQuestion] = useState("");
  const [faqAnswer, setFaqAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const navigateTo = useNavigate();

  // Define toastMixin
  const toastMixin = Swal.mixin({
    toast: true,
    icon: "success",
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  // handle submit button
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // const authData = JSON.parse(localStorage.getItem("auth"));
      // const accessToken = authData.accessToken;

      // const response = await axios.post(ApiServices.addProducts, formData, {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //     // logintoken: accessToken,
      //   },
      //   withCredentials: true,
      // });
      const response = await axios.post(
        `http://localhost:3000/products`,
        // formData
        JSON.stringify({
          faqAnswer,
          faqQuestion,
        })
      );

      //  const authData = JSON.parse(localStorage.getItem("auth"));

      //  const accessToken = authData.accessToken;
      //  //console.log("Access token: " + accessToken);
      //  const response = await axios.post(
      //    ApiServices.addAdmin,
      //    JSON.stringify({ name, category,code ,subcategory,brand,price,quantity,discountType,status,image,description }),
      //    {
      //      headers: {
      //        "Content-Type": "application/json",
      //        logintoken: accessToken,
      //      },
      //      withCredentials: true,
      //    }
      //  );
      // console.log(formData);

      console.log("this is the form rewsponse", response.data);

      if (response.data.status === "100") {
        toast.error(
          <div className="text-danger">
            {response.data.required_fields.map((item, index) => (
              <React.Fragment key={index}>
                <br />
                <small>{item}</small>
              </React.Fragment>
            ))}
          </div>
        );
      } else if (response.data.status === "200") {
        toastMixin.fire({
          title: "Product has been added successfully",
        });
        navigateTo("/admin/products");

        if (response.data.status === "300") {
          toast.error(response.data.message);
          return;
        }
        if (response.data.status === "302") {
          localStorage.removeItem("auth");
          toast.error(response.data.message);
          //   toastMixin.fire({
          //   icon: "error",
          //   title: "Error!",
          //   text: "response.data.message",
          // });
          navigateTo("/");
        }
        if (response.data.status === "400") {
          toast.error(response.data.message);
          navigateTo("/admin/products");
        }
      }
    } catch (error) {
      console.error("Error adding product", error.message);
      // toast.error("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <div className="pb-4 bg-white dark:bg-gray-900 flex justify-end">
            <Link
              to="/admin/faq/list"
              className="text-dGrey3 py-3 bg-darkGreen3 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center w-/4 my-0"
            >
              <span className="flex mx-2">
                <List className="mr-2" />
                Back To List
              </span>
            </Link>
          </div>
          <section className="gray_background">
            <div className="container px-1 pb-2 mx-auto">
              <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">
                Create Frequently Ask Question(FAQ)
              </h2>
              <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
                <form onSubmit={handleSubmit}>
                  <div className="flex lg:w-3/3 w-full sm:flex-row flex-col mx-auto px-0 sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 items-end">
                    <div className="relative flex-grow w-full">
                      <label
                        htmlFor="code"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Faq question
                      </label>
                      <input
                        type="text"
                        name="faqQuestion"
                        id="code"
                        value={faqQuestion}
                        onChange={(e) => setFaqQuestion(e.target.value)}
                        className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Enter product code"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="description"
                      className="block mb-2 my-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      faq Answer
                    </label>
                    <textarea
                      id="description"
                      name="faqQuestion"
                      rows="5"
                      value={faqQuestion}
                      onChange={(e) => setFaqAnswer(e.target.value)}
                      className="h-20 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-dGrey1 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Briefly describe Product here"
                    ></textarea>
                  </div>
                  <div className="flex justify-end">
                    <button
                      type="submit"
                      className={`text-white bg-darkGreen3 mt-4 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg w-40 ${
                        loading ? "btn-loading" : "btn-primary"
                      }`}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Add FAQ"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </MainLayouts>
    </div>
  );
};

export default Faq;
