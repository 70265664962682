import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as Trash } from "bootstrap-icons/icons/trash3.svg";
import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import CustomStyles from "../../components/utils/CustomStyles";

const ReturnList = () => {
  const [returnItems, setReturnItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedReturnType, setSelectedReturnType] = useState("");

  const navigateTo = useNavigate();

  //format date
  const formatDate = (dateString) => {
    // Check if dateString is null or undefined
    if (!dateString) {
      return "Not Available";
    }
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month}-${day}`;
  };

  //handle return type selction
  const handleCustomerTypeSelect = (type) => {
    setSelectedReturnType(type);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const fetchReturnList = async () => {
      setLoading(true);
      try {
        // const authData = JSON.parse(localStorage.getItem("auth"));
        // const accessToken = authData.accessToken;
        const response = await axios.get(
          ApiServices.getReturn,
          {},
          {
            headers: {
              // logintoken: accessToken,
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        //console.log(response);
        setReturnItems(response.data);
        if (response.data.status === "300") {
          toast.error(response.data.message);
          return;
        }
        if (response.data.status === "302") {
          localStorage.removeItem("auth");
          toast.error(response.data.message);
          window.location.href = "/";
        }
        if (response.data.status === "400") {
          toast.error(response.data.message);
          navigateTo("/admin/return/items");
        }
      } catch (error) {
        console.error("Error fetching categorys list:", error.message);
        // toast.error("Error fetching peoduct list.");
      } finally {
        setLoading(false);
      }
    };

    fetchReturnList();
  }, []);

  // ====================================fetch  category =================================================

  // Define toastMixin
  const toastMixin = Swal.mixin({
    toast: true,
    icon: "success",
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  // Define function to handle delete confirmation
  const handleDeleteConfirmation = (categoryId) => {
    setConfirmationLoading(true);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      setConfirmationLoading(false);
      if (result.isConfirmed) {
        handleDeleteReturnItems(categoryId);
      }
    });
  };

  // Define function to delete customer
  const handleDeleteReturnItems = async (categoryId) => {
    try {
      // Send delete request to your API
      // await axios.delete(`${ApiServices.deleteCustomer}/${customerId}`);
      await axios.delete(`http://localhost:3000/products/${categoryId}`);

      // Update the customer list after deletion
      const updatedReturnItems = returnItems.filter(
        (returnItems) => returnItems.id !== categoryId
      );
      setReturnItems(updatedReturnItems);
      setLoading(false);
      toastMixin.fire({
        title: "Retrun items  has been deleted",
      });
    } catch (error) {
      console.error("Error deleting items:", error.message);
      Swal.fire("Error!", "Failed to delete returned items.", "error");
      setLoading(false);
    }
  };
  // ==================================== end of delete product =================================================

  //filter data by name
  const handleFilter = (e) => {
    setFilterText(e.target.value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const columns = [
    // {
    //   name: "Date",
    //   selector: (row) => formatDate(row.created_at),
    //   sortable: true,
    // },
    {
      name: "Image",
      selector: (row) => {
        //console.log("Image URL:", row.image); // Debugging line
        return (
          <img
            src={row.image || "https://via.placeholder.com/50"}
            alt={row.name}
            style={{
              width: "50px",
              height: "50px",
              marginTop: "7px",
              marginBottom: "7px",
            }}
          />
        );
      },
      sortable: false,
      width: "80px",
    },
    {
      name: "Product Name",
      selector: (row) => (row.name ? row.name.toUpperCase() : ""),
      sortable: true,
      width: "200px",
    },

    {
      name: "Code",
      selector: (row) => row.code,
      sortable: true,
      width: "100px",
    },
    {
      name: "Reason for return",
      selector: (row) => row.returnStatements,
      sortable: true,
      width: "350px",
    },
    {
      name: "Status",
      selector: (row) => row.returnStatus,
      sortable: true,
      width: "200px",
    },
    {
      name: "Return Type",
      selector: (row) => row.returnType,
      sortable: true,
      width: "200px",
    },
    {
      name: "Product Owner",
      selector: (row) => row.productOwner,
      sortable: true,
      width: "200px",
    },
    {
      name: "Return Date",
      selector: (row) => row.returnDate,
      sortable: true,
      width: "200px",
    },
    // {
    //   name: "More",
    //   cell: (row) => (
    //     <div className="d-flex justify-content-end buttons">
    //       <Link
    //         to="/admin/return/items/details"
    //         state={{ id: row.id }}
    //         className="btn btn-info btn-sm"
    //         onClick={(e) => {
    //           navigateTo("/admin/return/items/details", {
    //             replace: true,
    //             state: { id: row.id },
    //           });
    //         }}
    //         style={{ marginLeft: "5px" }}
    //         data-toggle="tooltip"
    //         data-placement="bottom"
    //         title="returned items  Details"
    //       >
    //         <Eye className="w-5 h-5 text-dash4 mr-3" />
    //       </Link>
    //     </div>
    //   ),
    //   width: "80px",
    // },
    // {
    //   name: "Edit",
    //   cell: (row) => (
    //     <div className="d-flex justify-content-end buttons">
    //       <Link
    //         to="/category/edit"
    //         state={{ id: row.id }}
    //         className="btn btn-info btn-sm"
    //         onClick={(e) => {
    //           navigateTo("/category/edit", {
    //             replace: true,
    //             state: { id: row.id },
    //           });
    //         }}
    //         style={{ marginLeft: "5px" }}
    //         data-toggle="tooltip"
    //         data-placement="bottom"
    //         title="Edit category"
    //       >
    //         <PencilSquare className="w-5 h-5 text-dash4 mr-3" />
    //       </Link>
    //     </div>
    //   ),
    //   width: "80px",
    // },

    {
      name: "Delete",
      cell: (row) => (
        <div className="d-flex justify-content-end buttons">
          <Link
            // to="/category/delete"
            // state={{ id: row.id }}
            className="btn btn-info btn-sm"
            // onClick={(e) => {
            //   navigateTo("/category/delete", {
            //     replace: true,
            //     state: { id: row.id },
            //   });
            // }}
            onClick={(e) => handleDeleteConfirmation(row.id)}
            style={{ marginLeft: "5px" }}
            data-toggle="tooltip"
            data-placement="bottom"
            title="Delete returned items"
            disabled={confirmationLoading}
          >
            <Trash className="w-5 h-5 text-dash3 mr-3" />
          </Link>
        </div>
      ),
      width: "80px",
    },
  ];

  // const filteredItems = returnItems.filter((item) => {
  //   for (let key in item) {
  //     if (
  //       typeof item[key] === "string" &&
  //       item[key].toLowerCase().includes(filterText.toLowerCase())
  //     ) {
  //       return true;
  //     }
  //   }
  //   return false;
  // });

  //filter data
  const filteredItems = returnItems.filter((item) => {
    const matchesFilterText = Object.values(item).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(filterText.toLowerCase())
    );

    //filter data based on selection
    const matchesReturnType =
      !selectedReturnType ||
      item.returnType.toLowerCase() === selectedReturnType.toLowerCase();

    return matchesFilterText && matchesReturnType;
  });

  const data = filteredItems.map((returnItems) => ({
    // created_at: formatDate(categorys.created_at),
    image: returnItems.image,
    // createdBy: category.createdBy ? category.createdBy.toUpperCase() : "",
    name: returnItems.name ? returnItems.name.toUpperCase() : "",
    code: returnItems.code ? returnItems.code.toUpperCase() : "",
    returnStatements: returnItems.returnStatements
      ? returnItems.returnStatements.toUpperCase()
      : "",
    returnStatus: returnItems.returnStatus
      ? returnItems.returnStatus.toUpperCase()
      : "",
    returnDate: returnItems.returnDate
      ? returnItems.returnDate.toUpperCase()
      : "",
    productOwner: returnItems.productOwner
      ? returnItems.productOwner.toUpperCase()
      : "",
    returnType: returnItems.returnType
      ? returnItems.returnType.toUpperCase()
      : "",
    id: returnItems.id,
  }));

  // Sort data based on creation date
  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  return (
    <div>
      <MainLayouts>
        <div className="sm:rounded-lg py-6 w-full">
          <h1>Returned Items</h1>
          <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap justify-between">
            <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
              <input
                type="search"
                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search"
                value={filterText}
                onChange={handleFilter}
              />
            </div>

            {/* <Link
              to="/admin/return/items"
              className="w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <span className="flex justify-center sm:justify-start mx-2">

                Return Items
              </span>
            </Link> */}

            {/* <div className="relative w-full md:w-auto flex-shrink-0">
              <div>
                <Link
                  to="/admin/customer/transactions/history"
                  className="bg-darkGreen3 inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-3 text-sm font-semibold text-lGrey3 shadow-sm ring-1 ring-inset  hover:bg-gray-50"
                >
                  Customer Transaction History
                </Link>
              </div>
            </div> */}
            {/* Select Options Button */}
            <div className="relative w-full md:w-auto flex-shrink-0">
              <div>
                <button
                  type="button"
                  className="bg-white inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-black1 hover:bg-gray-50"
                  id="menu-button"
                  aria-expanded={isDropdownOpen}
                  aria-haspopup="true"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  Select Return Item Type
                  <svg
                    className="-mr-1 h-5 w-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              {isDropdownOpen && (
                <div
                  className="absolute right-0 z-10 mt-2 w-full md:w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-lGrey shadow-lg ring-1 ring-black1 ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                >
                  <div
                    className="py-1 rounded-md"
                    role="none"
                    style={{ backgroundColor: "white" }}
                  >
                    <button
                      onClick={() => handleCustomerTypeSelect("sales return")}
                      className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-white hover:text-darkGreen3"
                      role="menuitem"
                    >
                      Sales Return
                    </button>
                    <button
                      onClick={() =>
                        handleCustomerTypeSelect("purchase return")
                      }
                      className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-white hover:text-darkGreen3"
                      role="menuitem"
                    >
                      Purchase Return
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <DataTable
              columns={columns}
              data={sortedData}
              pagination
              striped
              highlightOnHover
              noDataComponent={<div>No return items found</div>}
              customStyles={CustomStyles}
            />
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default ReturnList;
