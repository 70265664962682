import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as CheckSquareFill } from "bootstrap-icons/icons/check-square-fill.svg";
import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import Desk from "../../assets/desk.png";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import CustomStyles from "../../components/utils/CustomStyles";
import CurrencyFormatter from "../../components/utils/CurrencyFormatter";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";

const CustomerTransactionHistory = () => {
  const [CustomerHistory, setCustomerHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);

  const navigateTo = useNavigate();

  //format date
  const formatDate = (dateString) => {
    // Check if dateString is null or undefined
    if (!dateString) {
      return "Not Available";
    }
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const fetchCustomerList = async () => {
      setLoading(true);
      try {
        // const authData = JSON.parse(localStorage.getItem("auth"));
        // const accessToken = authData.accessToken;
        const response = await axios.get(
          ApiServices.getPayment,
          {},
          {
            headers: {
              // logintoken: accessToken,
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        //console.log(response);
        setCustomerHistory(response.data);
        if (response.data.status === "300") {
          toast.error(response.data.message);
          return;
        }
        if (response.data.status === "302") {
          localStorage.removeItem("auth");
          toast.error(response.data.message);
          navigateTo("/");
        }
        if (response.data.status === "400") {
          toast.error(response.data.message);
          navigateTo("/CustomerHistory/list");
        }
      } catch (error) {
        console.error("Error fetching CustomerHistory list:", error.message);
        // toast.error("Error fetching peoduct list.");
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerList();
  }, []);

  // ====================================Confirm CustomerHistory =================================================

  //filter data by name
  const handleFilter = (e) => {
    setFilterText(e.target.value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const columns = [
    // {
    //   name: "Date",
    //   selector: (row) => formatDate(row.created_at),
    //   sortable: true,
    // },
    {
      name: "Customer Name",
      selector: (row) => row.name,
      sortable: true,
      width: "250px",
    },
    {
      name: "Transaction Ref",
      selector: (row) => row.transactionId,
      sortable: true,
      width: "240px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "240px",
    },

    {
      name: "Product",
      selector: (row) => row.product,
      sortable: true,
      width: "200px",
    },
    {
      name: "Amount",
      selector: (row) => CurrencyFormatter(row.amount),
      sortable: true,
      width: "200px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "150px",
    },
    {
      name: "Product Owner",
      selector: (row) => row.productOwner,
      sortable: true,
      width: "150px",
    },

    {
      name: "CustomerHistory Details",
      cell: (row) => (
        <div className="d-flex justify-content-end buttons">
          <Link
            to="/admin/CustomerHistory/details"
            state={{ id: row.id }}
            className="btn btn-info btn-sm"
            onClick={(e) => {
              navigateTo("/CustomerHistory/details", {
                replace: true,
                state: { id: row.id },
              });
            }}
            style={{ marginLeft: "5px" }}
            data-toggle="tooltip"
            data-placement="bottom"
            title="View CustomerHistory Details"
          >
            <Eye className="w-5 h-5 text-dash4 mr-3" />
          </Link>
        </div>
      ),
      width: "150px",
    },
  ];

  const filteredItems = CustomerHistory.filter((item) => {
    for (let key in item) {
      if (
        typeof item[key] === "string" &&
        item[key].toLowerCase().includes(filterText.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  });

  const data = filteredItems.map((CustomerHistory) => ({
    // created_at: formatDate(products.created_at),
    name: CustomerHistory.name ? CustomerHistory.name.toUpperCase() : "",
    email: CustomerHistory.email ? CustomerHistory.email : "",
    product: CustomerHistory.product
      ? CustomerHistory.product.toUpperCase()
      : "",
    amount: CustomerHistory.amount ? CustomerHistory.amount : "0.00",
    status: CustomerHistory.status ? CustomerHistory.status.toUpperCase() : "",
    transactionId: CustomerHistory.transactionId
      ? CustomerHistory.transactionId.toUpperCase()
      : "",
    id: CustomerHistory.id,
  }));

  // Sort data based on creation date
  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <h1>Customer Transaction History </h1>
          <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap justify-between">
            <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
              <input
                type="search"
                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search"
                value={filterText}
                onChange={handleFilter}
              />
            </div>
            <div className="pb-4 bg-white dark:bg-gray-900 flex justify-end">
              <Link
                to="/admin/customer"
                className="text-dGrey3 py-3 bg-darkGreen3 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center w-/4 my-0"
              >
                <span className="flex mx-2">
                  <List className="mr-2" />
                  Back To Customer List
                </span>
              </Link>
            </div>
          </div>
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <DataTable
              columns={columns}
              data={sortedData}
              pagination
                striped
              highlightOnHover
              noDataComponent={<div>No CustomerHistory list found</div>}
              customStyles={CustomStyles}
            />
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default CustomerTransactionHistory;
