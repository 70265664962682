import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import { handleError } from "../../components/utils/AuthenticationCheck";
import { ReactComponent as PencilFill } from "bootstrap-icons/icons/pencil-fill.svg";

const AppSettingList = () => {
    const [settings, setSettings] = useState([]);
    const [loading, setLoading] = useState(true);

    const navigateTo = useNavigate();

    useEffect(() => {
        const fetchAllAppSettings = async () => {
            setLoading(true);
            try {
                const authData = JSON.parse(localStorage.getItem("auth"));
                const token = authData?.token;
                if (!token) {
                    throw new Error("No access token found.");
                }

                const response = await axios.get(ApiServices.getAllAppSettings, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                });


                // console.log("these are all the app settings", response.data);
                const { status, message, data } = response.data;
                if (status === 200) {
                    setSettings(data);
                    // Only show toast if it hasn't been shown before
                    if (!toast.isActive("appsettings-fetch-success")) {
                        toast.success(message, { toastId: "appsettings-fetch-success" });
                    }
                } else {
                    handleError({ response: { status, data: { message } } }, navigateTo);
                }
            } catch (error) {
                handleError(error, navigateTo);
            } finally {
                setLoading(false);
            }
        };

        fetchAllAppSettings();
    }, []);

    const formatAppSettingText = (text) => {
        // Replace underscores with spaces
        let formattedText = text.replace(/_/g, " ");

        // Capitalize the first letter of each word
        formattedText = formattedText
            .toLowerCase()
            .replace(/\b\w/g, (char) => char.toUpperCase());

        return formattedText;
    };

    return (
        <div>
            <MainLayouts>
                <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
                    <section
                        className="w-full pr-[3%] pt-3 my-3"
                        style={{ backgroundColor: "#F7F7F7" }}
                    >
                        <nav className="bg-gray-200 py-0 px-4 rounded-lg">
                            <ol className="list-none p-0 inline-flex mb-2">
                                <li className="flex items-center">
                                    <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                                        App Settings
                                    </p>
                                    <ChevronRightIcon />
                                </li>
                                <li className="flex items-center">
                                    <p className="text-orange1">List</p>
                                </li>
                            </ol>
                        </nav>
                    </section>
                   
                    {loading ? (
                        <Spinner loading={loading} />
                    ) : (
                        <section className="text-gray-600 body-font">
                            <div className="container px-1 py-2 mx-auto">
                                <div className="flex flex-wrap -m-2">
                                    {settings.length > 0 ? (
                                        settings.map((sett, index) => {

                                            return (
                                                <div
                                                    key={index}
                                                    className=" p-2 lg:w-1/3 md:w-1/2 w-full"
                                                >
                                                    <div className="h-full flex items-center border-darkGreen3 border  p-1.5 rounded-lg">
                                                        <div className="flex-grow">
                                                            <h2 className="flex items-center justify-between text-darkGreen3 title-font font-medium">
                                                                <span> {formatAppSettingText(sett.name)}</span>

                                                                <span className="flex items-center">
                                                                    <Link
                                                                        to="/admin/appsettings/update"
                                                                        state={{
                                                                            sett,
                                                                        }}
                                                                        className="text-dash5 mr-2 border border-gray-300 rounded-md p-2"
                                                                    >
                                                                        <PencilFill className="w-5 h-5" />
                                                                    </Link>
                                                                </span>
                                                            </h2>

                                                            <p className="text-gray-500 flex items-center">
                                                                <span className="ml-2 text-dash5">
                                                                    {sett.value}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className="w-full text-center">
                                            No app settings  found
                                        </div>
                                    )}
                                </div>

                            </div>
                        </section>
                    )}
                </div>

            </MainLayouts>
        </div>
    );
};

export default AppSettingList;


