import React, { useState, useEffect, useMemo } from "react";
import MainLayouts from "../../../Layout/MainLayouts";
import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import Spinner from "../../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { handleError } from "../../../components/utils/AuthenticationCheck";
import axios from "axios";
import ApiServices from "../../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import CustomStyles from "../../../components/utils/CustomStyles";
import formatDate from "../../../components/utils/DateFormatter";
import Swal from "sweetalert2";
import { ReactComponent as PencilSquare } from "bootstrap-icons/icons/pencil-square.svg";
import { ReactComponent as Trash } from "bootstrap-icons/icons/trash3.svg";
import { ReactComponent as PlusSquareFill } from "bootstrap-icons/icons/plus-square-fill.svg";

const GetBillers = () => {
  const [billers, setBillers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);

  const navigateTo = useNavigate();

  useEffect(() => {
    const fetchBilerList = async () => {
      setLoading(true);
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) {
          throw new Error("No access token found.");
        }
        // console.log("i am token", token);
        // Extract the actual token part
        const actualToken = token.split("|")[1];

        const response = await axios.post(
          ApiServices.getBillers,
          {},
          {
            headers: {
              Authorization: `Bearer ${actualToken}`,
              token: token,
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );

        const { status, message, data } = response.data;
        //console.log("this is all billers", response.data);
        // setBillers(data);
        if (status === 200) {
          setBillers(data.slice().reverse());
          toast.success(message, { toastId: "billers-fetch-success" });
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setLoading(false);
      }
    };

    fetchBilerList();
  }, []);

  // Function to format permission text
  const formatText = (text) => {
    let formattedText = text.replace(/_/g, " ");
    formattedText = formattedText
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
    return formattedText;
  };

  //filter data by name
  const handleFilter = (e) => {
    setFilterText(e.target.value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const columns = [
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex justify-content-start  ">
          <Link
            to="/admin/billers/update"
            state={{ billers: row }}
            className="mx-2 my-2 group"
          >
            <PencilSquare className="w-5 h-5 text-dash4 " />
            <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs  whitespace-nowrap ">
              Edit
            </div>
          </Link>
          <Link
            to="/admin/billers/view/product"
            state={{ billers: row }}
            className="mx-2 my-2 group"
          >
            <Eye className="w-5 h-5 text-darkGreen3 " />
            <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs  whitespace-nowrap ">
              View product
            </div>
          </Link>

          {/* <Link
            className="mx-2 my-2 group"
            onClick={() => handleDeleteConfirmation(row.flash_sale_id)}
            disabled={confirmationLoading}
          >
            <Trash className="w-5 h-5 text-dash3 " />
            <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs  whitespace-nowrap ">
              Delete billers
            </div>
          </Link> */}
        </div>
      ),
      width: "200px",
    },

    {
      name: "Biller Name",
      selector: (row) => row.name ? formatText(row.name).toUpperCase() : "NOT AVAILABLE",
      sortable: true,
      width: "300px",
    },

    {
      name: "Created Date",
      selector: (row) =>
        row.created_at ? formatDate(row.created_at) : "NOT AVAILABLE",
      sortable: true,
      width: "300px",
    },
  ];



  const filteredItems = billers.filter((item) => {
    for (let key in item) {
      if (
        typeof item[key] === "string" &&
        item[key].toLowerCase().includes(filterText.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  });

  const data = filteredItems.map((billers) => ({

    created_at: billers.created_at ? billers.created_at : "NOT AVAILABLE",
    name: billers.name ? billers.name.toUpperCase() : "NOT AVAILABLE",
    id: billers.id,
  }));

  // Sort data based on creation date
  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.start_date);
    const dateB = new Date(b.start_date);
    return dateB - dateA;
  });

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          {/* <h1>Product List</h1> */}
          <section
            className="w-full  pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Billers
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1 ">List</p>
                </li>
              </ol>
            </nav>
          </section>
          <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap justify-between">
            <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
              <input
                type="search"
                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search"
                value={filterText}
                onChange={handleFilter}
              />
            </div>
            <Link
              to="/admin/billers/create"
              className="w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <span className="flex justify-center sm:justify-start mx-2">
                <PlusSquareFill className="mr-2" />
                Create Biller
              </span>
            </Link>
          </div>
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <DataTable
              columns={columns}
              data={sortedData}
              pagination
              striped
              responsive
              highlightOnHover
              noDataComponent={<div>No Billers found</div>}
              customStyles={CustomStyles}

            />
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default GetBillers;
