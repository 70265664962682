import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as Trash } from "bootstrap-icons/icons/trash3.svg";
import { ReactComponent as PlusSquare } from "bootstrap-icons/icons/plus-square.svg";
import { ReactComponent as PencilSquare } from "bootstrap-icons/icons/pencil-square.svg";
import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import CustomStyles from "../../components/utils/CustomStyles";
// import CurrencyFormatter from "../../components/utils/CurrencyFormatter";
import { handleError } from "../../components/utils/AuthenticationCheck";

const StoreList = () => {
  const [store, setStore] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);

  const navigateTo = useNavigate();

  useEffect(() => {
    const fetchAllStoreList = async () => {
      setLoading(true);
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) {
          throw new Error("No access token found.");
        }
        // console.log("i am token", token);
        const response = await axios.get(ApiServices.getAllStore, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        const { status, message, data } = response.data;
        //  console.log("this is all store", response.data.data);
        if (status === 200) {
          setStore(data);
          toast.success(message, { toastId: "product-fetch-success" });
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setLoading(false);
      }
    };

    fetchAllStoreList();
  }, []);

  // ====================================delete product =================================================

  // Define toastMixin
  const toastMixin = Swal.mixin({
    toast: true,
    icon: "success",
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  // Define function to handle delete confirmation
  const handleDeleteStore = async (storeId) => {
    const formData = {
      store_id: storeId,
    };
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }
      // console.log("i am token",token);
      const response = await axios.delete(
        ApiServices.removeStore,

        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: formData,
          withCredentials: true,
        }
      );

      const updatedProduct = store.filter(
        (store) => store.store_id !== storeId
      );

      setStore(updatedProduct);

      const { status, message } = response.data;
      // console.log("this is the deleted items",data);
      if (status === 200 && Array.isArray(data)) {
        toast.error(message);
        return;
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteConfirmation = (storeId) => {
    setConfirmationLoading(true);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      setConfirmationLoading(false);
      if (result.isConfirmed) {
        handleDeleteStore(storeId);
      }
    });
  };

  // ==================================== end of delete product =================================================

  //filter data by name
  const handleFilter = (e) => {
    setFilterText(e.target.value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const columns = [
    {
      name: "More",
      cell: (row) => (
        <div className="d-flex justify-content-end">
          <Link
            to="/admin/store/details"
            state={{ id: row.store_id }}
            className="btn btn-info btn-sm"
          >
            <Eye className="w-5 h-5 text-dash4" />
          </Link>
        </div>
      ),
      width: "80px",
    },
    {
      name: "Edit",
      cell: (row) => (
        <div className="d-flex justify-content-end buttons">
          <Link to="/admin/store/update" state={{ store: row }}>
            <PencilSquare className="w-5 h-5 text-dash4" />
          </Link>
        </div>
      ),
      width: "80px",
    },
    // {
    //   name: "Image",
    //   selector: (row) => (
    //     <img
    //       src={row.image || "https://via.placeholder.com/50"}
    //       alt={row.name}
    //       style={{
    //         width: "50px",
    //         height: "50px",
    //         marginTop: "7px",
    //         marginBottom: "7px",
    //       }}
    //     />
    //   ),
    //   sortable: false,
    //   width: "80px",
    // },
    {
      name: "Name",
      selector: (row) => (row.name ? row.name.toUpperCase() : ""),
      sortable: true,
      width: "250px",
    },
    {
      name: "Email",
      selector: (row) =>
        row.email ? row.email.toUpperCase() : "NOT AVAILABLE",
      sortable: true,
      width: "250px",
    },
    {
      name: "Phone Number",
      selector: (row) => (row.telephone ? row.telephone : "NOT AVAILABLE"),
      sortable: true,
      width: "150px",
    },
    // {
    //   name: "Status",
    //   selector: (row) =>
    //     row.product_status ? row.status.toUpperCase() : "NOT AVAILABLE",
    //   sortable: true,
    //   cell: (row) => {
    //     let color = "grey";

    //     if (row.status === "ACTIVE") {
    //       color = "green";
    //     } else if (row.status === "INACTIVE") {
    //       color = "red";
    //     } else if (row.status === "PENDING") {
    //       color = "blue";
    //     }

    //     return (
    //       <span style={{ color: color }}>
    //         {row.status ? row.status.toUpperCase() : "NOT AVAILABLE"}
    //       </span>
    //     );
    //   },
    //   width: "150px",
    // },

    // {
    //   name: "Delete",
    //   cell: (row) => (
    //     <div className="d-flex justify-content-end buttons">
    //       <Link
    //         className="btn btn-info btn-sm"
    //         onClick={() => handleDeleteConfirmation(row.store_id)}
    //         disabled={confirmationLoading}
    //       >
    //         <Trash className="w-5 h-5 text-dash3" />
    //       </Link>
    //     </div>
    //   ),
    //   width: "80px",
    // },
  ];

  const filteredItems = store.filter((item) => {
    for (let key in item) {
      if (
        typeof item[key] === "string" &&
        item[key].toLowerCase().includes(filterText.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  });

  const data = filteredItems.map((store) => ({
    // created_at: formatDate(store.created_at),
    image: store.image,
    name: store.name ? store.name.toUpperCase() : "",
    email: store.owner?.email
      ? store.owner.email.toUpperCase()
      : "NOT AVAILABLE",
    telephone: store.telephone ? store.telephone : "NOT AVAILABLE",
    owner_type: store.owner_type
      ? store.owner_type.toUpperCase()
      : "NOT AVAILABLE",

    status: store.status ? store.status.toUpperCase() : "",
    store_id: store.store_id,
    description: store.description,
    notification_status: store.notification_status,
  }));

  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <section
            className="w-full  pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Vendor Store
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 ">List</p>
                </li>
              </ol>
            </nav>
          </section>
          <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap justify-between">
            <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
              <input
                type="search"
                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search"
                value={filterText}
                onChange={handleFilter}
              />
            </div>
            <Link
              to="/admin/store/create"
              className="w-full sm:w-auto text-dGrey3 bg-orange1 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <span className="flex justify-center sm:justify-start mx-2">
                <PlusSquare className="mr-2" />
                Create Vendor Store
              </span>
            </Link>
          </div>
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <DataTable
              columns={columns}
              data={sortedData}
              pagination
              striped
              responsive
              highlightOnHover
              noDataComponent={<div>No store found</div>}
              customStyles={CustomStyles}
            />
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default StoreList;
