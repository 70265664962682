const CustomStyles = {
  table: {
    style: {
      borderCollapse: "collapse",
      width: "100%",
    },
  },
  headRow: {
    style: {
      background: "#1D9E34",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      fontWeight: "bold",
      fontSize: "14px",
      color: "white",
      width: "100px",
    },
  },
  pagination: {
    style: {
      display: "flex",
      justifyContent: "end",
      color: "#1D9E34",
    },
  },
};

export default CustomStyles;
