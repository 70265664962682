


// import { useEffect, useRef, useContext } from "react";
// import { toast } from "react-toastify";
// import { AuthContext } from "../../AuthProvider/AuthProvider";
// import { ReactComponent as XSquareFill } from "bootstrap-icons/icons/x-square-fill.svg";

// const AdminInactivityLogout = ({ auth }) => {
//   const { logout } = useContext(AuthContext);
//   const timeoutIdRef = useRef(null);
//   const logoutTimeoutRef = useRef(null);
//   const toastIdRef = useRef(null);

//   // **Custom Message Component with Cancel Button**:
//   const Msg = () => (
//     <div style={{ textAlign: "center" }}>
//       <p>You will be logged out due to inactivity</p>
//       {/* Cancel Button to clear logout and dismiss toast */}
//       <button
//         onClick={() => {
//           clearTimeout(logoutTimeoutRef.current); // Clear the logout timeout
//           toast.dismiss(toastIdRef.current);      // Dismiss the toast
//         }}
//         className= "text-dGrey3 px-3 py-1 rounded"
//       >
//         {/* Cancel */}
//       <XSquareFill className="w-5 h-5  text-red1" />
//       </button>
//     </div>
//   );

//   const resetTimeout = () => {
//     if (timeoutIdRef.current) {
//       clearTimeout(timeoutIdRef.current);
//     }
//     if (logoutTimeoutRef.current) {
//       clearTimeout(logoutTimeoutRef.current);
//     }

//     timeoutIdRef.current = setTimeout(() => {
//       // **Check if toast is already active**:
//       if (!toast.isActive(toastIdRef.current)) {
//         const toastId = toast.info(<Msg />, {
//           position: "top-right",
//           autoClose: false,
//           closeButton: false,
//           hideProgressBar: true,
//           closeOnClick: false,
//           draggable: false,
//         });
//         toastIdRef.current = toastId;
//       }

//       // Set the logout timeout
//       logoutTimeoutRef.current = setTimeout(() => {
//         toast.dismiss(toastIdRef.current); // Dismiss the toast after timeout
//         logout();                          // Trigger the logout
//       }, 6000); // Second check (6 seconds)
//       // }, 120000); // 120,000 ms (2 minutes) for real use
//     }, 900000); // 900,000 ms (15 minutes)
//   }, 

//   const handleActivity = () => {
//     resetTimeout();
//     if (toastIdRef.current) {
//       toast.dismiss(toastIdRef.current); // Dismiss toast if there's activity
//       toastIdRef.current = null;
//     }
//   };

//   useEffect(() => {
//     if (!auth) return;

//     window.addEventListener("mousemove", handleActivity);
//     window.addEventListener("mousedown", handleActivity);
//     window.addEventListener("keypress", handleActivity);
//     window.addEventListener("scroll", handleActivity);
//     window.addEventListener("touchstart", handleActivity);

//     resetTimeout();

//     return () => {
//       clearTimeout(timeoutIdRef.current);
//       clearTimeout(logoutTimeoutRef.current);
//       window.removeEventListener("mousemove", handleActivity);
//       window.removeEventListener("mousedown", handleActivity);
//       window.removeEventListener("keypress", handleActivity);
//       window.removeEventListener("scroll", handleActivity);
//       window.removeEventListener("touchstart", handleActivity);
//     };
//   }, [auth, logout]);

//   return null;
// };

// export default AdminInactivityLogout;


import { useEffect, useRef, useContext, useCallback } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../../AuthProvider/AuthProvider";
import { ReactComponent as XSquareFill } from "bootstrap-icons/icons/x-square-fill.svg";

const AdminInactivityLogout = ({ auth }) => {
  const { logout } = useContext(AuthContext);
  const timeoutIdRef = useRef(null);
  const logoutTimeoutRef = useRef(null);
  const toastIdRef = useRef(null);

  // Custom Message Component with Cancel Button:
  const Msg = () => (
    <div style={{ textAlign: "start" }}>
      <p>Loggin you out for  inactivity
        {/* Cancel Button to clear logout and dismiss toast */}
        <span><button
          onClick={() => {
            clearTimeout(logoutTimeoutRef.current); // Clear the logout timeout
            toast.dismiss(toastIdRef.current);      // Dismiss the toast
          }}
          className="text-dGrey3 px-3 py-1 rounded"
        >
          <XSquareFill className="w-5 h-5  text-red1 justify-end" />
        </button>
        </span>
      </p>
    </div>
  );

  // Memoized resetTimeout function to avoid unnecessary re-creation
  const resetTimeout = useCallback(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
    if (logoutTimeoutRef.current) {
      clearTimeout(logoutTimeoutRef.current);
    }

    timeoutIdRef.current = setTimeout(() => {
      // Check if toast is already active:
      if (!toast.isActive(toastIdRef.current)) {
        const toastId = toast.info(<Msg />, {
          position: "top-right",
          autoClose: false,
          closeButton: false,
          hideProgressBar: true,
          closeOnClick: false,
          draggable: false,
        });
        toastIdRef.current = toastId;
      }

      // Set the logout timeout
      logoutTimeoutRef.current = setTimeout(() => {
        toast.dismiss(toastIdRef.current); // Dismiss the toast after timeout
        logout();                          // Trigger the logout
      }, 6000); // Second check (6 seconds)
      // }, 120000); // 120,000 ms (2 minutes) for real use
    }, 900000); // 900,000 ms (15 minutes)
  }, [logout]); // Dependency array includes logout 

  // Memoized handleActivity function
  const handleActivity = useCallback(() => {
    resetTimeout();
    if (toastIdRef.current) {
      // Dismiss toast if there's activity
      toast.dismiss(toastIdRef.current);
      toastIdRef.current = null;
    }
  }, [resetTimeout]);

  useEffect(() => {
    if (!auth) return;

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("mousedown", handleActivity);
    window.addEventListener("keypress", handleActivity);
    window.addEventListener("scroll", handleActivity);
    window.addEventListener("touchstart", handleActivity);

    resetTimeout();

    return () => {
      clearTimeout(timeoutIdRef.current);
      clearTimeout(logoutTimeoutRef.current);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("mousedown", handleActivity);
      window.removeEventListener("keypress", handleActivity);
      window.removeEventListener("scroll", handleActivity);
      window.removeEventListener("touchstart", handleActivity);
    };
    // Include handleActivity and resetTimeout in dependencies
  }, [auth, handleActivity, resetTimeout]);

  return null;
};

export default AdminInactivityLogout;

