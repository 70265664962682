import React, { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";
import { ReactComponent as CloudUploadFill } from "bootstrap-icons/icons/cloud-upload-fill.svg";

import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { handleError } from "../../components/utils/AuthenticationCheck";
import Spinner from "../spinner/Spinner";

const AddProductStockVariant = () => {
  const [name, setName] = useState("");
  const [productName, setProductName] = useState("");
  const [stock_id, setStockId] = useState("");
  // const [store_id, setStoreId] = useState("");
  const [quantity, setQuantity] = useState("");
  const [sku, setSku] = useState("");
  const [price, setPrice] = useState("");
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [StoreLoading, setStoreLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);

  const location = useLocation();
  const navigateTo = useNavigate();

  const { productDetails } = location.state || {};

  // Now you can use productDetails to populate your form or for other purposes
  //   console.log("na me dey here:", productDetails);

  useEffect(() => {
    if (productDetails) {
      // console.log("Product Stock ID:", productDetails);
      setProductName(productDetails.name || "");
      setStockId(productDetails.stock_id || "");
      // setStoreId(productDetails.store_id || "");
      setQuantity(productDetails.quantity || "");
      setSku(productDetails.sku || "");
    }
  }, [productDetails]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validateForm = () => {
      // const batch_number = null;
      if (!name || !stock_id || !value || !price) {
        toast.error("All fields are required.");
      }
      return true;
    };

    if (!validateForm()) return;

    setLoading(true);
    const formData = {
      price: price || null,
      name,
      stock_id,
      value,
    };
    // console.log("this is my form data", formData);
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }

      const response = await axios.post(
        ApiServices.addProductStockVariant,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const { status, message } = response.data;
      if (status === 200) {
        toast.success(message, {
          toastId: "product-stock-variant-added-successfully",
        });
        navigateTo("/admin/product/stock/details", {
          state: { stock_id: formData.stock_id },
        });
        setName("");
        setPrice("");
        setValue("");
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <section
            className="w-full  pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Product
                  </p>
                  <ChevronRightIcon />
                </li>

                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Stock
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Variant
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1 ">Create</p>
                </li>
              </ol>
            </nav>
          </section>

          <section className="gray_background">
            <div className="container px-1 pb-2 mx-auto">
              <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
                {loading ? (
                  <Spinner loading={loading} />
                ) : (
                  <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 sm:grid-cols-1 gap-12">
                      <input type="hidden" name="stock_id" value={stock_id} />
                      {/* <input type="hidden" name="store_id" value={store_id} /> */}
                      <div className="w-full">
                        <label
                          htmlFor="product_stock_name"
                          className="block mb-2 text-sm font-medium text-black dark:text-white"
                        >
                          Product Stock Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          defaultValue={productName}
                          readOnly
                          className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                        />
                      </div>
                      {/* <div className="w-2.5/5">
                        <label
                          htmlFor="quantity"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Quantity
                        </label>
                        <input
                          type="text"
                          id="quantity"
                          name="quantity"
                          defaultValue={quantity}
                          readOnly
                          className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                        />
                      </div> */}
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 ">
                      <div className="w-full mt-4">
                        <div className="col-span-1 sm:col-span-1">
                          <label
                            htmlFor="quantity"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Sku
                          </label>
                          <input
                            type="text"
                            id="sku"
                            name="sku"
                            defaultValue={sku}
                            readOnly
                            className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          />
                        </div>
                      </div>
                      <div className="w-full mt-4">
                        <div className="col-span-1 sm:col-span-1">
                          <label
                            htmlFor="name"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Variant Name
                          </label>
                          <input
                            type="name"
                            name="name"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                            placeholder="Enter variant name"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-3">
                      <input type="hidden" name="stock_id" value={stock_id} />
                      <div className="w-full">
                        <label
                          htmlFor="product_stock_name"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Value
                        </label>
                        <input
                          type="text"
                          id="value"
                          name="value"
                          value={value}
                          onChange={(e) => setValue(e.target.value)}
                          className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                          placeholder="Enter value"
                        />
                      </div>
                      <div className="w-2.5/5">
                        <label
                          htmlFor="quantity"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Price
                        </label>
                        <input
                          type="number"
                          id="price"
                          name="price"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                          className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                          placeholder="Enter price"
                          min={0}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
                      <Link
                        to="/admin/product/stock/details"
                        // state={{ stock_id: productStock?.stock_id }}
                        state={{ stock_id }}
                        className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
                      >
                        <List className="mr-2" />
                        Back To List
                      </Link>
                      <button
                        type="submit"
                        className={`text-white bg-darkGreen3 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium ${loading ? "btn-loading" : "btn-primary"
                          }`}
                        disabled={loading}
                      >
                        {loading
                          ? "Loading..."
                          : "Add Product To Stock Variant"}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </section>
        </div>
      </MainLayouts>
    </div>
  );
};

export default AddProductStockVariant;
