import React, { useState, useEffect, useMemo } from "react";
import MainLayouts from "../../../Layout/MainLayouts";
import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import Spinner from "../../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { handleError } from "../../../components/utils/AuthenticationCheck";
import axios from "axios";
import ApiServices from "../../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import CustomStyles from "../../../components/utils/CustomStyles";
import formatDate from "../../../components/utils/DateFormatter";
import CurrencyFormatter from "../../../components/utils/CurrencyFormatter";

const GetBillsProducts = () => {
  const [airtimeProducts, setAirtimeProducts] = useState([]);
  const [dataProducts, setDataProducts] = useState([]);
  const [discoProducts, setDiscoProducts] = useState([]);
  const [tvProducts, setTvProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterTextAirtime, setFilterTextAirtime] = useState("");
  const [filterTextData, setFilterTextData] = useState("");
  const [filterTextDisco, setFilterTextDisco] = useState("");
  const [filterTextTv, setFilterTextTv] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedProductBillsType, setSelectedProductBillsType] =
    useState("airtime");

  const navigateTo = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isDropdownOpen && !event.target.closest("#menu-button")) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDropdownOpen]);

  const fetchBillProductList = async (type) => {
    setLoading(true);
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;

      // Extract the actual token part
      const actualToken = token.split("|")[1];

      const response = await axios.post(
        ApiServices.getBillsProduct,
        {
          // Send the selected type
          product: type,
        },
        {
          headers: {
            Authorization: `Bearer ${actualToken}`,
            token: token,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      // console.log("Response:", response.data);
      const { status, message, data } = response.data;
      if (status === 200) {
        switch (type) {
          case "airtime":
            setAirtimeProducts(data);
            break;
          case "data":
            setDataProducts(data);
            break;
          case "disco":
            setDiscoProducts(data);
            break;
          case "tv":
            setTvProducts(data);
            break;
          default:
            break;
        }
        //  toast.success(message, { toastId: "bills-product-fetch-success" });
      } else {
        console.log("Error:", message);
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      console.log("Error occurred:", error.message);
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch data based on selection
    fetchBillProductList(selectedProductBillsType);
  }, [selectedProductBillsType]);

  const handleFilter = (e, type) => {
    switch (type) {
      case "airtime":
        setFilterTextAirtime(e.target.value);
        break;
      case "data":
        setFilterTextData(e.target.value);
        break;
      case "disco":
        setFilterTextDisco(e.target.value);
        break;
      case "tv":
        setFilterTextTv(e.target.value);
        break;
      default:
        break;
    }
    setResetPaginationToggle(!resetPaginationToggle);
  };

  // Convert numeric status to human-readable text
  const getStatusText = (status) => {
    switch (status) {
      case "1":
        return "successful";
      case "2":
        return "failed";
      case "0":
      case "3":
        return "pending";
      default:
        return "";
    }
  };

  //airtime filter
  const filteredAirtimeProducts = airtimeProducts.filter((product) => {
    const filterText = filterTextAirtime.toLowerCase(); // Convert filter text to lowercase for consistent comparison

    const statusText = getStatusText(product.status); // Convert status to text for filtering

    const phoneNumber = product.phone_number ? product.phone_number : "";
    const requestId = product.request_id
      ? product.request_id.toLowerCase()
      : "";
    const network = product.network ? product.network.toLowerCase() : "";
    const created_at = product.created_at
      ? product.created_at.toLowerCase()
      : "";

    return (
      statusText.includes(filterText) ||
      phoneNumber.includes(filterTextAirtime) ||
      requestId.includes(filterText) ||
      network.includes(filterText) ||
      formatDate(created_at).includes(filterText)
    );
  });
  // Data filter
  const filteredDataProducts = dataProducts.filter((product) => {
    const filterText = filterTextData.toLowerCase();
    const statusText = getStatusText(product.status);
    const phoneNumber = product.phone_number
      ? product.phone_number.toLowerCase()
      : "";
    const requestId = product.request_id
      ? product.request_id.toLowerCase()
      : "";
    const network = product.network ? product.network.toLowerCase() : "";
    const created_at = product.created_at
      ? product.created_at.toLowerCase()
      : "";

    return (
      statusText.includes(filterText) ||
      phoneNumber.includes(filterText) ||
      requestId.includes(filterText) ||
      network.includes(filterText) ||
      formatDate(created_at).includes(filterText)
    );
  });

  // Disco filter
  const filteredDiscoProducts = discoProducts.filter((product) => {
    const filterText = filterTextDisco.toLowerCase();
    const statusText = getStatusText(product.status);
    const requestId = product.request_id
      ? product.request_id.toLowerCase()
      : "";
    const type = product.type ? product.type.toLowerCase() : "";
    const name = product.name ? product.name.toLowerCase() : "";
    const meterNo = product.meterNo ? product.meterNo.toLowerCase() : "";

    return (
      type.includes(filterText) ||
      statusText.includes(filterText) ||
      name.includes(filterText) ||
      requestId.includes(filterText) ||
      meterNo.includes(filterText)
    );
  });

  // TV filter
  const filteredTvProducts = tvProducts.filter((product) => {
    const filterText = filterTextTv.toLowerCase();
    const statusText = getStatusText(product.status);
    const customerName = product.customerName
      ? product.customerName.toLowerCase()
      : "";
    const smartcardNumber = product.smartcardNumber
      ? product.smartcardNumber.toLowerCase()
      : "";
    const requestId = product.request_id
      ? product.request_id.toLowerCase()
      : "";
    const type = product.type ? product.type.toLowerCase() : "";

    return (
      statusText.includes(filterText) ||
      customerName.includes(filterText) ||
      requestId.includes(filterText) ||
      type.includes(filterText) ||
      smartcardNumber.includes(filterText)
    );
  });

  const handleCustomerTypeSelect = (type) => {
    setSelectedProductBillsType(type);
    setIsDropdownOpen(false);
  };

  //table column

  const airtimeColumns = [
    {
      name: "Request Id",
      selector: (row) => (row.request_id ? row.request_id : "NOT AVAILABLE"),
      sortable: true,
      width: "auto",
    },
    {
      name: "Phone Number",
      selector: (row) =>
        row.phone_number ? row.phone_number : "NOT AVAIALABLE",
      sortable: true,
      width: "auto",
    },
    {
      name: "Amount",
      selector: (row) => (row.amount ? CurrencyFormatter(row.amount) : "0.00"),
      sortable: true,
      width: "auto",
    },
    {
      name: "Network",
      selector: (row) =>
        row.network ? row.network.toUpperCase() : "NOT AVAILABLE",
      sortable: true,
      width: "auto",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => {
        // Determine the background color based on the status value
        let backgroundColor;
        let textColor;

        switch (row.status) {
          case "1":
            backgroundColor = "bg-darkGreen3"; // Successful
            textColor = "text-dGrey3";
            break;
          case "2":
            backgroundColor = "bg-dash3"; // Failed
            textColor = "text-dGrey3";
            break;
          case "0":
          case "3":
            backgroundColor = "bg-dash5"; // Pending
            textColor = "text-dGrey3";
            break;
          default:
            backgroundColor = "bg-grey1";
            textColor = "text-dGrey3";
        }

        return (
          <div className={`p-1 rounded ${backgroundColor} ${textColor}`}>
            {row.status === "1"
              ? "Successful"
              : row.status === "2"
              ? "Failed"
              : "Pending"}
          </div>
        );
      },
      width: "120px",
    },

    {
      name: "Created At",
      selector: (row) =>
        row.created_at ? formatDate(row.created_at) : "NOT AVAILABLE",
      sortable: true,
      width: "auto",
    },
  ];

  const dataColumns = [
    {
      name: "Request Id",
      selector: (row) => (row.request_id ? row.request_id : "NOT AVAILABLE"),
      sortable: true,
      width: "auto",
    },
    {
      name: "Phone Number",
      selector: (row) =>
        row.phone_number ? row.phone_number : "NOT AVAIALABLE",
      sortable: true,
      width: "auto",
    },
    {
      name: "Amount",
      selector: (row) => (row.amount ? CurrencyFormatter(row.amount) : "0.00"),
      sortable: true,
      width: "auto",
    },
    {
      name: "Network",
      selector: (row) =>
        row.network ? row.network.toUpperCase() : "NOT AVAILABLE",
      sortable: true,
      width: "auto",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => {
        // Determine the background color based on the status value
        let backgroundColor;
        let textColor;

        switch (row.status) {
          case "1":
            backgroundColor = "bg-darkGreen3"; // Successful
            textColor = "text-dGrey3";
            break;
          case "2":
            backgroundColor = "bg-dash3"; // Failed
            textColor = "text-dGrey3";
            break;
          case "0":
          case "3":
            backgroundColor = "bg-dash5"; // Pending
            textColor = "text-dGrey3";
            break;
          default:
            backgroundColor = "bg-grey1";
            textColor = "text-dGrey3";
        }

        return (
          <div className={`p-1 rounded ${backgroundColor} ${textColor}`}>
            {row.status === "1"
              ? "Successful"
              : row.status === "2"
              ? "Failed"
              : "Pending"}
          </div>
        );
      },
      width: "120px",
    },

    {
      name: "Created At",
      selector: (row) =>
        row.created_at ? formatDate(row.created_at) : "NOT AVAILABLE",
      sortable: true,
      width: "auto",
    },
  ];

  const discoColumns = [
    {
      name: "Request Id",
      selector: (row) => (row.request_id ? row.request_id : "NOT AVAILABLE"),
      sortable: true,
      width: "auto",
    },
    {
      name: "Customer Name",
      selector: (row) => (row.name ? row.name.toUpperCase() : "NOT AVAIALABLE"),
      sortable: true,
      width: "auto",
    },
    {
      name: "Meter Number",
      selector: (row) => (row.meterNo ? row.meterNo : "NOT AVAIALABLE"),
      sortable: true,
      width: "auto",
    },
    {
      name: "Type",
      selector: (row) => (row.type ? row.type.toUpperCase() : "NOT AVAILABLE"),
      sortable: true,
      width: "auto",
    },
    {
      name: "Amount",
      selector: (row) => (row.amount ? CurrencyFormatter(row.amount) : "0.00"),
      sortable: true,
      width: "auto",
    },

    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => {
        // Determine the background color based on the status value
        let backgroundColor;
        let textColor;

        switch (row.status) {
          case "1":
            backgroundColor = "bg-darkGreen3"; // Successful
            textColor = "text-dGrey3";
            break;
          case "2":
            backgroundColor = "bg-dash3"; // Failed
            textColor = "text-dGrey3";
            break;
          case "0":
          case "3":
            backgroundColor = "bg-dash5"; // Pending
            textColor = "text-dGrey3";
            break;
          default:
            backgroundColor = "bg-grey1";
            textColor = "text-dGrey3";
        }

        return (
          <div className={`p-1 rounded ${backgroundColor} ${textColor}`}>
            {row.status === "1"
              ? "Successful"
              : row.status === "2"
              ? "Failed"
              : "Pending"}
          </div>
        );
      },
      width: "120px",
    },

    {
      name: "Created At",
      selector: (row) =>
        row.created_at ? formatDate(row.created_at) : "NOT AVAILABLE",
      sortable: true,
    },
  ];

  const tvColumns = [
    {
      name: "Request Id",
      selector: (row) => (row.request_id ? row.request_id : "NOT AVAILABLE"),
      sortable: true,
      // width: "200px",
      width: "auto",
    },
    {
      name: "Customer Name",
      selector: (row) =>
        row.customerName ? row.customerName.toUpperCase() : "NOT AVAIALABLE",
      sortable: true,
      width: "auto",
    },
    {
      name: "Smart Card No",
      selector: (row) =>
        row.smartcardNumber ? row.smartcardNumber : "NOT AVAIALABLE",
      sortable: true,
      width: "auto",
    },
    {
      name: "Type",
      selector: (row) => (row.type ? row.type.toUpperCase() : "NOT AVAILABLE"),
      sortable: true,
      width: "auto",
    },
    {
      name: "Amount",
      selector: (row) => (row.amount ? CurrencyFormatter(row.amount) : "0.00"),
      sortable: true,
      width: "auto",
    },

    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => {
        // Determine the background color based on the status value
        let backgroundColor;
        let textColor;

        switch (row.status) {
          case "1":
            backgroundColor = "bg-darkGreen3"; // Successful
            textColor = "text-dGrey3";
            break;
          case "2":
            backgroundColor = "bg-dash3"; // Failed
            textColor = "text-dGrey3";
            break;
          case "0":
          case "3":
            backgroundColor = "bg-dash5"; // Pending
            textColor = "text-dGrey3";
            break;
          default:
            backgroundColor = "bg-grey1";
            textColor = "text-dGrey3";
        }

        return (
          <div className={`p-1 rounded ${backgroundColor} ${textColor}`}>
            {row.status === "1"
              ? "Successful"
              : row.status === "2"
              ? "Failed"
              : "Pending"}
          </div>
        );
      },
      width: "120px",
    },

    {
      name: "Created At",
      selector: (row) =>
        row.created_at ? formatDate(row.created_at) : "NOT AVAILABLE",
      sortable: true,
      width: "auto",
    },
  ];

  // Function to filter products based on input
  const getFilteredItems = (products, filterText) => {
    return products.filter((item) => {
      const matchesFilterText = Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(filterText.toLowerCase())
      );
      return matchesFilterText;
    });
  };

  return (
    <MainLayouts>
      <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
        <section
          className="w-full pl-[3%] pr-[3%] pt-1 my-1"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          <div className="flex justify-between items-center">
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Bills Product
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1">List</p>
                </li>
              </ol>
            </nav>
            <button
              id="menu-button"
              className="bg-darkGreen3 inline-flex justify-center gap-x-1.5 rounded-md px-3 py-3  my-3 text-sm font-semibold text-lGrey3 shadow-sm ring-1 ring-inset hover:bg-gray-50"
              onClick={() => setIsDropdownOpen((prev) => !prev)}
            >
              {selectedProductBillsType.toUpperCase()} Products
              <ChevronRightIcon
                className={`${
                  isDropdownOpen ? "rotate-90 transform" : ""
                } h-5 w-5`}
                aria-hidden="true"
              />
            </button>
          </div>
        </section>

        <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap items-center justify-between gap-4">
          <div className="justify-end flex-shrink-0 w-full sm:w-6/12 ">
            {isDropdownOpen && (
              <ul
                className="absolute z-10 mt-2 w-full bg-dGrey3 border border-lGrey rounded-md shadow-lg"
                role="menu"
              >
                {["airtime", "data", "disco", "tv"].map((type) => (
                  <li key={type}>
                    <button
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 w-full text-left hover:bg-orange1"
                      onClick={() => handleCustomerTypeSelect(type)}
                    >
                      {type.charAt(0).toUpperCase() + type.slice(1)} Products
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        {loading ? (
          <Spinner />
        ) : (
          <>
            {selectedProductBillsType === "airtime" && (
              <DataTable
                customStyles={{
                  headRow: {
                    style: {
                      backgroundColor: "#1D9E34",
                    },
                  },
                  headCells: {
                    style: {
                      color: "white",
                    },
                  },
                }}
                columns={airtimeColumns}
                data={filteredAirtimeProducts}
                pagination
                striped
                responsive
                highlightOnHover
                paginationResetDefaultPage={resetPaginationToggle} // Reset pagination when filtering
                noDataComponent="No Airtime Products Found"
                subHeader
                subHeaderComponent={
                  <input
                    type="text"
                    placeholder="Airtime Products"
                    value={filterTextAirtime}
                    onChange={(e) => handleFilter(e, "airtime")}
                    className="justify-end w-3/5 px-3 py-2 text-md border border-gray-300 rounded-lg  "
                  />
                }
              />
            )}
            {selectedProductBillsType === "data" && (
              <DataTable
                customStyles={{
                  headRow: {
                    style: {
                      backgroundColor: "#FFA439",
                    },
                  },
                  headCells: {
                    style: {
                      color: "white",
                    },
                  },
                }}
                columns={dataColumns}
                data={filteredDataProducts}
                pagination
                subHeader
                subHeaderComponent={
                  <input
                    type="text"
                    placeholder="Filter Data Products"
                    value={filterTextData}
                    onChange={(e) => handleFilter(e, "data")}
                    className="justify-end w-3/5 px-3 py-2 text-md border border-gray-300 rounded-lg"
                  />
                }
                striped
                responsive
                highlightOnHover
                paginationResetDefaultPage={resetPaginationToggle} // Reset pagination when filtering
                noDataComponent="No Data Products Found"
              />
            )}
            {selectedProductBillsType === "disco" && (
              <DataTable
                customStyles={{
                  headRow: {
                    style: {
                      backgroundColor: "#4373fc",
                    },
                  },
                  headCells: {
                    style: {
                      color: "white",
                    },
                  },
                }}
                columns={discoColumns}
                data={filteredDiscoProducts}
                pagination
                subHeader
                subHeaderComponent={
                  <input
                    type="text"
                    placeholder="Filter Disco Products"
                    value={filterTextDisco}
                    onChange={(e) => handleFilter(e, "disco")}
                    className="justify-end w-3/5 px-3 py-2 text-md border border-gray-300 rounded-lg  "
                  />
                }
                striped
                responsive
                highlightOnHover
                paginationResetDefaultPage={resetPaginationToggle} // Reset pagination when filtering
                noDataComponent="No Disco Products Found"
              />
            )}
            {selectedProductBillsType === "tv" && (
              <DataTable
                customStyles={{
                  headRow: {
                    style: {
                      backgroundColor: "#313736",
                    },
                  },
                  headCells: {
                    style: {
                      color: "white",
                    },
                  },
                }}
                columns={tvColumns}
                data={filteredTvProducts}
                // customStyles={CustomStyles}
                pagination
                subHeader
                subHeaderComponent={
                  <input
                    type="text"
                    placeholder="Filter Tv Products"
                    value={filterTextTv}
                    onChange={(e) => handleFilter(e, "tv")}
                    className="justify-end w-3/5 px-3 py-2 text-md border border-gray-300 rounded-lg  "
                  />
                }
                paginationResetDefaultPage={resetPaginationToggle}
                noDataComponent="No Tv Products Found"
              />
            )}
          </>
        )}
      </div>
    </MainLayouts>
  );
};

export default GetBillsProducts;
