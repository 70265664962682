

import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as PlusSquareFill } from "bootstrap-icons/icons/plus-square-fill.svg";
import Spinner from "../spinner/Spinner";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import { ReactComponent as Trash } from "bootstrap-icons/icons/trash3.svg";
import Swal from "sweetalert2";
import { handleError } from "../../components/utils/AuthenticationCheck";


const AllSliders = () => {
    const [sliders, setSliders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [confirmationLoading, setConfirmationLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [imagesPerPage] = useState(6);

    const navigateTo = useNavigate();

    useEffect(() => {
        const fetchHomePageSlider = async () => {
            setLoading(true);
            try {
                const authData = JSON.parse(localStorage.getItem("auth"));
                const token = authData?.token;
                if (!token) {
                    throw new Error("No access token found.");
                }
                const response = await axios.get(ApiServices.AllSlider, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                });

                // console.log("API Response for image slider:", response.data);
                const { status, message, data } = response.data;

                if (status === 200) {
                    setSliders(data); // Set sliders directly from API response
                    toast.success(message, { toastId: "product-details-fetch-success" });
                } else {
                    handleError({ response: { status, data: { message } } }, navigateTo);
                }
            } catch (error) {
                handleError(error, navigateTo);
            } finally {
                setLoading(false);
            }
        };

        fetchHomePageSlider();
    }, [navigateTo]);

    const handleDeleteProduct = async (slider_id) => {
        const formData = { slider_id :slider_id };
        setLoading(true);
        try {
            const authData = JSON.parse(localStorage.getItem("auth"));
            const token = authData?.token;
            if (!token) {
                throw new Error("No access token found.");
            }

            const response = await axios.delete(ApiServices.deleteSlider, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                data: formData,
                withCredentials: true,
            });

            const { status, message } = response.data;
            if (status === 200) {
                setSliders((prevSliders) => prevSliders.filter((slider) => slider.id !== slider_id));
                toast.success(message, { toastId: "slider-deleted-successfully" });
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteConfirmation = (slider_id) => {
        setConfirmationLoading(true);
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            setConfirmationLoading(false);
            if (result.isConfirmed) {
                handleDeleteProduct(slider_id);
            }
        });
    };

    // Pagination logic
    const indexOfLastImage = currentPage * imagesPerPage;
    const indexOfFirstImage = indexOfLastImage - imagesPerPage;
    const currentImages = sliders.slice(indexOfFirstImage, indexOfLastImage);
    const hasNextPage = indexOfLastImage < sliders.length;
    const hasPrevPage = currentPage > 1;

    return (
        <div>
            <MainLayouts>
                <div className="relative sm:rounded-lg py-6 w-full">
                    <section className="w-full pr-[3%] pt-3 my-3" style={{ backgroundColor: "#F7F7F7" }}>
                        <nav className="bg-gray-200 py-0 px-4 rounded-lg">
                            <ol className="list-none p-0 inline-flex mb-2">
                                <li className="flex items-center">
                                    <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                                        Sliders
                                    </p>
                                    <ChevronRightIcon />
                                </li>
                                <li className="flex items-center">
                                    <p className="text-orange1">List</p>
                                </li>
                            </ol>
                        </nav>
                    </section>

                    <div className="pb-1 bg-white dark:bg-gray-900 flex flex-wrap justify-end">

                        <Link
                            to="/admin/slider/create"
                            className="m-1 w-full sm:w-auto text-dGrey3 bg-orange1 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                            <span className="flex justify-center sm:justify-start mx-2">
                                <PlusSquareFill className="mr-2" />
                                Add New Image Slider
                            </span>
                        </Link>
                    </div>

                    {loading ? (
                        <Spinner loading={loading} />
                    ) : (
                        <section className="text-gray-600 body-font">
                            <div className="container">
                                <div className="grid grid-cols-12 gap-4">
                                    <div className="col-span-12 lg:col-span-12 p-2">
                                        <div className="grid grid-cols-2 sm:grid-cols-6 gap-4 mt-4">
                                            {currentImages.length > 0 ? (
                                                currentImages.map((slider) => (
                                                    <div key={slider.id} className="overflow-hidden shadow-md bg-dGrey3">
                                                        <div className="h-full flex items-center border-dGrey3 border p-1.5 rounded-lg">
                                                            <div className="flex-grow">
                                                                <h2 className="flex items-center justify-between text-darkGreen3 title-font font-medium">
                                                                    <small className="text-darkGreen3">{slider.name}</small>
                                                                    <span className="flex">
                                                                        <Link
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                handleDeleteConfirmation(slider.id);
                                                                            }}
                                                                            disabled={confirmationLoading}
                                                                            className="text-dash5 mr-2 border border-dGrey3 rounded-md p-2"
                                                                        >
                                                                            <Trash className="w-5 h-5 text-dash3" />
                                                                        </Link>
                                                                    </span>
                                                                </h2>
                                                                <img
                                                                    src={slider.value}
                                                                    alt={slider.name}
                                                                    className="w-full h-32 object-cover p-4"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="col-span-full text-center">
                                                    No images available.
                                                </div>
                                            )}
                                        </div>

                                        {/* Pagination Controls */}
                                        <div className="flex justify-end mt-6">
                                            <nav aria-label="Page navigation">
                                                <ul className="pagination flex mx-auto">
                                                    <li className={`page-item ${!hasPrevPage ? "disabled" : ""}`}>
                                                        <button
                                                            className="text-dGrey3 bg-black1 hover:bg-darkGreen2 font-medium rounded-full text-sm px-5 py-2"
                                                            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                                            disabled={!hasPrevPage}
                                                        >
                                                            Prev
                                                        </button>
                                                    </li>
                                                    <li className={`page-item ${!hasNextPage ? "disabled" : ""}`}>
                                                        <button
                                                            className="text-dGrey3 bg-black1 hover:bg-darkGreen2 font-medium rounded-full text-sm px-5 py-2"
                                                            onClick={() => setCurrentPage((prev) => prev + 1)}
                                                            disabled={!hasNextPage}
                                                        >
                                                            Next
                                                        </button>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </MainLayouts>
        </div>
    );
};

export default AllSliders;

