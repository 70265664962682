import React, { useState, useEffect } from "react";
import MainLayouts from "../../../Layout/MainLayouts";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import Spinner from "../../spinner/Spinner";
import { handleError } from "../../../components/utils/AuthenticationCheck";
import axios from "axios";
import ApiServices from "../../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import CustomStyles from "../../../components/utils/CustomStyles";
import { useNavigate } from "react-router-dom";

const GetAllProducts = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filterText, setFilterText] = useState("");
    const navigateTo = useNavigate();

    // Fetch product list
    const fetchProductList = async () => {
        setLoading(true);
        try {
            const authData = JSON.parse(localStorage.getItem("auth"));
            const token = authData?.token?.split("|")[1];
            if (!token) throw new Error("No access token found.");

            const response = await axios.post(
                ApiServices.getBillsProductsForSwitch,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );
            // console.log("respponse from bill peoduct", response.data);
            const { status, message, data } = response.data;
            if (status === 200) {
                setProducts(data);
                toast.success(message, { toastId: "bills-product-fetch-success" });
            } else {
                handleError({ response: { status, data: { message } } }, navigateTo);
            }
        } catch (error) {
            handleError(error, navigateTo);
        } finally {
            setLoading(false);
        }
    };

    // Update product status
    const handleUpdateStatus = async (row, newStatus) => {
        setLoading(true);
        try {
            const authData = JSON.parse(localStorage.getItem("auth"));
            const token = authData?.token?.split("|")[1];
            if (!token) throw new Error("No access token found.");
            // 1 for activate, 0 for deactivate
            const statusValue = newStatus === "1" ? "activate" : "deactivate";

            const response = await axios.post(
                ApiServices.changeProductStatus,
                { id: row.id, status: statusValue },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );

            const { status, message } = response.data;
            if (status === 200) {
                toast.success(message, { toastId: "product-status-change-success" });
                // Refetch the product list after status change
                fetchProductList();
            } else {
                handleError({ response: { status, data: { message } } }, navigateTo);
            }
        } catch (error) {
            handleError(error, navigateTo);
        } finally {
            setLoading(false);
        }
    };

    // Filter products by name
    const handleFilter = (e) => {
        setFilterText(e.target.value);
    };

    // Columns definition
    const columns = [
        {
            name: "Change Product Status",
            cell: (row) => (
                <select
                    value={row.status}
                    onChange={(e) => handleUpdateStatus(row, e.target.value)}
                    className="mx-2 my-2  px-2 py-2  text-darkGreen3 border rounded-md"
                >
                    <option value="1">Active</option>
                    <option value="0">Disabled</option>
                </select>
            ),
            width: "200px",
        },
        {
            name: "Product Name",
            selector: (row) => (row.name ? row.name.toUpperCase() : "NOT AVAILABLE"),
            sortable: true,
            width: "300px",
        },
        {
            name: "Status",
            sortable: true,
            width: "110px",
            cell: (row) => (
                <span
                    className={`px-3 py-0.5 rounded-md text-xs ${row.status === "0" ? "text-red1" : "text-dash5"
                        }`}
                >
                    {row.status === "0" ? "Disabled" : "Active"}
                </span>
            ),
        },
    ];

    // Filtered and sorted data
    const filteredItems = products.filter((item) =>
        Object.values(item).some((value) =>
            typeof value === "string" && value.toLowerCase().includes(filterText.toLowerCase())
        )
    );

    const data = filteredItems.map((product) => ({
        name: product.name ? product.name.toUpperCase() : "NOT AVAILABLE",
        status: product.status ? product.status : "NOT AVAILABLE",
        id: product.id,
    }));

    useEffect(() => {
        fetchProductList(); // Fetch products on component mount
    }, []);

    return (
        <MainLayouts>
            <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
                <section className="w-full pr-[3%] pt-3 my-3" style={{ backgroundColor: "#F7F7F7" }}>
                    <nav className="bg-gray-200 py-0 px-4 rounded-lg">
                        <ol className="list-none p-0 inline-flex mb-2">
                            <li className="flex items-center">
                                <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">Bills Products</p>
                                <ChevronRightIcon />
                            </li>
                            <li className="flex items-center">
                                <p className="text-orange1">All</p>
                            </li>
                        </ol>
                    </nav>
                </section>
                <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap justify-between">
                    <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
                        <input
                            type="search"
                            className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                            placeholder="Search"
                            value={filterText}
                            onChange={handleFilter}
                        />
                    </div>
                </div>
                {loading ? (
                    <Spinner loading={loading} />
                ) : (
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        striped
                        responsive
                        highlightOnHover
                        noDataComponent={<div>No products found</div>}
                        customStyles={CustomStyles}

                    />
                )}
            </div>
        </MainLayouts>
    );
};

export default GetAllProducts;
