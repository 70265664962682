import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as ArrowLeftSquare } from "bootstrap-icons/icons/arrow-left-square-fill.svg";
import Spinner from "../spinner/Spinner";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import { toast } from "react-toastify";
import formatDate from "../../components/utils/DateFormatter";

const RidersDetails = () => {
    const location = useLocation();
    const riders = location.state?.riders;

    // console.log("riders details is here", riders);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (riders) {
            setLoading(false);
        } else {
            toast.error("No rider details found.");
        }
    }, [riders]);

    if (!riders) {
        return <div>No riders details found.</div>;
    }

    // Destructure rider details
    const {
        firstname,
        lastname,
        status,
        nin,
        vehicle_model,
        vehicle_plate_number,
        email,
        phone_number,
        address,
        created_at,
    } = riders;

    return (
        <div>
            <MainLayouts>
                <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
                    <section className="w-full pl-[3%] pr-[3%] pt-3 my-3" style={{ backgroundColor: "#F7F7F7" }}>
                        <nav className="bg-gray-200 py-0 px-4 rounded-lg ">
                            <ol className="list-none p-0 inline-flex mb-2">
                                <li className="flex items-center">
                                    <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                                        Riders
                                    </p>
                                    <ChevronRightIcon />
                                </li>
                                <li className="flex items-center">
                                    <p className="text-orange1 ">Details</p>
                                </li>
                            </ol>
                        </nav>
                    </section>

                    {loading ? (
                        <Spinner loading={loading} />
                    ) : (
                        <section className="text-gray-600 body-font">
                            <div className="container px-1 py-1 mx-auto">
                                <div className="flex flex-wrap -m-2">
                                    <div className="p-2 w-full">
                                        <div className="h-full flex border-grey3 border items-center p-4 rounded-lg">
                                            {/* Optional Profile Photo */}
                                            {/* <img
                                                alt="Rider"
                                                className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                                                src={`https://ui-avatars.com/api/?name=${encodeURIComponent(firstname || "Not Available")}`}
                                            /> */}
                                            <div className="flex-grow hidden md:block ">
                                                <h2 className="text-dash title-font font-medium uppercase">
                                                    {lastname && firstname ? `${lastname} ${firstname}` : "Not Available"}
                                                </h2>
                                                <p className="text-gray-500 hidden md:block ">
                                                    <span className="text-gray-500">Status: </span>
                                                    <span className="text-dash">{status || "Not Available"}</span>
                                                </p>
                                                <p className="text-gray-500 hidden md:block ">
                                                    <span className="text-gray-500">NIN: </span>
                                                    <span className="text-dash">{nin || "Not Available"}</span>
                                                </p>
                                                <p className="text-gray-500 hidden md:block ">
                                                    <span className="text-gray-500">Registered Date: </span>
                                                    <span className="text-dash">{formatDate(created_at) || "Not Available"}</span>
                                                </p>
                                            </div>
                                            <Link
                                                to="/admin/riders/list"
                                                className="w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                            >
                                                <span className="flex justify-center sm:justify-start mx-2">
                                                    <ArrowLeftSquare className="mr-2" />
                                                    Back
                                                </span>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="px-2 w-full">
                                        <div className="h-full flex items-center border-dash border p-2 rounded-lg border-l-4">
                                            <div className="w-full pb-6">
                                                <div className="flex border-gray-200 py-2">
                                                    <span className="text-gray-500">Bike Model</span>
                                                    <span className="ml-auto text-dash">{vehicle_model || "Not Available"}</span>
                                                </div>
                                                <div className="flex border-t border-grey3 py-2">
                                                    <span className="text-gray-500">Bike Plate Number</span>
                                                    <span className="ml-auto text-dash">{vehicle_plate_number || "Not Available"}</span>
                                                </div>
                                                <div className="flex border-t border-grey3 py-2">
                                                    <span className="text-gray-500">Email Address</span>
                                                    <span className="ml-auto text-dash">{email || "Not Available"}</span>
                                                </div>
                                                <div className="flex border-t border-grey3 py-2">
                                                    <span className="text-gray-500">Phone</span>
                                                    <span className="ml-auto text-dash">{phone_number || "Not Available"}</span>
                                                </div>

                                                <div className="flex border-t border-grey3 py-2">
                                                    <span className="text-gray-500">Address</span>
                                                    <span className="ml-auto text-dash">{address || "Not Available"}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </MainLayouts>
        </div>
    );
};

export default RidersDetails;
