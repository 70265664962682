

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ChevronDownIcon } from "bootstrap-icons/icons/chevron-down.svg";

const MobileDropdownNavbar = () => {
  const [openCustomer, setOpenCustomer] = useState(false);
  const [openGeneral, setOpenGeneral] = useState(false);
  const [openOrders, setOpenOrders] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);
  const [openBills, setOpenBills] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openRiders, setOpenRiders] = useState(false);

  return (
    <aside className="flex-1 space-y-1 md:hidden">
      <p className="block p-2 text-xl font-medium tracking-tighter text-darkGreen3 transition duration-500 ease-in-out transform cursor-pointer hover:text-gray-900 mx-auto mb-3">
        Dashboard
      </p>

      <hr className="border border-lGrey" />
      {/* Customer Section */}
      <div className="rounded-lg">
        <button
          className="flex justify-between w-full px-4 py-2 text-left bg-gray-200 rounded-t-lg focus:outline-none"
          onClick={() => setOpenCustomer(!openCustomer)}
        >
          <span className="text-black2">Customer</span>
          <ChevronDownIcon
            className={`transform transition-transform ${openCustomer ? "rotate-180" : "rotate-0"
              }`}
          />
        </button>
        {openCustomer && (
          <div className="p-4 bg-white rounded-b-lg">
            <ul>
              <li>
                <Link
                  to="/admin/customer"
                  className="text-grey3 hover:text-darkGreen3 inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline"
                >
                  <span>Customers</span>
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
      <hr className="border border-lGrey" />

      {/* General Section */}
      <div className="rounded-lg">
        <button
          className="flex justify-between w-full px-4 py-2 text-left bg-gray-200 rounded-t-lg focus:outline-none"
          onClick={() => setOpenGeneral(!openGeneral)}
        >
          <span className="text-black2">General</span>
          <ChevronDownIcon
            className={`transform transition-transform ${openGeneral ? "rotate-180" : "rotate-0"
              }`}
          />
        </button>
        {openGeneral && (
          <div className="p-4 bg-white rounded-b-lg">
            <ul>

              <li>
                <Link
                  to="/admin/products"
                  className="text-grey3 hover:text-darkGreen3 inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline"
                >
                  <span>Vendors Product</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/products/stock/list"
                  className="text-grey3 hover:text-darkGreen3 inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline"
                >
                  <span>Product Stock</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/product/property/list"
                  className="text-grey3 hover:text-darkGreen3 inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline"
                >
                  <span>Property</span>
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/admin/category/list"
                  className="text-grey3 hover:text-darkGreen3 inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline"
                >
                  <span>Categories</span>
                </Link>
              </li> */}
            </ul>
          </div>
        )}
      </div>
      <hr className="border border-lGrey" />

      {/* Orders Section */}
      <div className="rounded-lg">
        <button
          className="flex justify-between w-full px-4 py-2 text-left bg-gray-200 rounded-t-lg focus:outline-none"
          onClick={() => setOpenOrders(!openOrders)}
        >
          <span className="text-black2">Orders</span>
          <ChevronDownIcon
            className={`transform transition-transform ${openOrders ? "rotate-180" : "rotate-0"
              }`}
          />
        </button>
        {openOrders && (
          <div className="p-4 bg-white rounded-b-lg">
            <ul>
              <li>
                <Link
                  to="/admin/order/list"
                  className="text-grey3 hover:text-darkGreen3 inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline"
                >
                  <span>Order</span>
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/admin/return/items"
                  className="text-grey3 hover:text-darkGreen3 inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline"
                >
                  <span>Return</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/fulfilled"
                  className="text-grey3 hover:text-darkGreen3 inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline"
                >
                  <span>Fulfilled</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/help/pending"
                  className="text-grey3 hover:text-darkGreen3 inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline"
                >
                  <span>Help Pending</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/help/center"
                  className="text-grey3 hover:text-darkGreen3 inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline"
                >
                  <span>Help Center</span>
                </Link>
              </li> */}
            </ul>
          </div>
        )}
      </div>
      <hr className="border border-lGrey" />

      {/* Admin Section */}
      <div className="rounded-lg">
        <button
          className="flex justify-between w-full px-4 py-2 text-left bg-gray-200 rounded-t-lg focus:outline-none"
          onClick={() => setOpenAdmin(!openAdmin)}
        >
          <span className="text-black2">Admin</span>
          <ChevronDownIcon
            className={`transform transition-transform ${openAdmin ? "rotate-180" : "rotate-0"
              }`}
          />
        </button>
        {openAdmin && (
          <div className="p-4 bg-white rounded-b-lg">
            <ul>
              <li>
                <Link
                  to="/admin/view/list"
                  className="text-grey3 hover:text-darkGreen3 inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline"
                >
                  <span>Admins</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/permission/list"
                  className="text-grey3 hover:text-darkGreen3 inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline"
                >
                  <span>Permissions</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/roles/list"
                  className="text-grey3 hover:text-darkGreen3 inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline"
                >
                  <span>Roles</span>
                </Link>
              </li>

              <li>
                <Link
                  to="/admin/activity/log"
                  className="text-grey3 hover:text-darkGreen3 inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline"
                >
                  <span>Activity Log</span>
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
      <hr className="border border-lGrey" />

      {/* Bills Section */}
      <div className="rounded-lg">
        <button
          className="flex justify-between w-full px-4 py-2 text-left bg-gray-200 rounded-t-lg focus:outline-none"
          onClick={() => setOpenBills(!openBills)}
        >
          <span className="text-black2">Bills</span>
          <ChevronDownIcon
            className={`transform transition-transform ${openBills ? "rotate-180" : "rotate-0"
              }`}
          />
        </button>
        {openBills && (
          <div className="p-4 bg-white rounded-b-lg">
            <ul>
              <li>
                <Link
                  to="/admin/product/list"
                  className="text-grey3 hover:text-darkGreen3 inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline"
                >
                  <span>Airtime</span>
                </Link>
              </li>

              <li>
                <Link
                  to="/admin/billers/list"
                  className="text-grey3 hover:text-darkGreen3 inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline"
                >
                  <span>Billers</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/switchers/list"
                  className="text-grey3 hover:text-darkGreen3 inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline"
                >
                  <span>Switchers</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/product/all/list"
                  className="text-grey3 hover:text-darkGreen3 inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline"
                >
                  <span>Products</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/bills/search"
                  className="text-grey3 hover:text-darkGreen3 inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline"
                >
                  <span>Search Bills</span>
                </Link>
              </li>

            </ul>
          </div>
        )}
      </div>
      <hr className="border border-lGrey" />

      {/* Riders Section */}
      <div className="rounded-lg">
        <button
          className="flex justify-between w-full px-4 py-2 text-left bg-gray-200 rounded-t-lg focus:outline-none"
          onClick={() => setOpenRiders(!openRiders)}
        >
          <span className="text-black2">Riders</span>
          <ChevronDownIcon
            className={`transform transition-transform ${openRiders ? "rotate-180" : "rotate-0"
              }`}
          />
        </button>
        {openRiders && (
          <div className="p-4 bg-white rounded-b-lg">
            <ul>
              <li>
                <Link
                  to="/admin/riders/list"
                  className="text-grey3 hover:text-darkGreen3 inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline"
                >
                  <span>Riders</span>
                </Link>
              </li>

            </ul>
          </div>
        )}
      </div>
      <hr className="border border-lGrey" />

      {/* Settings Section */}
      <div className="rounded-lg">
        <button
          className="flex justify-between w-full px-4 py-2 text-left bg-gray-200 rounded-t-lg focus:outline-none"
          onClick={() => setOpenSettings(!openSettings)}
        >
          <span className="text-black2">Settings</span>
          <ChevronDownIcon
            className={`transform transition-transform ${openSettings ? "rotate-180" : "rotate-0"
              }`}
          />
        </button>
        {openSettings && (
          <div className="p-4 bg-white rounded-b-lg">
            <ul>
              <li>
                <Link
                  to="/admin/category/list"
                  className="text-grey3 hover:text-darkGreen3 inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline"
                >
                  <span>Categories</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/help/list"
                  className="text-grey3 hover:text-darkGreen3 inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline"
                >
                  <span>Help Center</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/account/2fa/update/status"
                  className="text-grey3 hover:text-darkGreen3 inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline"
                >
                  <span>2FA Status</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/account/notification/status"
                  className="text-grey3 hover:text-darkGreen3 inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline"
                >
                  <span>Notification</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/password/change"
                  className="text-grey3 hover:text-darkGreen3 inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline"
                >
                  <span>Change Password</span>
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </aside>
  );
};

export default MobileDropdownNavbar;
