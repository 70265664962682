import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import { ReactComponent as ArrowLeftCircleLeftFill } from "bootstrap-icons/icons/arrow-left-circle-fill.svg";
import { ReactComponent as ArrowRightCircleLeftFill } from "bootstrap-icons/icons/arrow-right-circle-fill.svg";
import { ReactComponent as EyeFill } from "bootstrap-icons/icons/eye-fill.svg";
import { ReactComponent as PencilSquare } from "bootstrap-icons/icons/pencil-square.svg";
import { ReactComponent as CardList } from "bootstrap-icons/icons/card-list.svg";
import { ReactComponent as PersonAdd } from "bootstrap-icons/icons/person-add.svg";
import { ReactComponent as PersonDashFill } from "bootstrap-icons/icons/person-dash-fill.svg";
import { ReactComponent as PersonCheckFill } from "bootstrap-icons/icons/person-check-fill.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import { handleError } from "../../components/utils/AuthenticationCheck";
import Swal from "sweetalert2";

const RolesList = () => {
  const [roles, setRoles] = useState([]);
  const [role_id, setRole_id] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rolesPerPage] = useState(12);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);

  const navigateTo = useNavigate();

  useEffect(() => {
    const fetchAllRoles = async () => {
      setLoading(true);
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) {
          throw new Error("No access token found.");
        }
        const response = await axios.get(ApiServices.getRoles, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        const { status, message, data } = response.data;
        // console.log(data);
        if (status === 200) {
          setRoles(data);
          setRole_id(data.role_id);
          toast.success(message, { toastId: "roles-fetch-success" });
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setLoading(false);
      }
    };

    fetchAllRoles();
  }, []);

  const handleFilter = (e) => {
    setFilterText(e.target.value);
    setResetPaginationToggle(!resetPaginationToggle); // Reset pagination on filter change
  };

  // Filter roles based on the input text
  const filteredRoles = roles.filter((role) =>
    role.name.toLowerCase().includes(filterText.toLowerCase().trim())
  );

  // Pagination logic
  const indexOfLastRole = currentPage * rolesPerPage;
  const indexOfFirstRole = indexOfLastRole - rolesPerPage;
  const currentRoles = filteredRoles.slice(indexOfFirstRole, indexOfLastRole);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const hasNextPage = () => indexOfLastRole < filteredRoles.length;
  const hasPrevPage = () => currentPage > 1;

  // Handle delete roles
  const handleDeleteRole = async (roleId) => {
    // Modified parameter name
    const formData = { role_id: roleId };
    // console.log("role form data", formData);
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }

      const response = await axios.delete(ApiServices.deleteRoles, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: formData,
        withCredentials: true,
      });

      const { status, message } = response.data;
      if (status === 200) {
        // Update roles by filtering out the deleted one
        setRoles(roles.filter((role) => role.role_id !== roleId));
        toast.error(message);
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  // Confirm delete
  const handleDeleteConfirmation = (roleId) => {
    // Modified parameter name
    setConfirmationLoading(true);
    Swal.fire({
      title: "Are you sure want to delete role?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      setConfirmationLoading(false);
      if (result.isConfirmed) {
        handleDeleteRole(roleId);
      }
    });
  };

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <section
            className="w-full pl-[3%] pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg ">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Roles
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1">List</p>
                </li>
              </ol>
            </nav>
          </section>

          {/* Flex Container for Search and Button */}
          <div className="pb-4 bg-white dark:bg-gray-900 flex items-center justify-between gap-4">
            {/* Search Input Field */}
            <div className="relative w-full md:w-1/2 mb-4 sm:mb-0">
              <input
                type="search"
                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search"
                value={filterText}
                onChange={handleFilter}
              />
            </div>
            {/* Create Role Link */}
            <div className="relative w-full md:w-auto flex-shrink-0">
              <Link
                to="/admin/roles/create"
                className="bg-orange1 inline-flex w-full md:w-auto justify-center gap-x-1.5 rounded-md px-3 py-3 text-sm font-semibold text-lGrey3"
              >
                Create Role
              </Link>
            </div>
          </div>

          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <section className="text-gray-600 body-font">
              <div className="container px-1 py-2 mx-auto">
                <div className="flex flex-wrap -m-2">
                  {currentRoles.length > 0 ? (
                    currentRoles.map((role, index) => (
                      <div key={index} className="p-2 lg:w-1/3 md:w-1/2 w-full">
                        <div className="h-full flex items-center border-darkGreen3 border p-4 rounded-lg">
                          <div className="flex-grow">
                            <h2 className="text-darkGreen3 title-font font-medium">
                              {role.name}
                            </h2>
                            {/* <div className="mt-2">
                              <Link
                                to="/admin/roles/view/permission"
                                state={{ role: role }}
                                className="text-dash5 hover:text-darkGreen4"
                              >
                                <CardList className="w-7 h-7 inline-block mr-2" />
                                View Permission
                              </Link>
                            </div> */}
                            <div className="flex justify-between mt-4 space-x-4">
                              <div className="relative group">
                                <Link
                                  to="/admin/roles/update"
                                  state={{ role }}
                                  className="text-darkGreen3 hover:text-darkGreen4"
                                >
                                  <PencilSquare className="w-5 h-5" />
                                </Link>
                                <div className="absolute top-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block text-darkGreen3 text-xs rounded py-1 px-2 whitespace-nowrap">
                                  Update Role
                                </div>
                              </div>
                              <div className="relative group">
                                <Link
                                  to="/admin/roles/assign/permission"
                                  state={{ role }}
                                  className="text-orange1 hover:text-darkGreen4"
                                >
                                  <PersonAdd className="w-5 h-5" />
                                </Link>
                                <div className="absolute top-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block text-orange1 text-xs rounded py-1 px-2 whitespace-nowrap">
                                  Assign Role To Admin
                                </div>
                              </div>
                              <div className="relative group">
                                <Link
                                  to="/admin/roles/view/permission"
                                  state={{ role }}
                                  className="text-dash5 hover:text-darkGreen4"
                                >
                                  <CardList className="w-5 h-5 inline-block mr-2" />
                                </Link>
                                <div className="absolute top-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block text-dash5 text-xs rounded py-1 px-2 whitespace-nowrap">
                                  View Permission
                                </div>
                              </div>
                              <div className="relative group">
                                <Link
                                  to="/admin/roles/grant/permission"
                                  state={{ role }}
                                  className="text-dash2 hover:text-darkGreen4"
                                >
                                  <PersonCheckFill className="w-5 h-5 inline-block mr-2" />
                                </Link>
                                <div className="absolute top-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block text-dash2 text-xs rounded py-1 px-2 whitespace-nowrap">
                                  Grant Permission To role
                                </div>
                              </div>
                              <div className="relative group">
                                <button
                                  onClick={() =>
                                    handleDeleteConfirmation(role.role_id)
                                  }
                                  className="text-red1 hover:text-darkGreen4"
                                >
                                  <PersonDashFill className="w-5 h-5" />
                                </button>
                                <div className="absolute top-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block text-red1 text-xs rounded py-1 px-2 whitespace-nowrap">
                                  Remove Role
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="w-full text-center text-lg py-5">
                      No Roles Available
                    </div>
                  )}
                </div>
              </div>
            </section>
          )}

          {/* Pagination Controls */}
          <section className="py-3">
            <div className="flex justify-end mt-6">
              <nav aria-label="Page navigation">
                <ul className="pagination flex mx-auto">
                  <li
                    className={`page-item ${!hasPrevPage() ? "disabled" : ""}`}
                  >
                    <button
                      className="text-dGrey3 bg-black1 border-0 py-2 px-5 rounded mr-3"
                      onClick={() => paginate(currentPage - 1)}
                      disabled={!hasPrevPage()}
                    >
                      <span className="flex mx-2 mt-1">
                        <ArrowLeftCircleLeftFill className="w-5 h-5 mr-2" />
                        Prev
                      </span>
                    </button>
                  </li>
                  <li
                    className={`page-item ${!hasNextPage() ? "disabled" : ""}`}
                  >
                    <button
                      className="text-dGrey3 bg-black1 border-0 py-2 px-5 rounded"
                      onClick={() => paginate(currentPage + 1)}
                      disabled={!hasNextPage()}
                    >
                      <span className="flex mx-2 mt-1">
                        <ArrowRightCircleLeftFill className="w-5 h-5 mr-2" />
                        Next
                      </span>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </section>
        </div>
      </MainLayouts>
    </div>
  );
};

export default RolesList;
