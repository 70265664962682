import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as PlusSquareFill } from "bootstrap-icons/icons/plus-square-fill.svg";
import { ReactComponent as PencilSquare } from "bootstrap-icons/icons/pencil-square.svg";
import { ReactComponent as Trash } from "bootstrap-icons/icons/trash3.svg";
import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import CustomStyles from "../../components/utils/CustomStyles";
import { handleError } from "../../components/utils/AuthenticationCheck";
import formatDate from "../../components/utils/DateFormatter";

const FlashSalesList = () => {
  const [flashSales, setFlashSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);

  const navigateTo = useNavigate();

  useEffect(() => {
    const fetchAllFlashList = async () => {
      setLoading(true);
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) {
          throw new Error("No access token found.");
        }
        // console.log("i am token", token);
        const response = await axios.get(ApiServices.getAllFlashsales, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        const { status, message, data } = response.data;
        // console.log("this is all flash sales", response.data);
        // setFlashSales(data);
        if (status === 200) {
          setFlashSales(data);
          toast.success(message, { toastId: "flash-sales-fetch-success" });
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setLoading(false);
      }
    };

    fetchAllFlashList();
  }, []);

  // ====================================delete product =================================================

  // Define toastMixin
  const toastMixin = Swal.mixin({
    toast: true,
    icon: "success",
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  // Define function to handle delete confirmation
  const handleDeleteProduct = async (flash_sale_id) => {
    const formData = {
      flash_sale_id,
    };
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }
      // console.log("i am token",token);
      const response = await axios.delete(
        ApiServices.removeFlashsales,

        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: formData,
          withCredentials: true,
        }
      );

      const updatedProduct = flashSales.filter(
        (flashSales) => flashSales.flash_sale_id !== flash_sale_id
      );

      setFlashSales(updatedProduct);

      const { status, message } = response.data;
      // console.log("this is the deleted items",data);
      if (status === 200 && Array.isArray(data)) {
        toast.error(message);
        return;
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteConfirmation = (flash_sale_id) => {
    setConfirmationLoading(true);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      setConfirmationLoading(false);
      if (result.isConfirmed) {
        handleDeleteProduct(flash_sale_id);
      }
    });
  };

  // ==================================== end of delete product =================================================

  //filter data by name
  const handleFilter = (e) => {
    setFilterText(e.target.value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const columns = [
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex justify-content-start  ">
          <Link
            to="/admin/flash/sales/update"
            state={{ flashSales: row }}
            className="mx-2 my-2 group"
          >
            <PencilSquare className="w-5 h-5 text-dash4 " />
            <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs  whitespace-nowrap ">
              Edit
            </div>
          </Link>

          <Link
            className="mx-2 my-2 group"
            onClick={() => handleDeleteConfirmation(row.flash_sale_id)}
            disabled={confirmationLoading}
          >
            <Trash className="w-5 h-5 text-dash3 " />
            <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs  whitespace-nowrap ">
              Delete flashSales
            </div>
          </Link>
        </div>
      ),
      width: "200px",
    },

    {
      name: "Discount Percentage",
      selector: (row) =>
        row.discount_percentage
          ? `${row.discount_percentage.toUpperCase()}%`
          : "",
      sortable: true,
      width: "300px",
    },

    {
      name: "Start date",
      selector: (row) =>
        row.start_date ? formatDate(row.start_date) : "NOT AVAILABLE",
      sortable: true,
      width: "300px",
    },
    {
      name: "End Date",
      selector: (row) =>
        row.end_date ? formatDate(row.end_date) : "NOT AVAILABLE",
      sortable: true,
      width: "300px",
    },
  ];

  const filteredItems = flashSales.filter((item) => {
    for (let key in item) {
      if (
        typeof item[key] === "string" &&
        item[key].toLowerCase().includes(filterText.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  });

  const data = filteredItems.map((flashSales) => ({
    // created_at: formatDate(flashSales.created_at),
    discount_percentage: flashSales.discount_percentage,
    start_date: flashSales.start_date ? flashSales.start_date : "NOT AVAILABLE",
    end_date: flashSales.end_date ? flashSales.end_date : "NOT AVAILABLE",
    flash_sale_id: flashSales.flash_sale_id,
    stock_id: flashSales.stock_id,
  }));

  // Sort data based on creation date
  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.start_date);
    const dateB = new Date(b.start_date);
    return dateB - dateA;
  });

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          {/* <h1>Product List</h1> */}
          <section
            className="w-full  pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Product Property
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1">List</p>
                </li>
              </ol>
            </nav>
          </section>
          <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap justify-between">
            <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
              <input
                type="search"
                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search"
                value={filterText}
                onChange={handleFilter}
              />
            </div>
            {/* <Link
              to="/admin/addproduct"
              className="w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <span className="flex justify-center sm:justify-start mx-2">
                <PlusSquareFill className="mr-2" />
                Create Flash Sales
              </span>
            </Link> */}
          </div>
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <DataTable
              columns={columns}
              data={sortedData}
              pagination
              striped
              responsive
              highlightOnHover
              noDataComponent={<div>No flash sales found</div>}
              customStyles={CustomStyles}
              style={{ width: "100%" }}
            />
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default FlashSalesList;
