// import { Navigate } from "react-router-dom";
// import { useContext } from "react";
// import { AuthContext } from "./AuthProvider";

// const PrivateRoute = ({ children }) => {
//   const { auth } = useContext(AuthContext);

//   // console.log("Auth state in PrivateRoute:", auth); 

//   return auth ? children : <Navigate to="/" replace />;
// };

// export default PrivateRoute;


// import { Navigate } from "react-router-dom";
// import { useContext } from "react";
// import { AuthContext } from "./AuthProvider";
// import { toast } from "react-toastify";

// const PrivateRoute = ({ children, requiredPermissions }) => {
//   const { auth, permissions } = useContext(AuthContext);

  

//   if (!auth?.token) {
//     // Redirect to login if not authenticated
//     return <Navigate to="/" />;
//   }

//   if (requiredPermissions) {
//     // Check if the user has the required permissions for this route
//     const hasPermissions = requiredPermissions.every(permission =>
//       permissions.includes(permission)
//     );

//     if (!hasPermissions) {
//       // Show a toast notification if the user lacks permissions
//       toast.info("You do not have permission to access this page.");
//       return <Navigate to="/admin/dashboard" />; 
//     }
//   }

//   return children;
// };


// export default PrivateRoute;

import { Navigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "./AuthProvider";
import { toast } from "react-toastify";

const PrivateRoute = ({ children, requiredPermissions }) => {
  const { auth, permissions } = useContext(AuthContext);

  // console.log("Auth:", auth);
  // console.log("Permissions in AuthContext:", permissions);
  // console.log("Required Permissions for this route:", requiredPermissions);
  

  if (!auth?.token) {
    // Redirect to login if not authenticated
    return <Navigate to="/" />;
  }


 
  if (requiredPermissions) {
    // Assuming permissions in AuthContext are objects with "name"
    const userPermissions = permissions.map((perm) => perm.name);
    // console.log("User Permissions:", userPermissions);

    // Check if the user has the required permissions for this route
    const hasPermissions = requiredPermissions.every(permission =>
      userPermissions.includes(permission)
    );

    if (!hasPermissions) {
      // Show a toast notification if the user lacks permissions
      toast.info("You do not have permission to access this page.");
      return <Navigate to="/admin/dashboard" />;
    }
  }

  return children;
};

export default PrivateRoute;

