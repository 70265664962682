import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as Trash } from "bootstrap-icons/icons/trash3.svg";
import { ReactComponent as PlusSquare } from "bootstrap-icons/icons/plus-square.svg";
import { ReactComponent as PencilSquare } from "bootstrap-icons/icons/pencil-square.svg";
import { ReactComponent as ArrowLeftSquare } from "bootstrap-icons/icons/arrow-left-square-fill.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import CustomStyles from "../../components/utils/CustomStyles";
import { handleError } from "../../components/utils/AuthenticationCheck";

const ProductCategoryList = () => {
  const [productCategory, setProductCategory] = useState([]);
  // To hold the subCategoryId
  const [subCategoryId, setSubCategoryId] = useState("");
  const [productCategory_id, setProductCategoryId] = useState("");
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const location = useLocation();
  const navigateTo = useNavigate();

  //Initialize subCategoryId from here using use location
  useEffect(() => {
    const { id } = location.state || {};
    if (id) {
      // console.log(id);
      setSubCategoryId(id);
    }
  }, [location.state]);

  // Fetch product categories based on subCategoryId
  useEffect(() => {
    const fetchProductCategoryList = async () => {
      setLoading(true);

      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) {
          throw new Error("No access token found.");
        }

        const response = await axios.get(
          ApiServices.getProductsBySubCategoryId,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            params: { sub_category_id: subCategoryId },
            withCredentials: true,
          }
        );

        const { status, message, data } = response.data;
        //console.log("product category list ",data);
        if (status === 200 && Array.isArray(data)) {
          setProductCategory(data);
          setProductCategoryId(data.product_category_id);
          toast.success(message, { toastId: "product-category-fetch-success" });
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setLoading(false);
      }
    };

    if (subCategoryId) {
      fetchProductCategoryList();
    }
  }, [subCategoryId]);

  // Handle delete category
  const handleDeleteCategory = async (productCategory_id) => {
    // console.log("Deleting category with id:", productCategory_id);

    const formData = { product_category_id: productCategory_id };
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }

      const response = await axios.delete(ApiServices.removeProductsCategory, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: formData,
        withCredentials: true,
      });

      const updatedProductCategory = productCategory.filter(
        (category) => category.product_category_id !== productCategory_id
      );
      setProductCategory(updatedProductCategory);

      const { status, message } = response.data;
      // console.log("this is the deleted items", data);
      if (status === 200 && Array.isArray(data)) {
        toast.error(message);
        return;
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  // Confirm delete
  const handleDeleteConfirmation = (productCategory_id) => {
    setConfirmationLoading(true);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      setConfirmationLoading(false);
      if (result.isConfirmed) {
        handleDeleteCategory(productCategory_id);
      }
    });
  };

  // Filter data by name
  const handleFilter = (e) => {
    setFilterText(e.target.value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const columns = [
    {
      name: "Actions",
      width: "300px",
      cell: (row) => (
        <div className="flex justify-start">
          <Link
            to="/admin/product/category/edit"
            state={{ productCategory: row }}
            className="mx-4 my-2 group"
            style={{ marginLeft: "5px" }}
          >
            <PencilSquare className="w-5 h-5 text-dash4 mr-3" />
            <div className="absolute ml-4 top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
              Edit product category
            </div>
          </Link>

          <Link
            className="mx-4 my-2 group"
            onClick={() => handleDeleteConfirmation(row.product_category_id)}
            style={{ marginLeft: "5px" }}
            data-toggle="tooltip"
            data-placement="bottom"
            title="Delete category"
            disabled={confirmationLoading}
          >
            <Trash className="w-5 h-5 text-dash3 mr-3" />
            <div className="absolute ml-4 top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
              Delete product category
            </div>
          </Link>
        </div>
      ),
    },
    {
      name: "Name",
      selector: (row) => row.name.toUpperCase(),
      sortable: true,
      width: "200px",
    },
    {
      name: "Category Name",
      selector: (row) => row.category_name.toUpperCase(),
      sortable: true,
      width: "200px",
    },
    {
      name: "Sub Category Name",
      selector: (row) => row.sub_category_name.toUpperCase(),
      sortable: true,
      width: "200px",
    },
    {
      name: "Status",
      selector: (row) =>
        row.status ? row.status.toUpperCase() : "NOT AVAILABLE",
      sortable: true,
      cell: (row) => (
        <span className={row.status === "active" ? "text-dash" : "text-dash3"}>
          {row.status ? row.status.toUpperCase() : "NOT AVAILABLE"}
        </span>
      ),
      width: "150px",
    },
  ];

  const filteredItems = productCategory.filter((item) => {
    for (let key in item) {
      if (
        typeof item[key] === "string" &&
        item[key].toLowerCase().includes(filterText.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  });

  const data = filteredItems.map((category) => ({
    sub_category_name: category.sub_category_name || "NOT AVAILABLE",
    status: category.status || "NOT AVAILABLE",
    category_name: category.category_name
      ? category.category_name.toUpperCase()
      : "NOT AVAILABLE",
    sub_category_id: category.sub_category_id,
    category_id: category.category_id,
    name: category.name,
    product_category_id: category.product_category_id,
  }));

  // Sort data based on creation date
  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          {/* <h1>Product Category List</h1> */}
          <section
            className="w-full  pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Category
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Sub Category
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Product Category
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1">List</p>
                </li>
              </ol>
            </nav>
          </section>
          <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap justify-between">
            <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
              <input
                type="search"
                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search"
                value={filterText}
                onChange={handleFilter}
              />
            </div>
            <Link
              to="/admin/products/category/create"
              state={{ id: subCategoryId }}
              className="m-1 w-full sm:w-auto text-dGrey3 bg-orange1 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <span className=" flex justify-center sm:justify-start mx-2">
                <PlusSquare className="mr-2" />
                Create Product Category
              </span>
            </Link>
            <Link
              to="/admin/subcategory/list"
              state={{ id: subCategoryId }}
              className="m-1 w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <span className="flex justify-center sm:justify-start mx-2">
                <ArrowLeftSquare className="mr-2" />
                Back To Sub Category
              </span>
            </Link>
          </div>
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <DataTable
              columns={columns}
              data={sortedData}
              pagination
                striped
              highlightOnHover
              noDataComponent={<div>No Product Category found</div>}
              customStyles={CustomStyles}
            />
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default ProductCategoryList;
