import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { AuthContext } from "./AuthProvider/AuthProvider.jsx";
import Login from "./pages/Auth/Login.jsx";
import TokenLogin from "./pages/Auth/TokenLogin";
import Dashboard from "./pages/dashboard/Dashboard.jsx";
import Customer from "./pages/customer/Customer";
import PageNotFound from "./pages/PageNotFound";
import AddProduct from "./pages/vendorProduct/AddProduct.jsx";
import ProductList from "./pages/vendorProduct/ProductList.jsx";
import CategoryList from "./pages/categories/CategoryList";
import AddCategories from "./pages/categories/AddCategories";
// import AddPayment from "./pages/payment/AddPayment";
import EditProduct from "./pages/vendorProduct/EditProduct.jsx";
import PaymentList from "./pages/payment/PaymentList";
import CustomerDetails from "./pages/customer/CustomerDetails";
import ProductDetails from "./pages/vendorProduct/ProductDetails.jsx";
import PaymentDetails from "./pages/payment/PaymentDetails";
// import ListOfMessages from "./pages/messages/ListOfMessages";
// import MessageDetails from "./pages/messages/MessageDetails";
import ReturnList from "./pages/return/ReturnList";
// import CategoryDetails from "./pages/categories/CategoryDetails";
import UpdateCategory from "./pages/categories/UpdateCategory";
import ReturnItemDetails from "./pages/return/ReturnItemDetails";
import FulfiledList from "./pages/fulfiled/FulfiledList";
import ErrorPage from "./pages/ErrorPage";
// import HelpPending from "./pages/HelpCenter/HelpPending";
// import HelpPendingDetails from "./pages/HelpCenter/HelpPendingDetails";
import Faq from "./pages/faq/Faq";
import ListOfFaq from "./pages/faq/ListOfFaq";
// import ChangePassword from "./pages/Auth/password/ChangePassword.jsx";
import HelpCenterList from "./pages/Ticket/HelpCenterList.jsx";
import CustomerTransactionHistory from "./pages/customer/CustomerTransactionHistory";
// import AddProductToStore from "./pages/storeOld/AddProductToStore.jsx";
// import StoreProductList from "./pages/storeOld/StoreProductList.jsx";
import UpdateSubCategory from "./pages/subcategories/UpdateSubCategory";
import AddSubCategories from "./pages/subcategories/AddSubCategories";
import SubCategoryList from "./pages/subcategories/SubCategoryList";
import ProductCategoryList from "./pages/productCategory/ProductCategoryList.jsx";
import AddProductCategory from "./pages/productCategory/AddProductCategory.jsx";
import EditProductCategory from "./pages/productCategory/EditProductCategory.jsx";
// import ProductCategoryDetails from "./pages/productCategory/ProductCategoryDetails.jsx";
import ProductStockList from "./pages/productStock/ProductStockList.jsx";
import AddProductStock from "./pages/productStock/AddProductStock.jsx";
import EditProductStock from "./pages/productStock/EditProductStock.jsx";
import ProductStockDetails from "./pages/productStock/ProductStockDetails";
import AddStore from "./pages/store/AddStore.jsx";
import StoreList from "./pages/store/StoretList.jsx";
import EditStore from "./pages/store/EditStore.jsx";
import StoreDetails from "./pages/store/StoreDetails.jsx";
import ForgetPassword from "./pages/Auth/password/ForgetPassword.jsx";
import VerifyOTP from "./pages/Auth/password/VerifyOTP.jsx";
import ChangePassword from "./pages/Auth/password/ChangePassword.jsx";
import AdminList from "./pages/Admin/AdminList.jsx";
import AddAdmin from "./pages/Admin/AddAdmin.jsx";
import NotificationStatus from "./pages/Admin/NotificationStatus.jsx";
import TwoFactorAuth from "./pages/Admin/TwoFactorAuth.jsx";
import AdminDetails from "./pages/Admin/AdminDetails.jsx";
import UpdateAdmin from "./pages/Admin/UpdateAdmin.jsx";
import RoleList from "./pages/Roles/RoleList.jsx";
import PermissionList from "./pages/Permissions/PermissionList.jsx";
import UpdatePermission from "./pages/Permissions/UpdatePermission.jsx";
import GetAdminPermissionById from "./pages/Permissions/GetAdminPermisionById.jsx";
import AssignPermissionToAdmin from "./pages/Permissions/AssigPermissionToAdmin.jsx";
import RemovePermissionFromAdmin from "./pages/Permissions/RemovePermissionFromAdmin.jsx";
import ViewRolesPermission from "./pages/Roles/ViewRolesPermission.jsx";
import VendorList from "./pages/vendor/VendorList.jsx";
import VendorDetails from "./pages/vendor/VendorDetails";
import UpdateVendor from "./pages/vendor/UpdateVendor";
import CreateRoles from "./pages/Roles/CreateRoles.jsx";
import UpdateRoles from "./pages/Roles/UpdateRole.jsx";
import GrantPermissionToRole from "./pages/Roles/GrantPermissionToRole.jsx";
import AssignRoleToAdmin from "./pages/Roles/AssignRoleToAdmin.jsx";
// import HelpCenterType from "./pages/HelpCenter/HelpCenterType.jsx";
import GetBillsProducts from "./pages/Bills/Airtime/GetBillsProducts.jsx";
import AddImagesToProduct from "./pages/vendorProduct/AddImagesToProduct.jsx";
import AddImagesToProductStock from "./pages/productStock/AddImagesToProductStock.jsx";
import AddProductStockSupply from "./pages/productStock/AddProductStockSupply.jsx";
import AddProductStockVariant from "./pages/productStock/AddProductStockVariant.jsx";
import UpdateProductStockVariant from "./pages/productStock/UpdateProductStockVariant.jsx";
import AddProductStockVariantSupply from "./pages/productStock/AddProductStockVariantSupply.jsx";
import ProductStockVariantImageUpload from "./pages/productStock/ViewProductStockVariantImageUpload.jsx";
import AddImagesToProductStockVariant from "./pages/productStock/AddImageToProductStockVariant.jsx";
import AddFlashSales from "./pages/flashSales/AddFlashSales.jsx";
import FlashSalesList from "./pages/flashSales/FlashSalestList.jsx";
import EditFlashSales from "./pages/flashSales/EditFlashSales.jsx";
import GetBillers from "./pages/Bills/Biller/GetBillers.jsx";
import AddBillers from "./pages/Bills/Biller/AddBiller.jsx";
import UpdateBillers from "./pages/Bills/Biller/UpdateBillers.jsx";
import SearchBills from "./pages/Bills/Biller/SearchBills.jsx";
import PrivateRoute from "./AuthProvider/PrivateRoute.jsx";
import ProductPropertyList from "./pages/ProductProperty/ProductPropertyList.jsx";
import AddProductProperty from "./pages/ProductProperty/AddProductProperty.jsx";
import EditProductProperty from "./pages/ProductProperty/EditProductProperty.jsx";
import HelpCenterDetails from "./pages/Ticket/HelpCenterDetail.jsx";
import HelpCenterUpdate from "./pages/Ticket/HelpCenterUpdate.jsx";
import AddMessageToCustomerTicket from "./pages/Ticket/AddMessageToCustomerTicket.jsx";
import AdminActLog from "./pages/Admin/AdminActLog.jsx";
import GetAllProducts from "./pages/Bills/Products/GetAllProducts.jsx";
import GetBillersProduct from "./pages/Bills/Biller/GetBillersProduct.jsx";
import Switcher from "./pages/Bills/Switcher/Switcher.jsx";
import GetAllOrders from "./pages/orders/GetAllOrders.jsx";
import UpdateOrders from "./pages/orders/UpdateOrders.jsx";
import OrderById from "./pages/orders/OrderById.jsx";
import GetCompletedOrders from "./pages/orders/GetCompletedOrders.jsx";
import { AuthContext } from "./AuthProvider/AuthProvider.jsx";
import AdminInactivityLogout from "./components/utils/AdminInactivityLogout.jsx";
import React, { useContext } from 'react';
import AppSettingList from "./pages/AppSettings/AppSettingList.jsx";
import UpdateAppSettings from "./pages/AppSettings/UpdateAppSettings.jsx";
import AllSliders from "./pages/Slider/AllSliders.jsx";
import AddSlider from "./pages/Slider/AddSlider.jsx";
import RiderList from "./pages/Riders/RidersList.jsx";
import EditRider from "./pages/Riders/EditRiders.jsx";
import RidersDetails from "./pages/Riders/RidersDetails.jsx";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Login />} />
      {/* <Route element={<PrivateRoutes />}> */}
      <Route path="/login/token" element={<TokenLogin />} />
      {/* <Route path="/admin/dashboard" element={<Dashboard />} /> */}

      <Route
        path="/admin/dashboard"
        element={
          <PrivateRoute >
            <Dashboard />
          </PrivateRoute>
        }
      />
      {/* ======================= customer management=========================== */}
      <Route
        path="/admin/customer"
        element={
          <PrivateRoute requiredPermissions={['customer.view']}>
            <Customer />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/customer/transactions/history"
        element={
          <PrivateRoute requiredPermissions={['']}>
            <CustomerTransactionHistory />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/customer/details"
        element={
          <PrivateRoute requiredPermissions={['customer.view']}>
            <CustomerDetails />
          </PrivateRoute>
        }
      />
      {/* =======================end of customer managemnt======================= */}
      {/* ========================== product management  =========================*/}
      <Route
        path="/admin/products"
        element={
          <PrivateRoute requiredPermissions={['product.view']}>
            <ProductList />
          </PrivateRoute>
        }
      />
      {/* <Route path="/admin/store/products" element={<StoreProductList />} /> */}
      <Route
        path="/admin/addproduct"
        element={
          <PrivateRoute requiredPermissions={['product.create']}>
            <AddProduct />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/addproduct/images"
        element={
          <PrivateRoute requiredPermissions={['image.add']}>
            <AddImagesToProduct />
          </PrivateRoute>
        }
      />
      {/* <Route path="/admin/store/addproduct" element={<AddProductToStore />} /> */}
      <Route
        path="/admin/product/details"
        element={
          <PrivateRoute requiredPermissions={['product.view']}>
            <ProductDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/product/edit"
        element={
          <PrivateRoute requiredPermissions={['product.edit']}>
            <EditProduct />
          </PrivateRoute>
        }
      />
      {/*======================= end of product ======================*/}
      {/*=======================  product  property======================*/}
      <Route
        path="/admin/product/property/list"
        element={
          <PrivateRoute requiredPermissions={['property.view']}>
            <ProductPropertyList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/product/property/update"
        element={
          <PrivateRoute requiredPermissions={['property.edit']}>
            <EditProductProperty />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/product/property/create"
        element={
          <PrivateRoute requiredPermissions={['property.create']}>
            <AddProductProperty />
          </PrivateRoute>
        }
      />
      {/*======================= end of product  property======================*/}
      {/*============================ product  category management =================*/}
      <Route
        path="/admin/products/category/list"
        element={
          <PrivateRoute requiredPermissions={['product_category.view']}>
            <ProductCategoryList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/products/category/create"
        element={
          <PrivateRoute requiredPermissions={['product_category.create']}>
            <AddProductCategory />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/product/category/edit"
        element={
          <PrivateRoute requiredPermissions={['product_category.edit']}>
            <EditProductCategory />
          </PrivateRoute>
        }
      />
      {/* <Route
        path="/admin/product/category/details"
        element={
          <PrivateRoute requiredPermissions={['']}>
            <ProductCategoryDetails />
          </PrivateRoute>
        }
      /> */}
    
      {/*========================== end of product management=================== */}
      {/*============================== product stock managememt ==================*/}
      <Route
        path="/admin/products/stock/list"
        element={
          <PrivateRoute requiredPermissions={['stock.view']}>
            <ProductStockList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/products/stock/create"
        element={
          <PrivateRoute requiredPermissions={['stock.create']}>
            <AddProductStock />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/product/stock/edit"
        element={
          <PrivateRoute requiredPermissions={['stock.edit']}>
            <EditProductStock />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/product/stock/details"
        element={
          <PrivateRoute requiredPermissions={['stock.view']}>
            <ProductStockDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/product/stock/images"
        element={
          // may chnage this permission name later
          <PrivateRoute requiredPermissions={['image.add']}>
            <AddImagesToProductStock />
          </PrivateRoute>
        }
      />

      <Route
        path="/admin/products/stock/variant/update"
        element={
          <PrivateRoute requiredPermissions={['stock.edit']}>
            <UpdateProductStockVariant />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/product/stock/variant/images/uploads"
        element={
          <PrivateRoute requiredPermissions={['image.add']}>
            <ProductStockVariantImageUpload />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/product/stock/variant/add/supply"
        element={
          <PrivateRoute requiredPermissions={['stock.supply']}>
            <AddProductStockVariantSupply />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/product/stock/variant/images/uploads"
        element={
          <PrivateRoute requiredPermissions={['image.add']}>
            <AddImagesToProductStockVariant />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/product/stock/add/supply"
        element={
          <PrivateRoute requiredPermissions={['stock.supply']}>
            <AddProductStockSupply />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/products/stock/variant/create"
        element={
          <PrivateRoute requiredPermissions={['stock.create']}>
            <AddProductStockVariant />
          </PrivateRoute>
        }
      />
      {/*===================== end of product stock managememt =============================== */}
      {/*=============================== store managemenet ===================================*/}
      {/* ============================== end of store management============================== */}
      <Route
        path="/admin/store/list"
        element={
          <PrivateRoute requiredPermissions={['store.view']}>
            <StoreList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/store/update"
        element={
          <PrivateRoute requiredPermissions={['store.edit']}>
            <EditStore />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/store/details"
        element={
          <PrivateRoute requiredPermissions={['store.view']}>
            <StoreDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/store/create"
        element={
          <PrivateRoute requiredPermissions={['store.create']}>
            <AddStore />
          </PrivateRoute>
        }
      />
      {/* <Route
        path="/admin/store/create"
        element={
          <PrivateRoute requiredPermissions={['store.delete']}>
            <RemoveStore />
          </PrivateRoute>
        }
      /> */}

      {/*======================= category management ===========================================*/}
      <Route
        path="/admin/category/list"
        element={
          <PrivateRoute requiredPermissions={['category.view']}>
            <CategoryList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/category/update"
        element={
          <PrivateRoute requiredPermissions={['category.edit']}>
            <UpdateCategory />
          </PrivateRoute>
        }
      />
      {/* <Route path="/admin/category/details" element={<CategoryDetails />} /> */}
      <Route
        path="/admin/addcategory"
        element={
          <PrivateRoute requiredPermissions={['category.create']}>
            <AddCategories />
          </PrivateRoute>
        }
      />
      {/*  ============================category management =======================================*/}
      {/*================================ sub category ===========================================*/}
     <Route
        path="/admin/subcategory/list"
        element={
          <PrivateRoute requiredPermissions={['subcategory.view']}>
            <SubCategoryList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/subcategory/update"
        element={
          <PrivateRoute requiredPermissions={['subcategory.edit']}>
            <UpdateSubCategory />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/subcategory/create"
        element={
          <PrivateRoute requiredPermissions={['subcategory.create']}>
            <AddSubCategories />
          </PrivateRoute>
        }
      />
      {/*==================================== end of sub category ============================== */}
      {/* ========================= flash sales ======================================== */}
      <Route
        path="/admin/flash/sales/list"
        element={
          <PrivateRoute requiredPermissions={['flash_sales.view']}>
            <FlashSalesList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/flash/sales/create"
        element={
          <PrivateRoute requiredPermissions={['flash_sales.create']}>
            <AddFlashSales />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/flash/sales/update"
        element={
          <PrivateRoute requiredPermissions={['flash_sales.edit']}>
            <EditFlashSales />
          </PrivateRoute>
        }
      />

      {/* ========================= end flash sales ======================================== */}
      {/* ================================orders management ====================================*/}
      <Route
        path="/admin/fullfilled"
        element={
          <PrivateRoute requiredPermissions={['']}>
            <FulfiledList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/payment"
        element={
          <PrivateRoute requiredPermissions={['']}>
            <PaymentList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/payment/details"
        element={
          <PrivateRoute requiredPermissions={['']}>
            <PaymentDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/order/list"
        element={
          <PrivateRoute requiredPermissions={['order.view']}>
            <GetAllOrders />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/order/details"
        element={
          <PrivateRoute requiredPermissions={['order.view']}>
            <OrderById />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/order/update"
        element={
          <PrivateRoute requiredPermissions={['order.edit']}>
            <UpdateOrders />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/return/items"
        element={
          <PrivateRoute requiredPermissions={['']}>
            <ReturnList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/return/items/details"
        element={
          <PrivateRoute requiredPermissions={['']}>
            <ReturnItemDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/order/fulfiled"
        element={
          <PrivateRoute requiredPermissions={['']}>
            <GetCompletedOrders />
          </PrivateRoute>
        }
      />
      {/* ========================= ticket ============================== */}
      <Route
        path="/admin/help/list"
        element={
          <PrivateRoute requiredPermissions={['ticket.view']}>
            <HelpCenterList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/help/details"
        element={
          <PrivateRoute requiredPermissions={['ticket.view']}>
            <HelpCenterDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/help/update"
        element={
          <PrivateRoute requiredPermissions={['ticket.edit']}>
            <HelpCenterUpdate />
          </PrivateRoute>
        }
      />
      {/* ==================== message reply to ticket ================== */}
      <Route
        path="/admin/help/message/add"
        element={
          <PrivateRoute requiredPermissions={['message.send']}>
            <AddMessageToCustomerTicket />
          </PrivateRoute>
        }
      />
      
      {/*=============================- end of ticket management ==========================*/}
      {/* ===================== admin management=========================================== */}
      <Route
        path="/admin/view/list"
        element={
          <PrivateRoute requiredPermissions={['admin.view']}>
            <AdminList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/view/details"
        element={
          <PrivateRoute requiredPermissions={['admin.view']}>
            <AdminDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/create"
        element={
          <PrivateRoute requiredPermissions={['admin.create']}>
            <AddAdmin />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/update"
        element={
          <PrivateRoute requiredPermissions={['admin.edit']}>
            <UpdateAdmin />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/activity/log"
        element={
          <PrivateRoute requiredPermissions={['admin.activities']}>
            <AdminActLog />
          </PrivateRoute>
        }
      />
      {/* ===================== end if admin management=========================================== */}
      {/* ====================== Roles and Permission ========================== */}
      <Route
        path="/admin/permission/list"
        element={
          <PrivateRoute requiredPermissions={['permission.view']}>
            <PermissionList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/permission/view/permissions"
        element={
          //may change this later
          <PrivateRoute requiredPermissions={['permission.view']}>
            <GetAdminPermissionById />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/permission/assign"
        element={
          <PrivateRoute requiredPermissions={['permission.assign']}>
            <AssignPermissionToAdmin />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/permission/remove"
        element={
          <PrivateRoute requiredPermissions={['permission.revoke']}>
            <RemovePermissionFromAdmin />
          </PrivateRoute>
        }
      />

      <Route
        path="/admin/permission/update"
        element={
          <PrivateRoute requiredPermissions={['permission.edit']}>
            <UpdatePermission />
          </PrivateRoute>
        }
      />
      {/* roles */}
      <Route
        path="/admin/roles/list"
        element={
          <PrivateRoute requiredPermissions={['role.view']}>
            <RoleList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/roles/view/permission"
        element={
          <PrivateRoute requiredPermissions={['role.view']}>
            <ViewRolesPermission />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/roles/create"
        element={
          <PrivateRoute requiredPermissions={['role.create']}>
            <CreateRoles />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/roles/update"
        element={
          <PrivateRoute requiredPermissions={['role.edit']}>
            <UpdateRoles />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/roles/grant/permission"
        element={
          <PrivateRoute requiredPermissions={['role.assign']}>
            <GrantPermissionToRole />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/roles/assign/permission"
        element={
          <PrivateRoute requiredPermissions={['role.assign']}>
            <AssignRoleToAdmin />
          </PrivateRoute>
        }
      />
      {/* ======================end of  Roles and Permission =================== */}
      {/*======================== settings management ====================================*/}
      <Route
        path="/admin/faq/list"
        element={
          <PrivateRoute requiredPermissions={['']}>
            <ListOfFaq />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/faq/create"
        element={
          <PrivateRoute requiredPermissions={['']}>
            <Faq />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/account/2fa/update/status"
        element={
          <PrivateRoute requiredPermissions={['admin.edit']}>
            <TwoFactorAuth />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/account/notification/status"
        element={
          <PrivateRoute requiredPermissions={['admin.create']}>
            <NotificationStatus />
          </PrivateRoute>
        }
      />
      {/* ====================== vendor  management====================================== */}
      <Route
        path="/admin/vendor/list"
        element={
          <PrivateRoute requiredPermissions={['vendor.view']}>
            <VendorList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/vendor/details"
        element={
          <PrivateRoute requiredPermissions={['vendor.view']}>
            <VendorDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/vendor/update"
        element={
          <PrivateRoute requiredPermissions={['vendor.edit']}>
            <UpdateVendor />
          </PrivateRoute>
        }
      />
      {/* ====================== end of vendor  management====================================== */}
      {/* ====================== end of settings management ===============================*/}
      <Route
        path="*"
        element={
          <PrivateRoute >
            <PageNotFound />
          </PrivateRoute>
        }
      />
      <Route
        path="/request/error"
        element={
          <PrivateRoute>
            <ErrorPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/password/change"
        element={
          <PrivateRoute requiredPermissions={['admin.create']}>
            <ChangePassword />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/password/forget"
        element={
          <PrivateRoute requiredPermissions={['admin.create']}>
            <ForgetPassword />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/otp/verification"
        element={
          <PrivateRoute >
            <VerifyOTP />
          </PrivateRoute>
        }
      />
      {/*============================ admin settings============================= */}
      {/*============================ bills management============================= */}
      <Route
        path="/admin/product/list"
        element={
          <PrivateRoute requiredPermissions={['bill.view']}>
            <GetBillsProducts />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/billers/list"
        element={
          <PrivateRoute requiredPermissions={['biller.view']}>
            <GetBillers />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/billers/create"
        element={
          <PrivateRoute requiredPermissions={['biller.create']}>
            <AddBillers />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/billers/update"
        element={
          <PrivateRoute requiredPermissions={['biller.edit']}>
            <UpdateBillers />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/bills/search"
        element={
          <PrivateRoute requiredPermissions={['bill.view']}>
            <SearchBills />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/product/all/list"
        element={
          <PrivateRoute requiredPermissions = { ['bill.view'] } >
            <GetAllProducts />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/billers/view/product"
        element={
          <PrivateRoute requiredPermissions={['biller.view']}>
            <GetBillersProduct />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/switchers/list"
        element={
          <PrivateRoute requiredPermissions={['switcher.view']}>
            <Switcher />
          </PrivateRoute>
        }
      />
      {/*============================ bills management============================= */}
      {/*============================ app setting management============================= */}
      <Route
        path="/admin/appsettings/list"
        element={
          <PrivateRoute requiredPermissions={['settings.view']}>
            <AppSettingList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/appsettings/update"
        element={
          <PrivateRoute requiredPermissions={['settings.edit']}>
            <UpdateAppSettings />
          </PrivateRoute>
        }
      />
      {/*============================ sliders management============================= */}
      <Route
        path="/admin/slider/list"
        element={
          <PrivateRoute requiredPermissions={['settings.view']}>
            <AllSliders />
          </PrivateRoute>
        }
      />

      <Route
        path="/admin/slider/create"
        element={
          <PrivateRoute requiredPermissions={['settings.edit']}>
            <AddSlider />
          </PrivateRoute>
        }
      />
      {/*============================ riders management============================= */}
      <Route
        path="/admin/riders/list"
        element={
          <PrivateRoute requiredPermissions={['rider.view']}>
            <RiderList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/riders/details"
        element={
          <PrivateRoute requiredPermissions={['rider.view']}>
            <RidersDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/riders/update"
        element={
          <PrivateRoute requiredPermissions={['rider.edit']}>
            <EditRider />
          </PrivateRoute>
        }
      />
      {/* </Route> */}
    </Route>
  )
);

// function App() {
//   const { auth } = useContext(AuthContext);
//   return <RouterProvider router={router} />;
// }

// export default App;

function App() {
  const { auth } = useContext(AuthContext);

  return (
    <>
      {/* Render AdminInactivityLogout on all pages  with the below router  */}
      <AdminInactivityLogout auth={auth} />

      <RouterProvider router={router} />
    </>
  );
}

export default App;
