import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as CheckSquareFill } from "bootstrap-icons/icons/check-square-fill.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import Desk from "../../assets/desk.png";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import CustomStyles from "../../components/utils/CustomStyles";
import CurrencyFormatter from "../../components/utils/CurrencyFormatter";

const PaymentList = () => {
  const [payment, setPayment] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);

  const navigateTo = useNavigate();

  //format date
  const formatDate = (dateString) => {
    // Check if dateString is null or undefined
    if (!dateString) {
      return "Not Available";
    }
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const fetchCustomerList = async () => {
      setLoading(true);
      try {
        // const authData = JSON.parse(localStorage.getItem("auth"));
        // const accessToken = authData.accessToken;
        const response = await axios.get(
          ApiServices.getPayment,
          {},
          {
            headers: {
              // logintoken: accessToken,
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        //console.log(response);
        setPayment(response.data);
        if (response.data.status === "300") {
          toast.error(response.data.message);
          return;
        }
        if (response.data.status === "302") {
          localStorage.removeItem("auth");
          toast.error(response.data.message);
          navigateTo("/");
        }
        if (response.data.status === "400") {
          toast.error(response.data.message);
          navigateTo("/customer/list");
        }
      } catch (error) {
        console.error("Error fetching customer list:", error.message);
        // toast.error("Error fetching peoduct list.");
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerList();
  }, []);

  // ====================================Confirm Payment =================================================

  // Define toastMixin
  const toastMixin = Swal.mixin({
    toast: true,
    icon: "success",
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  // Define function to handle delete confirmation
  const handlePaymentConfirmation = (paymentId) => {
    setConfirmationLoading(true);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "question",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonColor: "#1D9E34",
      confirmButtonText: "Yes, confirm payment!",
    }).then((result) => {
      setConfirmationLoading(false);
      if (result.isConfirmed) {
        handlePayment(paymentId);
      }
    });
  };

  // Define function to confirm payment
  const handlePayment = async (paymentId) => {
    try {
      // await axios.delete(`${ApiServices.deleteCustomer}/${customerId}`);
      await axios.post(`http://localhost:3000/payment/${paymentId}`);

      // Update the customer list after deletion
      const updatedPayment = payment.filter(
        (payment) => payment.id !== paymentId
      );
      setPayment(updatedPayment);
      toastMixin.fire({
        title: "Customer Payment confirmed",
      });
      setLoading(false);
    } catch (error) {
      console.error("Error confirming payment:", error.message);
      Swal.fire("Error!", "Failed to confirm payment.", "error");
      setLoading(false);
    }
  };
  // ==================================== end of payment =================================================

  //filter data by name
  const handleFilter = (e) => {
    setFilterText(e.target.value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const columns = [
    // {
    //   name: "Date",
    //   selector: (row) => formatDate(row.created_at),
    //   sortable: true,
    // },
    {
      name: "Full Name",
      selector: (row) => row.name,
      sortable: true,
      width: "250px",
    },
    {
      name: "Transaction Ref",
      selector: (row) => row.transactionId,
      sortable: true,
      width: "240px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "240px",
    },

    {
      name: "Product",
      selector: (row) => row.product,
      sortable: true,
      width: "200px",
    },
    {
      name: "Amount",
      selector: (row) => CurrencyFormatter(row.amount),
      sortable: true,
      width: "200px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "150px",
    },
    {
      name: "Payment Details",
      cell: (row) => (
        <div className="d-flex justify-content-end buttons">
          <Link
            to="/admin/payment/details"
            state={{ id: row.id }}
            className="btn btn-info btn-sm"
            onClick={(e) => {
              navigateTo("/payment/details", {
                replace: true,
                state: { id: row.id },
              });
            }}
            style={{ marginLeft: "5px" }}
            data-toggle="tooltip"
            data-placement="bottom"
            title="View payment Details"
          >
            <Eye className="w-5 h-5 text-dash4 mr-3" />
          </Link>
        </div>
      ),
      width: "150px",
    },

    {
      name: "Confirm Payment",
      cell: (row) => (
        <div className="d-flex justify-content-end buttons">
          <Link
            // to="/payment/confirm"
            state={{ id: row.id }}
            className="btn btn-info btn-sm"
            // onClick={(e) => {
            //   navigateTo("/payment/confirm", {
            //     replace: true,
            //     state: { id: row.id },
            //   });
            // }}
            onClick={(e) => handlePaymentConfirmation(row.id)}
            style={{ marginLeft: "5px" }}
            data-toggle="tooltip"
            data-placement="bottom"
            title="confirm payment"
            disabled={confirmationLoading}
          >
            <CheckSquareFill className="w-5 h-5 text-dash mr-3" />
          </Link>
        </div>
      ),
      width: "150px",
    },
  ];

  const filteredItems = payment.filter((item) => {
    for (let key in item) {
      if (
        typeof item[key] === "string" &&
        item[key].toLowerCase().includes(filterText.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  });

  const data = filteredItems.map((payment) => ({
    // created_at: formatDate(products.created_at),
    name: payment.name ? payment.name.toUpperCase() : "",
    email: payment.email ? payment.email : "",
    product: payment.product ? payment.product.toUpperCase() : "",
    amount: payment.amount ? payment.amount : "0.00",
    status: payment.status ? payment.status.toUpperCase() : "",
    transactionId: payment.transactionId
      ? payment.transactionId.toUpperCase()
      : "",
    id: payment.id,
  }));

  // Sort data based on creation date
  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          {/* <h1>Payment List</h1> */}
          <section
            className="w-full pl-[3%] pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg ">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Payment
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600">List</p>
                </li>
              </ol>
            </nav>
          </section>
          <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap justify-between">
            <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
              <input
                type="search"
                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search"
                value={filterText}
                onChange={handleFilter}
              />
            </div>
          </div>
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <DataTable
              columns={columns}
              data={sortedData}
              pagination
                striped
              highlightOnHover
              noDataComponent={<div>No payment list found</div>}
              customStyles={CustomStyles}
            />
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default PaymentList;
