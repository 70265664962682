import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { ReactComponent as Trash } from "bootstrap-icons/icons/trash3.svg";
import { ReactComponent as PencilSquare } from "bootstrap-icons/icons/pencil-square.svg";
import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
import CurrencyFormatter from "../../components/utils/CurrencyFormatter";
import { handleError } from "../../components/utils/AuthenticationCheck";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import Spinner from "../spinner/Spinner";
import { ReactComponent as PlusSquareFill } from "bootstrap-icons/icons/plus-square-fill.svg";
import { ReactComponent as BagPlusFill } from "bootstrap-icons/icons/bag-plus-fill.svg";

const ProductStockVariantTable = ({ productDetails }) => {
  const [productStockList, setProductStockList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [is_serialized, setIsSerialized] = useState(false);
  const [stock_variant_id, setStockVariantId] = useState(null);
  const navigateTo = useNavigate();

  useEffect(() => {
    //  console.log("this is details to be use in add siupply to stock variant ", productDetails);
    setProductStockList(productDetails.stock_variants || []);
    setIsSerialized(productDetails.is_serialized || []);
    if (productDetails.stock_variants.length > 0) {
      setStockVariantId(productDetails.stock_variants[0].variant_id);
    }
  }, [productDetails]);

  const handleDeleteCategory = async (stock_id, stock_variant_id) => {
    setLoading(true);
    const formData = { stock_id, stock_variant_id };

    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }

      const response = await axios.delete(ApiServices.removeProductStockVariant, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: formData,
        withCredentials: true,
      });

      const { status, message } = response.data;
      if (status === 200) {
        toast.success(message);
        setProductStockList((prevList) =>
          prevList.filter((product) => product.variant_id !== stock_variant_id)
        );
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteConfirmation = (stock_id, variant_id) => {
    Swal.fire({
      title: "Are you sure you want to delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteCategory(stock_id, variant_id);
      }
    });
  };

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#FFA439",
        color: "white",
        textTransform: "uppercase",
        fontWeight: "bold",
      },
    },
  };

  // const ExpandableComponent = ({ data }) => (
  //   <div className="flex justify-start">
  //     <Link
  //       to="/admin/products/stock/variant/update"
  //       state={{ productDetails: data }}
  //       className="mx-2 my-2 group"
  //     >
  //       <PencilSquare className="w-5 h-5 text-dash4" />
  //       <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
  //         Edit
  //       </div>
  //     </Link>
  //     <Link
  //       to="/admin/product/stock/variant/images/uploads"
  //       state={{ productStock: data }}
  //       className="mx-2 my-2 group"
  //     >
  //       <PlusSquareFill className="w-5 h-5 text-orange1 " />
  //       <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap ">
  //         Add Images
  //       </div>
  //     </Link>
  //     <button
  //       onClick={() =>
  //         handleDeleteConfirmation(data.stock_id, data.variant_id)
  //       }
  //       disabled={loading}
  //       className="mx-2 group"
  //     >
  //       <Trash className="w-5 h-5 text-dash3" />
  //       <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
  //         Delete Images
  //       </div>
  //     </button>
  //     <Link
  //       to="/admin/product/stock/variant/add/supply"
  //       state={{ productStock: data }}
  //       className="mx-2 my-2 group"
  //     >
  //       <BagPlusFill className="w-5 h-5 text-darkGreen2 " />
  //       <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap ">
  //         Add Supply
  //       </div>
  //     </Link>
  //   </div>
  // );

  const columns = [
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex justify-start">
          <Link
            to="/admin/products/stock/variant/update"
            state={{ productDetails: row }}
            className="mx-2 my-2 group"
          >
            <PencilSquare className="w-5 h-5 text-dash4" />
            <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
              Edit
            </div>
          </Link>
          <Link
            to="/admin/product/stock/variant/images/uploads"
            state={{ productStock: row }}
            className="mx-2 my-2 group"
          >
            <PlusSquareFill className="w-5 h-5 text-orange1 " />
            <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap ">
              Add Images
            </div>
          </Link>
          <button
            onClick={() =>
              handleDeleteConfirmation(row.stock_id, row.variant_id)
            }
            disabled={loading}
            className="mx-2 group"
          >
            <Trash className="w-5 h-5 text-dash3" />
            <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
              Delete Images
            </div>
          </button>
          <Link
            to="/admin/product/stock/variant/add/supply"
            state={{ productStock: row }}
            className="mx-2 my-2 group"
          >
            <BagPlusFill className="w-5 h-5 text-darkGreen2 " />
            <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap ">
              Add Supply
            </div>
          </Link>
        </div>
      ),
      width: "200px",
    },
    {
      name: "Name",
      selector: (row) => row.name ? row.name.toUpperCase() : "NOT AVAILABLE",
      sortable: true,
      width: "300px",

    },
    {
      name: "Value",
      selector: (row) => row.value ? row.value.toUpperCase() : "NOT AVAILABLE",
      sortable: true,
      width: "300px",
    },
    {
      name: "Price",
      selector: (row) => CurrencyFormatter(row.price),
      sortable: true,
      width: "300px",
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity ? row.quantity : "0",
      sortable: true,
      width: "150px",
    },
  ];

  return (
    <div className="p-2 w-full mt-4">
      <h1 className="text-orange1 md:text-lg">Stock Product Variant</h1>
      <div className="flex items-center border-orange1 p-1">
        <div className="shadow-md sm:rounded-lg w-full">
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <DataTable
              columns={columns}
              data={productStockList}
              pagination
              highlightOnHover
              striped
              responsive
              customStyles={customStyles}
            />
            // <DataTable
            //   columns={columns}
            //   data={productStockList}
            //   pagination
            //   // fixedHeader
            //   // fixedHeaderScrollHeight="450px"
            //   highlightOnHover
            //    striped
            //   // subHeader
            //   // subHeaderAlign="right"
            //   customStyles={customStyles}
            //   noDataComponent={<div>No Product stock variant found</div>}
            //   expandableRows
            //   expandableRowsComponent={ExpandableComponent}

            // />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductStockVariantTable;




