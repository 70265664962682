import React, { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import Spinner from "../spinner/Spinner";
import { handleError } from "../../components/utils/AuthenticationCheck";

const EditProductStock = () => {
  const [price, setPrice] = useState("");
  const [batch_number, setBatchNumber] = useState("");
  const [status, setStatus] = useState("");
  const [product_id, setProduct_id] = useState("");
  const [store_id, setStore_id] = useState("");
  const [stock_id, setStock_id] = useState("");
  const [store, setStore] = useState([]);
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [StoreLoading, setStoreLoading] = useState(false);

  const navigateTo = useNavigate();

  const location = useLocation();
  const productStock = location.state?.productStock;
  //  const {productStock} = location.state || {};
  //console.log("This is product stock", productStock);

  // Set initial state from location.state
  useEffect(() => {
    if (productStock) {
      // console.log("Set initial state from location", productStock);
      setPrice(productStock.price || "");
      setStore_id(productStock.store_id || "");
      setProduct_id(productStock.product_id || "");
      setBatchNumber(productStock.batch_number || "");
      setStock_id(productStock.stock_id || "");
      setStatus(productStock.status || "");
    }
  }, [productStock]);

  // Fetch stores once the component mounts
  useEffect(() => {
    const fetchStoreList = async () => {
      setStoreLoading(true);
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) {
          throw new Error("No access token found.");
        }
        const response = await axios.get(ApiServices.getAllStore, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        const { status, message, data } = response.data;
        if (status === 200 && Array.isArray(data)) {
          setStore(data);
          setStore_id((prev) => prev || data[0]?.store_id || "");
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setStoreLoading(false);
      }
    };

    fetchStoreList();
  }, []);

  // Fetch product
  useEffect(() => {
    const fetchProductList = async () => {
      setIsProductLoading(true);
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) {
          throw new Error("No access token found.");
        }
        const response = await axios.get(ApiServices.getProducts, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        const { status, message, data } = response.data;
        // console.log("product list response",data);
        if (status === 200 && Array.isArray(data)) {
          setProduct(data);
          setProduct_id((prev) => prev || data[0]?.product_id || "");
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setIsProductLoading(false);
      }
    };

    fetchProductList();
  }, []);

  // handle chnages in product
  const handleStoreChange = (e) => {
    const selectedStore = e.target.value;
    // console.log("selcetd store id ", selectedStore);
    setStore_id(selectedStore);
  };

  // //handle chnages in category
  const handleProductChange = (e) => {
    const selectedProduct = e.target.value;
    // console.log("select product id ", selectedProduct);
    setProduct_id(selectedProduct);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validateForm = () => {
      // const batch_number = null;
      if (!price || !store_id || !product_id || !stock_id) {
        toast.error("All fields are required.");
        // return false;
      }
      return true;
    };

    if (!validateForm()) return;

    setLoading(true);
    const formData = {
      price,
      product_id,
      store_id,
      batch_number: batch_number || null,
      status: status || null,
      stock_id,
    };
    // console.log("this is my form data", formData);
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }

      const response = await axios.put(
        ApiServices.updateProductStock,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const { status, message } = response.data;
      if (status === 200) {
        navigateTo("/admin/products/stock/list");
        toast.success(message);
        setStore_id("");
        setProduct_id("");
        setPrice("");
        setBatchNumber("");
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };



  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <section
            className="w-full  pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Product
                  </p>
                  <ChevronRightIcon />
                </li>

                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Stock
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1">Update</p>
                </li>
              </ol>
            </nav>
          </section>

          <section className="gray_background">
            <div className="container px-1 pb-2 mx-auto">
              <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
                {loading ? (
                  <Spinner loading={loading} />
                ) : (
                  <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                      <input type="hidden" name="stock_id" value={stock_id} />
                      <div className="w-full">
                        <label
                          htmlFor="category_id"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Store
                        </label>
                        <select
                          id="store_id"
                          name="store_id"
                          value={store_id}
                          onChange={handleStoreChange}
                          className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        >
                          <option value="" disabled>
                            {StoreLoading ? "Loading..." : "Select Store"}
                          </option>
                          {store.map((storedItems, index) => (
                            <option key={index} value={storedItems.store_id}>
                              {storedItems.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="w-2.5/5">
                        <label
                          htmlFor="sub_category_id"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Products
                        </label>
                        <select
                          id="product_id"
                          name="product_id"
                          value={product_id}
                          onChange={handleProductChange}
                          className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        >
                          <option value="" disabled>
                            {isProductLoading ? "Loading..." : "Select product"}
                          </option>
                          {product.map((prod, index) => (
                            <option key={index} value={prod.product_id}>
                              {prod.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 ">
                      <div className="w-full mt-4">
                        <div className="col-span-1 sm:col-span-1">
                          <label
                            htmlFor="name"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Price
                          </label>
                          <input
                            type="number"
                            name="price"
                            id="price"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            placeholder="Enter price"
                            min="0"
                            aria-describedby="price-help" // Optional for better accessibility
                          />
                        </div>
                      </div>
                      <div className="w-full mt-4">
                        <div className="col-span-1 sm:col-span-1">
                          <label
                            htmlFor="name"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Batch Number
                          </label>
                          <input
                            type="number"
                            name="batch_number"
                            id="batch_number"
                            value={batch_number}
                            onChange={(e) => setBatchNumber(e.target.value)}
                            className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            placeholder="Enter batch  number"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="w-full mt-4">
                      <div className="col-span-1 sm:col-span-1">
                        <label
                          htmlFor="status"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Status
                        </label>
                        <select
                          id="status"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                          className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        >
                          <option value="" disabled>
                            Select status
                          </option>
                          <option value="available">Available</option>
                          <option value="discontinued">Discontinued</option>
                          <option value="out_of_stock">Out of stock</option>

                        </select>
                      </div>
                    </div>

                    <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
                      <Link
                        to="/admin/products/stock/list"
                        className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
                      >
                        <List className="mr-2" />
                        Back To List
                      </Link>
                      <button
                        type="submit"
                        className={`text-white bg-darkGreen3 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium ${loading ? "btn-loading" : "btn-primary"
                          }`}
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Update Product Stock"}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </section>
        </div>
      </MainLayouts>
    </div>
  );
};

export default EditProductStock;
