import React from 'react'

const DashboardCards = ({Heading,Amount,Icon}) => {
  return (
    <div className="Card-Parent flex p-4 max-md:p-3 justify-between items-center w-full bg-[#fff] rounded-2xl shadow-md">
                    <div className="Card-Text flex flex-col space-y-2">
                      <p className="text-sm max-md:text-sm text-grey4">{Heading||'Not available'}</p>
                      <div className="flex justify-start items-end flex-1 ">
                        <h1 className={`max-md:text-md text-xl ${Amount.length > 5 ? 'text-md':'text-xl'}`}>{Amount || 'Not available'}</h1>
                        {/* <span
                          className={`text-sm max-md:text-sm ml-1 ${
                            analytics.percentage.startsWith("+")
                              ? "text-chartGreen"
                              : analytics.percentage.startsWith("-")
                              ? "text-chartRed"
                              : ""
                          }`}
                        >
                          {analytics.percentage}
                        </span> */}
                      </div>
                    </div>
                    <div className='h-12 w-12 rounded-lg bg-[#257F34] flex items-center justify-center'>
                    <Icon className="h-6 w-6 text-[#fff]"/>
                    </div>
                    
                    {/* <div className="Card-icon">
                      <img src={icon} alt="icon" />
                    </div> */}
                  </div>
  )
}

export default DashboardCards