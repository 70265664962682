

import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as EyeFill } from "bootstrap-icons/icons/eye-fill.svg";
import { ReactComponent as PencilFill } from "bootstrap-icons/icons/pencil-fill.svg";
import { ReactComponent as ArrowLeftCircleLeftFill } from "bootstrap-icons/icons/arrow-left-circle-fill.svg";
import { ReactComponent as ArrowRightCircleLeftFill } from "bootstrap-icons/icons/arrow-right-circle-fill.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import Spinner from "../spinner/Spinner";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import { handleError } from "../../components/utils/AuthenticationCheck";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";

const ViewsRolesPermission = () => {
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [permissionsPerPage] = useState(12);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const navigateTo = useNavigate();
  const location = useLocation();

  const role = location.state?.role;
  const role_id = role?.role_id;
  const roleName = role?.name;

  useEffect(() => {
    const fetchAllPermissionListForRole = async () => {
      setLoading(true);
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) {
          throw new Error("No access token found.");
        }
        const response = await axios.get(ApiServices.getRolesPermission, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          params: { role_id: role_id },
          withCredentials: true,
        });

        const { status, message, data } = response.data;
        // console.log("response data from api:", data);

        if (status === 200) {
          if (Array.isArray(data.permissions)) {
            setPermissions(data.permissions);
            toast.success(message, { toastId: "permission-fetch-success" });
          } else {
            console.error("Unexpected permissions format:", data.permissions);
            setPermissions([]);
          }
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setLoading(false);
      }
    };
    if (role_id) {
      fetchAllPermissionListForRole();
    }
  }, [role_id]);

  const handleFilter = (e) => {
    setFilterText(e.target.value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const formatPermissionText = (text) => {
    let formattedText = text.replace(/_/g, " ");
    formattedText = formattedText
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
    return formattedText;
  };

  // Log permissions before filtering
  // console.log("Permissions data before filtering:", permissions);
  // console.log("Is permissions an array?", Array.isArray(permissions));

  const filteredPermissions = Array.isArray(permissions)
    ? permissions.filter((item) =>
        item.name.toLowerCase().includes(filterText.toLowerCase().trim())
      )
    : [];

  const indexOfLastPermission = currentPage * permissionsPerPage;
  const indexOfFirstPermission = indexOfLastPermission - permissionsPerPage;
  const currentPermissions = filteredPermissions.slice(
    indexOfFirstPermission,
    indexOfLastPermission
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const hasNextPage = () => indexOfLastPermission < filteredPermissions.length;
  const hasPrevPage = () => currentPage > 1;

  const parsePermission = (permission) => {
    const [resource, action] = permission.split(".");
    return { resource, action };
  };

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <section
            className="w-full pl-[3%] pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg ">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-dash5 pr-2">
                    Roles Permission
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600">List</p>
                  <ChevronRightIcon />
                </li>
                {roleName && (
                  <li className="flex items-center">
                    <p className="text-gray-600  text-orange1">
                      <span className="font-medium pl-1">{roleName}</span>
                    </p>
                    <ChevronRightIcon />
                  </li>
                )}
              </ol>
            </nav>
          </section>
          <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap items-center justify-between gap-4">
            <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
              <input
                type="search"
                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search"
                value={filterText}
                onChange={handleFilter}
              />
            </div>
            <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-1">
              <Link
                to="/admin/roles/list"
                className="text-dGrey3 py-3 bg-darkGreen3 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
              >
                <List className="mr-2" />
                Back To List
              </Link>
            </div>
          </div>

          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <section className="text-gray-600 body-font">
              <div className="container px-1 py-2 mx-auto">
                <div className="flex flex-wrap -m-2">
                  {currentPermissions.length > 0 ? (
                    currentPermissions.map((permission, index) => {
                      const { resource, action } = parsePermission(
                        permission.name
                      );
                      return (
                        <div
                          key={index}
                          className="p-2 lg:w-1/3 md:w-1/2 w-full"
                        >
                          <div className="h-full flex items-center border-darkGreen3 border p-4 rounded-lg">
                            <div className="flex-grow">
                              <h2 className="flex items-center justify-between text-darkGreen3 title-font font-medium">
                                <span>{action}</span>
                                <span className="flex items-center">
                                  <Link
                                    to="/admin/permission/update"
                                    state={{
                                      permission,
                                    }}
                                    className="text-dash5 mr-2 border border-gray-300 rounded-md p-2"
                                  >
                                    <PencilFill className="w-5 h-5" />
                                  </Link>
                                </span>
                              </h2>

                              <p className="text-gray-500 flex items-center">
                                Permission for:{" "}
                                <span className="ml-2 text-dash5">
                                  {formatPermissionText(resource)}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="w-full text-center">
                      No permissions found
                    </div>
                  )}
                </div>
                <div className="flex justify-end mt-6">
                  <nav aria-label="Page navigation">
                    <ul className="pagination flex mx-auto">
                      <li
                        className={`page-item ${
                          !hasPrevPage() ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="text-dGrey3 bg-black1  hover:bg-darkGreen2 focus:outline-none font-medium rounded-full text-sm px-5 py-2 text-center inline-flex items-center gap-1 m-2"
                          onClick={() => paginate(currentPage - 1)}
                          disabled={!hasPrevPage()}
                        >
                          <span className="flex mx-2 mt-1">
                            <ArrowLeftCircleLeftFill className="w-5 h-5 mr-2" />
                            Prev
                          </span>
                        </button>
                      </li>
                      <li
                        className={`page-item ${
                          !hasNextPage() ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="text-dGrey3 bg-black1  hover:bg-darkGreen2 focus:outline-none font-medium rounded-full text-sm px-5 py-2 text-center inline-flex items-center gap-1 m-2"
                          onClick={() => paginate(currentPage + 1)}
                          disabled={!hasNextPage()}
                        >
                          <span className="flex mx-2 mt-1">
                            <ArrowRightCircleLeftFill className="w-5 h-5 mr-2" />
                            Next
                          </span>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </section>
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default ViewsRolesPermission;
